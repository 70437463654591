import { ADD_TO_CART, REMOVE_TO_CART, RESET_CART, UPDATE_CART } from './types';

export const addToCart = (id, product) => {
  return {
    type: ADD_TO_CART,
    payload: {
      id,
      product
    }
  };
};

export const updateCart = newCart => {
  return {
    type: UPDATE_CART,
    payload: newCart
  };
};

export const removeToCart = id => {
  return {
    type: REMOVE_TO_CART,
    payload: { id }
  };
};

export const resetCart = () => {
  return {
    type: RESET_CART
  };
};
