import _ from 'lodash';
import { SHOW_PRODUCTS } from './types';

export const showProducts = products => {
  const productsArray = [];

  _.keys(products).forEach(productKey => {
    productsArray.push(products[productKey]);
  });

  return {
    type: SHOW_PRODUCTS,
    payload: productsArray
  };
};
