import React from 'react';
import { connect } from 'react-redux';
import 'moment/min/locales';
import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import Home from './Pages/Home';
import Landing from './Pages/Landing';
import Marketplace from './Pages/Marketplace';
import MessageModal from './components/MessageModal';
import CartModal from './components/CartModal';
import PaymentModal from './components/PaymentModal';
import InactivityModal from './components/InactivityModal';
import OrderModal from './components/OrderModal';
import Navbar from './components/Navbar';
import HorairesModal from './components/HorairesModal';
import ScrollToTop from './components/ScrollToTop';
import { fetchConfiguration, userConnectionCheck, updateShop } from './redux/actions';
import { initializeFirebase, initializeMessenger, getShopsData } from './api';
import { ORDER_TYPE_CLICK_AND_COLLECT, UNIT, COLOR_LIGHT_GRAY } from './constants';
import {
  checkAccessContent,
  isWebView,
  isQrCode,
  isTerminal,
  getMetadata,
  updateAddressAndShop,
  interceptConsole,
  isSplash
} from './utils';

import ConnexionModal from './components/ConnexionModal';
import AccountModal from './components/AccountModal';
import AddressModal from './components/AddressModal';
import './css/App.css';
import './css/react-phone-input.css';
import './css/App-dark.css';

const INITIAL_STATE = {
  stripe: false,
  displayAddressModal: false
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  async componentWillMount() {
    const { fetchConfiguration, userConnectionCheck, updateShop, shopId } = this.props;
    interceptConsole();
    await initializeFirebase();
    await fetchConfiguration();
    await getShopsData();
    checkAccessContent();
    if (!shopId) {
      await updateShop(null);
    }
    userConnectionCheck();
    if (isWebView()) {
      initializeMessenger();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { customerAddress, updateShop, shops } = this.props;
    if (nextProps.stripeApiKey) {
      this.setState({ stripe: loadStripe(nextProps.stripeApiKey) });
    }
    if (
      nextProps.customerAddress &&
      (!customerAddress ||
        (customerAddress &&
          !_.isEqual(
            _.omit(customerAddress, ['instruction', 'street2']),
            _.omit(nextProps.customerAddress, ['instruction', 'street2'])
          )))
    ) {
      updateShop(nextProps.customerAddress);
    }
    if (!_.isEqual(shops, nextProps.shops) && !_.isEmpty(nextProps.shops)) {
      const shopKeys = Object.keys(nextProps.shops);
      if (shopKeys.length === 1) {
        const shopId = shopKeys[0];
        const shop = nextProps.shops[shopId];
        const { address, configuration } = shop;
        const { orderTypes } = configuration;
        if (
          orderTypes.includes(ORDER_TYPE_CLICK_AND_COLLECT) &&
          orderTypes.length === 1 &&
          !isQrCode() &&
          !isTerminal()
        ) {
          updateAddressAndShop(shopId, address, ORDER_TYPE_CLICK_AND_COLLECT);
        }
      }
    }
  }

  render() {
    const {
      theme,
      webapp,
      name,
      logoUrl,
      landing,
      marketplace,
      secondaryColor,
      descriptionText
    } = this.props;
    const { stripe, displayAddressModal } = this.state;
    const { title, description, keywords } = getMetadata(name, webapp, descriptionText);
    const marketplaceEnabled = marketplace && marketplace.enabled;
    const landingEnabled = landing && landing.enabled;
    const navbarEnabled = webapp && webapp.navbar && webapp.navbar.enabled;
    const domain = window.location.href;
    const singlePageEnabled = isQrCode() || isTerminal() || isWebView();
    return (
      <div className={`${marketplaceEnabled ? 'clear' : theme}-theme`}>
        {name && (
          <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel='shortcut icon' href={logoUrl} type='image/x-icon' />
            <meta name='keywords' content={keywords} />
          </Helmet>
        )}
        {((!marketplaceEnabled && !landingEnabled) || singlePageEnabled) && (
          <>
            {navbarEnabled && !singlePageEnabled && <Navbar />}
            <Home />
          </>
        )}
        {marketplaceEnabled && !singlePageEnabled && (
          <>
            <ScrollToTop />
            <Navbar />
            <Switch>
              <Route path='/:shopId' exact component={Home} />
              <Route path='/' component={Marketplace} />
            </Switch>
          </>
        )}
        {!marketplaceEnabled && !singlePageEnabled && landingEnabled && (
          <>
            {/* <ScrollToTop /> */}
            <Navbar />
            <Switch>
              <Route path='/commander/' exact component={Home} />
              <Route path='/' component={Landing} />
            </Switch>
          </>
        )}
        <MessageModal />
        {stripe && (
          <>
            <Elements stripe={stripe}>
              <ElementsConsumer>
                {({ elements, stripe }) => <CartModal elements={elements} stripe={stripe} />}
              </ElementsConsumer>
            </Elements>

            <Elements stripe={stripe}>
              <ElementsConsumer>
                {({ elements, stripe }) => <PaymentModal elements={elements} stripe={stripe} />}
              </ElementsConsumer>
            </Elements>
          </>
        )}
        <HorairesModal />
        <ConnexionModal />
        <AccountModal />
        {getCookieConsentValue() === 'true' || displayAddressModal ? <AddressModal /> : null}
        <InactivityModal />
        <OrderModal />
        {!isSplash() && !isTerminal() && (
          <CookieConsent
            location='bottom'
            expires={365}
            overlay
            buttonText='Tout Accepter'
            buttonStyle={{
              background: secondaryColor || 'black',
              color: 'white',
              borderRadius: UNIT / 2
            }}
            style={{
              alignItems: 'center',
              backgroundColor: COLOR_LIGHT_GRAY,
              justifyContent: 'center'
            }}
            onAccept={() => {
              this.setState({ displayAddressModal: true });
            }}
            overlayStyle={{
              zIndex: 3000
            }}
          >
            <p className='text-secondary text-justify'>
              Nous utilisons des cookies pour offrir des fonctionnalités utiles et mesurer les
              performances afin d'améliorer votre expérience.
            </p>
          </CookieConsent>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ shopReducer, configurationReducer, userReducer, componentReducer }) => {
  const { shopId, shops } = shopReducer;
  const { splash } = componentReducer;
  const { customerAddress } = userReducer;
  const {
    webapp,
    name,
    logoUrl,
    theme,
    stripeApiKey,
    landing,
    marketplace,
    secondaryColor,
    descriptionText
  } = configurationReducer;
  return {
    descriptionText,
    shopId,
    webapp,
    theme,
    logoUrl,
    name,
    stripeApiKey,
    landing,
    marketplace,
    customerAddress,
    shops,
    secondaryColor,
    splash
  };
};

export default connect(mapStateToProps, {
  fetchConfiguration,
  userConnectionCheck,
  updateShop
})(App);
