import _ from 'lodash';
import { sendCloudWatchAlert } from '../../utils';

export const initializeMap = component => {
  try {
    const { google } = window;
    const center = { lat: 46.2321, lng: 2.2096 };
    const myOptions = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      zoom: 5,
      center
    };
    component.map = new google.maps.Map(component.refs.map_section, myOptions);
  } catch (error) {
    sendCloudWatchAlert(`Error initializing map ${error}`);
  }
};

export const updateMap = (shops, component) => {
  try {
    const { google } = window;
    const { map } = component;
    const bounds = new google.maps.LatLngBounds();
    _.keys(shops).map(key => {
      const shop = shops[key];
      const { address } = shop;
      const { location } = address;
      const point = {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
        title: shop.name
      };
      new google.maps.Marker({ position: point, map });
      bounds.extend(point);
      if (_.size(shops) === 1) {
        map.setCenter({ lat: point.lat, lng: point.lng });
        map.setZoom(14);
      }
    });
    if (_.size(shops) > 1) {
      map.fitBounds(bounds);
    }
  } catch (error) {
    sendCloudWatchAlert(`Error updating map ${error}`);
  }
};
