import { UNIT, COLOR_MID_GRAY } from '../../constants/configuration';

const styles = {
  containerOptionHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 3,
    height: '100px',
    backgroundColor: COLOR_MID_GRAY,
    borderRadius: `${UNIT}px ${UNIT}px 0px 0px`
  },
  imageOptionTerminalVertical: {
    width: '185px',
    height: '185px',
    borderRadius: UNIT * 2
  },
  imageOption: {
    width: '40px',
    height: '40px'
  },
   optionDescription: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
    fontSize:12,
    color:'grey'
  },
  divNameAndDescription: {
   flex:1,
  },
  productName: {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-wrap',
  maxWidth:'100%'
  },
};

export default styles;
