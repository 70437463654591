import React from 'react';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import { connect } from 'react-redux';
import Order from '../Order';
import Button from '../Button';
import { SECONDARY } from '../../constants';
import { showOrderModal, showMessageModal, showOrderStatus } from '../../redux/actions';
import OrderStatus from '../OrderStatus';
import { isTerminal } from '../../utils';
import ModalBorder from '../ModalBorder';

class OrderModal extends React.Component {
  renderBody = () => {
    const { orders, orderStatus, showOrderStatus } = this.props;
    if (orderStatus && !_.isEmpty(orders) && orders[orderStatus]) {
      return <OrderStatus chargeId={orderStatus} />;
    }
    if (_.size(orders) === 1) {
      const chargeId = _.map(orders, order => {
        return order.chargeId;
      });
      if (chargeId) showOrderStatus(chargeId)
    } else {
      return (
        !_.isEmpty(orders) &&
        _.keys(orders)
          .sort((a, b) => new Date(orders[b].date).getTime() - new Date(orders[a].date).getTime())
          .map(key => {
            const order = orders[key];
            return (
              <div
                onClick={() => {
                  showOrderStatus(order.chargeId);
                }}
              >
                <Order order={order} key={key} />
              </div>
            );
          })
      );
    }
  };

  renderFooter = () => {
    const { orders, showOrderModal, showOrderStatus, orderStatus } = this.props;
    return (
      <>
        {orderStatus && _.size(orders) > 1 && (
          <Button
            type={SECONDARY}
            onClick={() => {
              showOrderStatus(false);
            }}
          >
            Retour
          </Button>
        )}
        {(!orderStatus || _.size(orders)) <= 1 && (
          <Button
            type={SECONDARY}
            onClick={() => {
              showOrderModal(false);
              showOrderStatus(false);
            }}
          >
            Fermer
          </Button>
        )}
      </>
    );
  };

  render() {
    const { orderModal, showOrderModal, orders, showOrderStatus } = this.props;
    return (
      <>
        {!isTerminal() && (
          <Modal
            onHide={() => {
              showOrderModal(false);
              showOrderStatus(false);
            }}
            scrollable
            show={orderModal && !_.isEmpty(orders)}
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <ModalBorder className='modal-size-fixed hide-scroll'>
              <Modal.Body style={{ minHeight: window.innerHeight * 0.8 }} className='dark-modal'>
                {this.renderBody()}
              </Modal.Body>
              <Modal.Footer className='modal-sticky-footer justify-content-center dark-modal'>
                {this.renderFooter()}
              </Modal.Footer>
            </ModalBorder>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ configurationReducer, orderReducer, componentReducer }) => {
  const { secondaryColor } = configurationReducer;
  const { orders } = orderReducer;
  const { orderModal, orderStatus } = componentReducer;
  return {
    secondaryColor,
    orders,
    orderModal,
    orderStatus
  };
};

export default connect(mapStateToProps, {
  showOrderModal,
  showMessageModal,
  showOrderStatus
})(OrderModal);
