import { UNIT } from '../../constants/configuration';

const styles = {
  addressInput: {
    fontSize: UNIT * 0.875,
    width: '84%',
    border: 0,
    borderRadius: UNIT * 2,
    marginLeft: UNIT * 1.5,
    textIndent: 0
  },
  btn: {
    borderRadius: UNIT * 2,
    marginTop: UNIT,
    width: ' 100%'
  },
  space: {
    marginBottom: UNIT * 0.125,
    fontSize: 'large'
  },
  menuStyle: {
    width: '100%',
    backgroundColor: 'transparent!important',
    color: 'black!important',
    padding: '5px 13px',
    justifyContent: 'space-between'
  },
  inputText: {
    fontSize: UNIT * 0.875,
    marginLeft: UNIT * 0.5
  },
  startIcon: {
    fontSize: 'large'
  },
  formControlLabel: {
    fontSize: UNIT * 0.875,
    fontWeight: 400,
    marginBottom: -UNIT * 0.5,
    width: '100%'
  },
  labelStyle: {
    fontSize: UNIT * 0.8125,
    color: 'gray',
    paddingLeft: '0.7rem',
    marginTop: -UNIT * 0.5,
    marginBottom: UNIT * 0.5
  },
  truncText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%'
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex'
  },
  errorText: {
    color: 'red',
    fontSize: UNIT * 0.75
  }
};

export default styles;
