import { addPromotionBackend } from '../../api';
import { sendCloudWatchLogs, isStringNotNull, renderErrorMessage } from '../../utils';
import store from '../../redux/store';
import { showMessageModal } from '../../redux/actions';

export const addPromotion = async (component, promoCode) => {
  try {
    const { user } = store.getState().userReducer;
    const { customerId } = user;
    component.setState({ loadingPromotion: true });
    if (isStringNotNull(promoCode)) {
      sendCloudWatchLogs(`Adding code promo ${promoCode}`);
      const deviceId = customerId;
      await addPromotionBackend(customerId, promoCode, deviceId);
      store.dispatch(showMessageModal(`Votre code promotionnel ${promoCode} a bien été ajouté`));
      component.setState({ loadingPromotion: false });
    } else {
      store.dispatch(showMessageModal(`Vous devez entrer un code promotionnel.`));
      component.setState({ loadingPromotion: false });
    }
  } catch (error) {
    const errorMessage = String(error);
    component.setState({ loadingPromotion: false });
    store.dispatch(showMessageModal(renderErrorMessage(errorMessage)));

    sendCloudWatchLogs(`Error adding promotion ${error}`);
  }
};
