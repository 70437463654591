import _ from 'lodash';
import { ADD_TO_CART, REMOVE_TO_CART, RESET_CART, UPDATE_CART } from '../actions/types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return { ...state, [action.payload.id]: action.payload.product };
    case REMOVE_TO_CART:
      return _.omit(state, action.payload.id);
    case RESET_CART:
      return {};
    case UPDATE_CART:
      return action.payload;
    default:
      return state;
  }
};
