import _ from 'lodash';
import store from '../../../redux/store';

export const setSectionListKey = (component, selectedSection) => {
  const { sections } = store.getState().shopReducer;
  _.find(sections, (section, key) => {
    if (String(section.key) === String(selectedSection)) {
      component.setState({ selected: key });
      return true;
    }
  });
};
