import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  COMPONENT_MAP,
  COMPONENT_FEATURES,
  COMPONENT_BESTSELLER,
  COMPONENT_DESCRIPTION,
  COMPONENT_MOBILEAPP
} from '../../constants';
import Footer from '../../components/Footer';
import CoverSection from '../../components/CoverSection';
import DescriptionSection from '../../components/DescriptionSection';
import BestSellerSection from '../../components/BestSeller/BestSellerSection';
import CaracteristicsSection from '../../components/CaracteristicsSection';
import MapSection from '../../components/MapSection';
import MobileAppSectison from '../../components/MobileAppSection';

class Landing extends React.Component {
  renderComponents = () => {
    const { landing } = this.props;
    const { components } = landing;
    return _.map(components, (component, index) => {
      switch (component) {
        case COMPONENT_BESTSELLER:
          return <BestSellerSection index={index} />;
        case COMPONENT_FEATURES:
          return <CaracteristicsSection index={index} />;
        case COMPONENT_MAP:
          return <MapSection index={index} />;
        case COMPONENT_DESCRIPTION:
          return <DescriptionSection index={index} />;
        case COMPONENT_MOBILEAPP:
          return <MobileAppSectison index={index} />;
        default:
          break;
      }
    });
  };

  render() {
    return (
      <div>
        <CoverSection />
        {this.renderComponents()}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { name, landing } = configurationReducer;
  return {
    name,
    landing
  };
};

export default connect(mapStateToProps, {})(Landing);
