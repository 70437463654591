import { Cookies } from 'react-cookie';
import _ from 'lodash';
import moment from 'moment';
import store from '../redux/store';
import { isStringNotNull } from './dataType';
import { updateAddress } from '../redux/actions';
import {
  setUserAddressFirebase,
  getValidPromotions,
  getDataFromTable,
  createCustomerBackend,
  sendPostRequest,
  updateDataOnTable,
  removeDataFromTable,
  getFirebaseCustomerId
} from '../api';
import { getShopIdFromLink } from './shop';
import { sendCloudWatchAlert, sendCloudWatchLogs } from './logs';
import { WEBAPP, ORDER_TYPE_DELIVERY, API_REQUEST_DELIVERY_PRICING } from '../constants';

export const getCustomerAddress = async customerId => {
  let customerAddress = null;
  const customerAddresses = await getDataFromTable(`/users/${customerId}/donneesPerso/addresses`);
  if (customerAddresses) {
    let addressId = null;
    const address = _.find(customerAddresses, (address, id) => {
      addressId = id;
      return address.selected && address.shopId;
    });
    if (address) {
      address.id = addressId;
      customerAddress = address;
    }
  }
  return customerAddress;
};

export const getCreditCard = () => {
  const { user } = store.getState().userReducer;
  const { cards } = user;
  if (cards) {
    const card = _.find(cards, card => {
      return card.selected;
    });
    return card;
  }
};

export const generateCustomerAddress = (shopId, address, orderType) => {
  const newAddress = _.cloneDeep(address) || {};
  const { location = {} } = newAddress;
  const { latitude, longitude } = location;
  const addressId = `${parseFloat(latitude).toFixed(6)}${parseFloat(longitude).toFixed(6)}`.replace(
    /\./g,
    ''
  );
  newAddress.id = addressId;
  newAddress.shopId = shopId;
  newAddress.orderType = orderType;
  newAddress.selected = true;
  return newAddress;
};

export const updateAddressAndShop = async (shopId, newAddress, orderType, isCustomerAddress) => {
  try {
    const { userConnected, user } = store.getState().userReducer;
    const { delivery } = store.getState().configurationReducer;
    const { shopConfiguration = {} } = store.getState().shopReducer;
    const { delivery: deliveryShop = {} } = shopConfiguration;
    const { customerId } = user;
    if (newAddress) {
      newAddress = generateCustomerAddress(shopId, newAddress, orderType);
    }
    if (
      orderType === ORDER_TYPE_DELIVERY &&
      (delivery.userServicePrice || deliveryShop.userServicePrice) &&
      shopId
    ) {
      const data = {
        shopId,
        customerLocation: newAddress.location
      };
      const response = await sendPostRequest(API_REQUEST_DELIVERY_PRICING, data, {});
      const { customerFee, deliveryPricing } = response.data;
      sendCloudWatchLogs(`Getting delivery price ${customerFee}`);
      newAddress.deliveryPrice = customerFee;
      newAddress.pricing = deliveryPricing;
    }

    if (userConnected) {
      await setUserAddressFirebase(customerId, newAddress);
    }
    const cookies = new Cookies();
    if (isCustomerAddress) {
      cookies.set('address', JSON.stringify(newAddress));
    }
    // update address redux
    store.dispatch(updateAddress(newAddress, null));
  } catch (error) {
    sendCloudWatchAlert(`Error updating address and shop ${error}`);
    throw error;
  }
};

export const getPromotion = () => {
  const { promotions } = store.getState().userReducer.user;
  const customerId = getFirebaseCustomerId();
  const { promotions: shopPromotions } = store.getState().shopReducer;
  let promoPath;
  const { orders } = store.getState().orderReducer;
  if (promotions) {
    const promotionData = _.find(promotions, (promo, ppath) => {
      const { promotion = {} } = promo;
      const { promoCode } = promotion;
      const promoCodeUsed = _.find(orders, order => {
        const { promotion } = order;
        return promotion && promotion.promoCode === promoCode;
      });
      promoPath = ppath;
      return promo.status === 'en cours' && !promoCodeUsed;
    });
    if (promotionData) {
      const { promotion } = promotionData;
      const promoExpired = _.find(shopPromotions, shopPromotion => {
        return shopPromotion.promoCode === promotion.promoCode && shopPromotion.expired;
      });
      if (promoExpired) {
        removeDataFromTable(`/users/${customerId}/donneesPerso/promotions/${promoPath}`);
        removeDataFromTable(`/devices/${customerId}/promoCodeUsed/${promoPath}`);
      } else if (isPromotionValid(promotion)) {
        return promotion;
      }
    }
  }
};

export const isPromotionValid = promotion => {
  const { customerAddress } = store.getState().userReducer;
  const { shopId } = store.getState().shopReducer;
  const { orderType } = customerAddress;
  const validPromotion =
    promotion &&
    (promotion.shopId ? promotion.shopId === shopId : true) &&
    (promotion.canals ? promotion.canals.includes(WEBAPP) : true) &&
    (promotion.orderTypes ? promotion.orderTypes.includes(orderType) : true);
  return validPromotion;
};

export const setUserShopIds = async (shopId, customerId) => {
  // Add shops to firebase
  try {
    const shopIds = await getDataFromTable(`/users/${customerId}/shopIds`);
    if (customerId && shopId) {
      const foundShopId = _.find(shopIds, shopIdTemp => {
        return shopIdTemp.shopId === shopId;
      });
      if (!foundShopId) {
        const shopIdData = {
          shopId,
          creationTime: moment().format()
        };
        await updateDataOnTable(`/users/${customerId}/shopIds/${shopId}`, shopIdData);
      }
    }
  } catch (error) {
    sendCloudWatchAlert(`Error setting user shop ids ${error}`);
  }
};

export const validatePhone = telephone => {
  const re = /^[\s()+-]*([0-9][\s()+-]*){6,20}(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
  return re.test(String(telephone).toLowerCase());
};

export const getCookieCustomerId = () => {
  const customerId = new Cookies().get('customerId');
  return customerId;
};

export const setCookieCustomerId = customerId => {
  const cookies = new Cookies();
  cookies.set('customerId', customerId);
};

export const getWebViewCustomerId = () => {
  const customerId = window.location.pathname.split('/')[3];
  return customerId;
};

export const getCustomerTableNb = () => {
  const customerId = window.location.pathname.split('/')[3];
  return customerId;
};

export const createCookieCustomer = async orderType => {
  const shopId = getShopIdFromLink();
  const address = await getDataFromTable(`/shops/${shopId}/address`);
  let customerId = getCookieCustomerId();
  if (!customerId) {
    customerId = generateUUID();
  }
  const newAddress = generateCustomerAddress(shopId, address, orderType);
  const customerProfile = {
    addresses: {
      [newAddress.id]: newAddress
    },
    id: customerId
  };
  await createCustomerBackend(customerProfile);
  setCookieCustomerId(customerId);
  return customerId;
};

export const generateUUID = () => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  return uuid;
};

export const formatPhone = (phone, countryCode = '+33') => {
  if (isStringNotNull(phone)) {
    if (phone.startsWith('0') && phone.length > 1) {
      phone = phone.replace('0', countryCode);
    }
    phone = phone.replace(/ /g, '');
  }

  return phone;
};

export const removeAddress = async address => {
  sendCloudWatchLogs(`Removing address ${JSON.stringify(address)}`);
  const cookies = new Cookies();
  cookies.remove('address');
  const customerId = getFirebaseCustomerId();
  if (customerId) {
    await removeDataFromTable(`/users/${customerId}/donneesPerso/addresses/${address.id}`);
  }
  store.dispatch(updateAddress(null));
};
export const phoneNumberMinLength = iso2 => {
  const minLengths = {
    fr: 9,
    be: 8,
    us: 10,
    no: 8,
    mc: 8,
    sw: 7,
    al: 8,
    de: 11
  };
  return minLengths[iso2] || 5;
};
