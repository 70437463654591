import {
  SECTION_HORIZONTA,
  START_CONTENT,
  SECTION_HORIZONTAL,
  WAIT_TIMEOUT,
  INACTIVITY_TIMEOUT,
  BLOCK_CONTENT,
  ORDER_TYPE_DELIVERY,
  TERMINAL_V,
  TERMINAL_H,
  TERMINAL,
  QRCODE,
  WEBVIEW
} from '../constants';
import store from '../redux/store';
import {
  showCartModal,
  showProductModal,
  showOverlayCover,
  setTerminalTimeout,
  showInactivityModal
} from '../redux/actions/componentAction';
import { resetCart } from '../redux/actions/cartAction';
import { sendCloudWatchLogs, sendCloudWatchAlert } from './logs';
import { unlistenFirebase } from '../api/firebase';
import { isStringNotNull } from './dataType';

export const isWebView = () => {
  const domain = window.location.href;
  if (domain.includes(WEBVIEW)) {
    return true;
  }
  return false;
};

export const isQrCode = () => {
  const domain = window.location.href;
  if (domain.includes(QRCODE) && !domain.includes('utm')) {
    return true;
  }
  return false;
};

export const isTerminal = () => {
  const domain = window.location.href;
  if (domain.includes(TERMINAL)) {
    return true;
  }
  return false;
};

export const isTerminalVertical = () => {
  const domain = window.location.href;
  if (domain.includes(TERMINAL_V)) {
    return true;
  }
  return false;
};

export const getWebViewPageId = () => {
  const pageId = window.location.pathname.split('/')[2];
  return pageId;
};

export const getTerminalId = () => {
  const terminalId = window.location.pathname.split('/')[3];
  return terminalId;
};

export const isChatbot = () => {
  const { webapp } = store.getState().configurationReducer;
  const domain = window.location.hostname;
  if ((webapp && webapp.chatbot) || domain.includes('demobot')) {
    return true;
  }
  return false;
};

export const isDev = () => {
  const domain = window.location.hostname;
  if (domain.includes('localhost')) {
    return true;
  }
  return false;
};

export const getCompanyIdFromDomain = () => {
  let subdomain = window.location.hostname;
  // subdomain = 'ilove-rotisserie';
  subdomain = subdomain.replace(/^www\./, '');
  const companyId = subdomain.split('.')[0];
  return companyId;
};

export const getOffsetTop = (element) => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
};

export const resetTerminalTimeout = () => {
  let { terminalTimeout } = store.getState().componentReducer;
  clearTimeout(terminalTimeout);
  terminalTimeout = null;
  terminalTimeout = setTimeout(() => {
    sendCloudWatchLogs('show inactivity  session');
    store.dispatch(showInactivityModal(true));
    terminalTimeout = setTimeout(() => {
      resetTerminalSession();
    }, WAIT_TIMEOUT);
    store.dispatch(setTerminalTimeout(terminalTimeout));
  }, INACTIVITY_TIMEOUT);
  store.dispatch(setTerminalTimeout(terminalTimeout));
};

export const resetTerminalSession = () => {
  const { terminalTimeout } = store.getState().componentReducer;
  const { user } = store.getState().userReducer;
  sendCloudWatchLogs('Close and reset session');
  clearTimeout(terminalTimeout);
  store.dispatch(setTerminalTimeout(null));
  store.dispatch(showCartModal(false));
  store.dispatch(showProductModal(false));
  store.dispatch(showInactivityModal(false));
  store.dispatch(resetCart());
  unlistenFirebase(user.customerId);
  store.dispatch(showOverlayCover(START_CONTENT));
};

export const getPaymentTypes = () => {
  let { paymentTypes } = store.getState().configurationReducer;
  const { qrcode, terminal, delivery } = store.getState().configurationReducer;
  const { shopConfiguration = {} } = store.getState().shopReducer;
  const { customerAddress } = store.getState().userReducer;
  const { paymentTypes: deliveryPaymentTypes } = delivery;
  if (isQrCode() && qrcode) {
    paymentTypes = qrcode.paymentTypes;
  } else if (isTerminal() && terminal) {
    paymentTypes = terminal.paymentTypes;
  } else {
    if (shopConfiguration.paymentTypes) {
      paymentTypes = shopConfiguration.paymentTypes;
    }
    if (customerAddress && customerAddress.orderType === ORDER_TYPE_DELIVERY) {
      if (
        shopConfiguration.delivery &&
        shopConfiguration.delivery.paymentTypes
      ) {
        paymentTypes = shopConfiguration.delivery.paymentTypes;
      } else if (deliveryPaymentTypes) {
        paymentTypes = deliveryPaymentTypes;
      }
    }
  }
  return paymentTypes;
};

export const checkAccessContent = () => {
  const { qrcode, terminal, webapp } = store.getState().configurationReducer;
  if (isQrCode() && (!qrcode || !qrcode.enabled)) {
    store.dispatch(showOverlayCover(BLOCK_CONTENT));
  }
  if (isTerminal() && (!terminal || !terminal.enabled)) {
    store.dispatch(showOverlayCover(BLOCK_CONTENT));
  }
  if (!isTerminal() && !isQrCode() && (!webapp || !webapp.enabled)) {
    store.dispatch(showOverlayCover(BLOCK_CONTENT));
  }
};

export const isDarkTheme = () => {
  const { theme, marketplace } = store.getState().configurationReducer;
  return theme === 'dark' && (!marketplace || !marketplace.enabled);
};

export const getTheme = (index, baackgroundImage) => {
  const style = {
    backgroundColor: index % 2 ? 'black' : 'white'
  };
  const textColor = index % 2 ? 'text-white' : 'text-dark';
  if (index % 2 && isStringNotNull(baackgroundImage)) {
    style.backgroundImage = `url(${baackgroundImage})`;
    style.backgroundSize = 'cover';
  }
  return { style, textColor };
};

export const getMetadata = (name, webapp, descriptionText) => {
  let keywords =
    'Service, de, livraison, rapide, livraison, emporter, commande, Dishop';
  let title = name;
  let description = '';
  const {
    descriptionTitle,
    descriptionAddress,
    description: descriptionWebApp,
    keywords: keywordsWebApp
  } = webapp;
  if (webapp) {
    if (isStringNotNull(descriptionTitle)) {
      title += ` • ${descriptionTitle.slice(0, 50)}`;
    } else if (isStringNotNull(descriptionText)) {
      title += ` • ${descriptionText.slice(0, 50)}`;
      description = descriptionText.slice(0, 160);
    } else if (isStringNotNull(webapp.descriptionAddress)) {
      title += ` • ${descriptionAddress.slice(0, 50)}`;
      description = descriptionAddress.slice(0, 160);
    }
    if (isStringNotNull(descriptionWebApp)) {
      description = descriptionWebApp.slice(0, 160);
    }
    if (isStringNotNull(keywordsWebApp)) {
      keywords = keywordsWebApp;
    }
  }
  return { title, description, keywords };
};

export const interceptConsole = () => {
  // taken from http://tobyho.com/2012/07/27/taking-over-console-log/
  const { console } = window;
  if (!console) return;

  function intercept(method) {
    const original = console[method];
    console[method] = function() {
      // check message
      if (arguments[0] && String(arguments[0]).includes('MapError')) {
        sendCloudWatchAlert(`Error google maps ${arguments[0]}`);
      }

      if (original.apply) {
        // Do this for normal browsers
        original.apply(console, arguments);
      } else {
        // Do this for IE
        const message = Array.prototype.slice.apply(arguments).join(' ');
        original(message);
      }
    };
  }
  const methods = ['error']; // only interested in the console.error method
  for (let i = 0; i < methods.length; i++) intercept(methods[i]);
};

export const getCurrentLanguage = () => {
  let language = 'fr';
  if (window.Localize && window.Localize.getLanguage()) {
    language = window.Localize.getLanguage();
    if (language === 'source') {
      language = 'fr';
    }
  }
  return language;
};

export const getTerminalVerticalHeight = () => {
  const { navbarHeight } = store.getState().componentReducer;
  let terminalMarginBottom = 0;
  if (document.getElementById('bottom-cart')) {
    terminalMarginBottom = document.getElementById('bottom-cart').scrollHeight;
  }
  return (
    window.innerHeight -
    Number(navbarHeight.replace('px', '')) -
    terminalMarginBottom -
    16
  );
};

export const isSplash = () => {
  const domain = window.location.href;
  const { splash } = store.getState().componentReducer;
  return splash || domain.includes('splash');
};
