import {
  getOptionAssociatedProduct,
  isProductDisabled,
  isStringNotNull,
  getCurrentNbOption,
  getNbInCartOfProduct,
  getStockRemaining
} from '../../utils';

export const isChecked = (productItems, index, itemName, itemKey) => {
  const isChecked =
    productItems[index] &&
    productItems[index].options &&
    productItems[index].options[itemKey] &&
    productItems[index].options[itemKey].name === itemName;

  return isChecked || false;
};

export const getStockOption = option => {
  let stockOption = null;
  const product = getOptionAssociatedProduct(option);
  if (product && parseInt(product.stock, 10) >= 0) {
    stockOption = product.stock;
  }
  return stockOption;
};

export const getStockRemainingOption = (options, option, nbProduct) => {
  let stockRemainingOption = null;
  const stockOption = getStockOption(option);
  if (parseInt(stockOption, 10) >= 0) {
    const { key, productKey } = option;
    const nbOption = getCurrentNbOption(options, key);
    const nbInCart = getNbInCartOfProduct(key, productKey);
    stockRemainingOption = getStockRemaining(stockOption, nbInCart, nbProduct * nbOption);
    if (
      stockRemainingOption > 0 &&
      getStockRemaining(stockOption, nbInCart, nbProduct * (nbOption + 1)) < 0
    ) {
      stockRemainingOption = 0;
    }
  }
  return stockRemainingOption;
};

export const optionHasNotEnoughStock = (component, item, nbProduct) => {
  const { productItems } = component.props;
  let stockRemainingOption = false;
  let notEnoughStock = false;
  const { productKey } = item;
  if (productKey) {
    const product = getOptionAssociatedProduct(item);
    if (product) {
      stockRemainingOption = getStockRemainingOption(productItems, item, nbProduct);
      notEnoughStock = isProductDisabled(product);
    }
  }
  return notEnoughStock || parseInt(stockRemainingOption, 10) <= 0;
};

  export const getOptionData = item => {
    const { productKey } = item;
    let result = {};
    if (productKey) {
       const product = getOptionAssociatedProduct(item);
      if (product) {
        const { image, description } = product;
        result = { image, description };
      }
    }
    return result;
};

export const handleCounterClick = (component, item, itemKey, action) => {
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }
  const { index, categoryId, categoryKey, addItem, removeItem } = component.props;
  if (action === 'add') {
    addItem(index, categoryId, categoryKey, itemKey, item, true);
  } else {
    removeItem(index, categoryId, itemKey, true);
  }
};

export const handleCheckboxClick = (component, item, itemKey) => {
  const { index, categoryId, categoryKey, addItem, removeItem, productItems } = component.props;
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }

  const checked = isChecked(productItems, index, item.name, itemKey);
  if (!checked) {
    addItem(index, categoryId, categoryKey, itemKey, item);
  } else {
    removeItem(index, categoryId, itemKey);
  }
};

export const handleRadioButtonClick = (component, itemKey) => {
  const { index, category, categoryId, addUniqueItem, categoryKey } = component.props;
  const item = category.items[itemKey];
  if (!isStringNotNull(item.price)) {
    item.price = 0;
  }
  addUniqueItem(index, categoryId, categoryKey, itemKey, item);
};
