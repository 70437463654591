import firebase from 'firebase';
import _ from 'lodash';
import store from '../redux/store';
import {
  UPDATE_ORDERS,
  UPDATE_PAST_ORDERS,
  UPDATE_CART,
  UPDATE_LAST_ORDER_CHARGE_IDS,
  UPDATE_CANCELED_ORDERS,
  MESSAGE_MODAL,
  UPDATE_SHOP,
  UPDATE_DRIVERS_DATA,
  UPDATE_SHOPS,
  SHOW_ORDER_MODAL,
  SHOW_ORDER_STATUS,
  UPDATE_SHOP_ORDERS
} from '../redux/actions/types';
import {
  CUSTOMER_CANCEL,
  DRIVER_CANCEL,
  SHOP_CANCEL,
  CANCEL_CLIENT_UNAVAILABLE,
  ORDER_PHASE_PREPARING,
  SHOP_CANCEL_AND_REFUND,
  SHOP_CANCEL_PHASE_CONTACTING,
  PAYMENT_TYPE_STRIPE,
  ORDER_PHASE_DELIVERING
} from '../constants';
import { getCompanyIdFromDomain } from '../utils/config';
import { showMessageModal, updateAddress } from '../redux/actions';
import { sendCloudWatchAlert } from '../utils/logs';
import { getCustomerAddress, setUserShopIds, formatPhone } from '../utils/customer';
import { removeUndefined } from '../utils/dataType';
import { updateCustomerHubrise, signUpCustomerHubrise } from './hubrise';
import { createCustomerBackend } from './backend';

let firebaseModule;
let firebaseFleet;
export const getFirebaseCustomerId = () => {
  const { currentUser } = firebaseModule.auth();
  let customerId = null;
  if (currentUser) {
    customerId = currentUser.uid;
  }
  return customerId;
};

export const getIdToken = async () => {
  const idToken = await firebaseModule.auth().currentUser.getIdToken(true);
  return idToken;
};

export const initializeFirebase = async () => {
  const response = await fetch(
    'https://dishop-configuration-default-rtdb.europe-west1.firebasedatabase.app/.json'
  );
  const firebaseConfiguration = await response.json();
  const companyId = getCompanyIdFromDomain();
  // const companyId = 'peppinopizza';
  const configFleet = firebaseConfiguration.fleet;
  let config = _.find(firebaseConfiguration, (value, key) => {
    return companyId === key;
  });
  if (!config) {
    config = firebaseConfiguration.dev;
  }
  try {
    firebaseModule = await firebase.initializeApp(config, 'module');
    firebaseFleet = await firebase.initializeApp(configFleet, 'fleet');
    return firebaseModule;
  } catch (error) {
    await firebase.app('fleet').delete();
    await firebase.app('module').delete();
    firebaseFleet = await firebase.initializeApp(configFleet, 'fleet');
    firebaseModule = await firebase.initializeApp(config, 'module');
    return firebaseModule;
  }
};

export const getDataFromTable = async table => {
  try {
    const snapshot = await firebaseModule
      .database()
      .ref(table)
      .once('value');
    return snapshot.val();
  } catch (error) {
    throw error;
  }
};

export const getDataFromTableWithLimit = async (table, limit) => {
  const snapshot = await firebaseModule
    .database()
    .ref(table)
    .limitToFirst(limit)
    .once('value');
  return snapshot.val();
};

export const updateDataOnTable = async (table, update) => {
  try {
    await firebaseModule
      .database()
      .ref(table)
      .update(update);
    return true;
  } catch (error) {
    throw error;
  }
};

export const setDataOnTable = async (table, value) => {
  try {
    await firebaseModule
      .database()
      .ref(table)
      .set(value);
    return true;
  } catch (error) {
    throw error;
  }
};

export const pushDataOnTable = async (table, value) => {
  try {
    await firebaseModule
      .database()
      .ref(table)
      .push(value);
    return true;
  } catch (error) {
    throw error;
  }
};

export const signOut = async () => {
  await firebaseModule.auth().signOut();
};

export const signInUser = async (email, password) => {
  try {
    const response = await firebaseModule.auth().signInWithEmailAndPassword(email, password);
    return response.user.uid;
  } catch (error) {
    const errorMessage = String(error);
    if (
      !errorMessage.includes('already') &&
      !errorMessage.includes('6 characterss') &&
      !errorMessage.includes('user does not have a password') &&
      !errorMessage.includes('There is no user record')
    ) {
      sendCloudWatchAlert(`Error login ${errorMessage}`);
    }
    const errorCode = error.code;
    throw errorCode;
  }
};

export const facebookUserSignIn = async () => {
  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebaseModule.auth().languageCode = 'fr_FR';
    const response = await firebaseModule.auth().signInWithPopup(provider);
    if (response.additionalUserInfo.isNewUser) {
      const { uid, displayName, email } = response.user;
      const firstName = displayName.split(' ')[0];
      const lastName = displayName.split(/ (.+)/)[1];
      const profile = {
        firstName,
        lastName,
        email,
        firstOrder: true,
        newsletter: false,
        id: uid
      };
      await createCustomerBackend(profile);
    }
    const { uid } = response.user;
    return uid;
  } catch (error) {
    const errorCode = error.code;
    throw errorCode;
  }
};

export const googleUserSignIn = async () => {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebaseModule.auth().languageCode = 'fr_FR';
    const response = await firebaseModule.auth().signInWithPopup(provider);
    if (response.additionalUserInfo.isNewUser) {
      const { uid, displayName, email } = response.user;
      const firstName = displayName.split(' ')[0];
      const lastName = displayName.split(/ (.+)/)[1];
      const profile = {
        firstName,
        lastName,
        email,
        firstOrder: true,
        newsletter: false,
        id: uid
      };
      await createCustomerBackend(profile);
    }
    const { uid } = response.user;
    return uid;
  } catch (error) {
    const errorCode = error.code;
    throw errorCode;
  }
};

export const deleteUser = async () => {
  const user = firebaseModule.auth().currentUser;
  if (user) {
    try {
      sendCloudWatchAlert(`Delete user ${user.uid}`);
      await user.delete();
    } catch (error) {
      sendCloudWatchAlert(`Could not delete user ${error}`);
      throw error;
    }
  }
};

export const signUpUser = async (email, password, lastName, firstName, numero, newsletter) => {
  try {
    const response = await firebaseModule.auth().createUserWithEmailAndPassword(email, password);
    const customerId = response.user.uid;
    numero = formatPhone(numero);
    const profile = {
      firstName,
      lastName,
      email,
      numero,
      firstOrder: true,
      newsletter,
      id: customerId
    };
    await createCustomerBackend(profile);
    const { uid } = response.user;
    return uid;
  } catch (error) {
    const errorMessage = String(error);
    sendCloudWatchAlert(`Error sign up ${errorMessage}`);
    const errorCode = error.code;
    throw errorCode;
  }
};

export const forgetPasswordUser = async email => {
  const auth = firebaseModule.auth();
  auth.useDeviceLanguage();
  try {
    await auth.sendPasswordResetEmail(email);
    store.dispatch(
      showMessageModal(
        'Un email vous a été envoyé, cliquer sur le lien dans celui ci pour réinitialiser votre mot de passe'
      )
    );
  } catch (error) {
    const errorCode = error.code;
    throw errorCode;
  }
};

export const pushDataToTable = async (table, data) => {
  try {
    await firebaseModule
      .database()
      .ref(table)
      .update(data);

    return true;
  } catch (error) {
    throw error;
  }
};

export const removeDataFromTable = async table => {
  try {
    await firebaseModule
      .database()
      .ref(table)
      .remove();
    return true;
  } catch (error) {
    throw error;
  }
};

export const updatesDataOnTable = async updates => {
  try {
    await firebaseModule
      .database()
      .ref()
      .update(updates);
    return true;
  } catch (error) {
    throw error;
  }
};

export const setUserAddressFirebase = async (customerId, newAddress) => {
  try {
    const { isUserAnonymous } = store.getState().userReducer;
    const updates = {};
    const oldCustomerAddress = await getCustomerAddress(customerId);
    if (oldCustomerAddress && oldCustomerAddress.id) {
      oldCustomerAddress.selected = false;
      updates[
        `/users/${customerId}/donneesPerso/addresses/${oldCustomerAddress.id}`
      ] = oldCustomerAddress;
    }
    newAddress.selected = true;
    const addressId = newAddress.id;
    updates[`/users/${customerId}/donneesPerso/addresses/${addressId}`] = newAddress;
    // update address firebase
    await updatesDataOnTable(updates);
    if (!isUserAnonymous && newAddress.shopId) {
      setUserShopIds(newAddress.shopId, customerId);
    }
  } catch (error) {
    sendCloudWatchAlert(`Eerror setting user address ${error}`);
    throw error;
  }
};

export const addToCartFirebase = async (customerId, cartItemKey, newProduct) => {
  removeUndefined(newProduct.items);
  await updateDataOnTable(`/users/${customerId}/pendingOrder/commande`, {
    [cartItemKey]: newProduct
  });
};

export const removeToCartFirebase = async (customerId, cartItemKey) => {
  await removeDataFromTable(`/users/${customerId}/pendingOrder/commande/${cartItemKey}`);
};

export const unlistenFirebase = customerId => {
  firebaseModule
    .database()
    .ref(`/users/${customerId}`)
    .off('value');
  firebaseModule
    .database()
    .ref(`/orders/${customerId}`)
    .off('value');
  firebaseModule
    .database()
    .ref(`/users/${customerId}/pendingOrder/commande`)
    .off('value');
  firebaseModule
    .database()
    .ref(`/pastOrders/${customerId}`)
    .off('value');
  firebaseModule
    .database()
    .ref(`/canceledOrder/${customerId}`)
    .off('value');
};

export const listenFirebaseCart = async customerId => {
  firebaseModule
    .database()
    .ref(`/users/${customerId}/pendingOrder/commande`)
    .off('value');
  firebaseModule
    .database()
    .ref(`/users/${customerId}/pendingOrder/commande`)
    .on('value', snapshot => {
      let cart = snapshot.val();
      if (!cart) {
        cart = {};
      }
      store.dispatch({ type: UPDATE_CART, payload: cart });
    });
};

export const listenOrders = shopId => {
  firebaseModule
    .database()
    .ref('/orders')
    .off('value');
  firebaseModule
    .database()
    .ref(`/orders`)
    .on('value', snapshot => {
      const ordersByCustomerId = snapshot.val();
      const ordersByShopId = [];
      _.map(ordersByCustomerId, orders => {
        _.map(orders, order => {
          if (order.shopId === shopId) {
            ordersByShopId.push(order);
          }
        });
      });
      store.dispatch({ type: UPDATE_SHOP_ORDERS, payload: ordersByShopId });
    });
};

export const listenLastOrderChargeId = async customerId => {
  firebaseModule
    .database()
    .ref(`/users/${customerId}/lastOrderChargeIds`)
    .off('value');
  firebaseModule
    .database()
    .ref(`/users/${customerId}/lastOrderChargeIds`)
    .on('value', snapshot => {
      const lastOrderChargeIds = snapshot.val();
      store.dispatch({ type: UPDATE_LAST_ORDER_CHARGE_IDS, payload: lastOrderChargeIds || {} });
    });
};

export const listenFirebaseUsersData = async (customerId, isUserAnonymous, updateUser) => {
  try {
    unlistenFirebase(customerId);
    if (!isUserAnonymous) {
      listenLastOrderChargeId(customerId);
    }
    firebaseModule
      .database()
      .ref(`/users/${customerId}`)
      .on('value', async snapshot => {
        const customerProfile = snapshot.val() || {};
        const { shopIds, donneesPerso } = customerProfile;
        const address = await getCustomerAddress(customerId);
        if (address) {
          store.dispatch(updateAddress(address));
        }
        store.dispatch(updateUser(donneesPerso, shopIds, customerId, isUserAnonymous));
      });
    firebaseModule
      .database()
      .ref(`/orders/${customerId}`)
      .on('value', snapshot => {
        const newOrders = snapshot.val();
        const { orders } = store.getState().orderReducer;
        if (newOrders) {
          _.map(newOrders, newOrder => {
            const { status, idDep, chargeId, delivery = {} } = newOrder;
            const order = _.find(orders, order => {
              return order.chargeId === chargeId;
            });
            if (status === ORDER_PHASE_DELIVERING && idDep) {
              listenDriverData(idDep, chargeId, delivery.freelance);
            }
            if (
              order &&
              status === ORDER_PHASE_PREPARING &&
              order.status === ORDER_PHASE_DELIVERING
            ) {
              unlistenDriverData(order.idDep, order.chargeId, delivery.freelance);
            }
          });
        }
        store.dispatch({ type: UPDATE_ORDERS, payload: newOrders });
      });
    firebaseModule
      .database()
      .ref(`/pastOrders/${customerId}`)
      .on('value', snapshot => {
        const pastOrders = snapshot.val();
        if (!_.isEmpty(pastOrders)) {
          const { lastOrderChargeIds } = store.getState().userReducer;
          const { orders } = store.getState().orderReducer;
          const { orderStatus } = store.getState().componentReducer;
          const lastPastOrder = _.find(lastOrderChargeIds, lastOrderChargeId => {
            return pastOrders[lastOrderChargeId];
          });
          if (lastPastOrder) {
            const { orderNumber, chargeId, idDep, delivery = {} } = pastOrders[lastPastOrder];
            if (idDep) {
              unlistenDriverData(idDep, chargeId, delivery.freelance);
            }
            if (orderStatus === chargeId) {
              store.dispatch({ type: SHOW_ORDER_STATUS, payload: false });
            }
            if (_.isEmpty(orders)) {
              store.dispatch({ type: SHOW_ORDER_MODAL, payload: false });
            }
            store.dispatch({
              type: MESSAGE_MODAL,
              payload: {
                message: `Votre commande n°${orderNumber} est terminée. Merci pour votre fidélité et à très
              bientôt !`
              }
            });
            removeDataFromTable(`users/${customerId}/lastOrderChargeIds/${chargeId}`);
          }
          store.dispatch({ type: UPDATE_PAST_ORDERS, payload: pastOrders });
        } else {
          store.dispatch({ type: UPDATE_PAST_ORDERS, payload: {} });
        }
      });
    firebaseModule
      .database()
      .ref(`/canceledOrder/${customerId}`)
      .on('value', snapshot => {
        const canceledOrders = snapshot.val();
        if (!_.isEmpty(canceledOrders)) {
          const { lastOrderChargeIds } = store.getState().userReducer;
          const { support } = store.getState().shopReducer;
          const { orders } = store.getState().orderReducer;
          const { orderStatus } = store.getState().componentReducer;
          const lastCanceledOrder = _.find(lastOrderChargeIds, lastOrderChargeId => {
            return canceledOrders[lastOrderChargeId];
          });
          if (lastCanceledOrder) {
            const {
              cancellation,
              paymentType,
              userCancelServicePrice,
              orderNumber,
              chargeId,
              idDep,
              totalPriceOrder,
              delivery = {}
            } = canceledOrders[lastCanceledOrder];
            const { canceledBy, cancelReason } = cancellation;
            if (idDep) {
              unlistenDriverData(idDep, chargeId, delivery.freelance);
            }
            let cancelMessage = `Le magasin n'a pas pu prendre votre commande n°${orderNumber}. Vous pouvez contacter le magasin ${
              support && support.number ? `au ${support.number}` : ''
            } pour plus d'informations.`;
            if (cancelReason === CANCEL_CLIENT_UNAVAILABLE) {
              if (canceledBy === DRIVER_CANCEL) {
                cancelMessage = `Le livreur a annulé votre commande n°${orderNumber} car vous étiez injoignable.`;
              } else {
                cancelMessage = `Le magasin a annulé votre commande n°${orderNumber} car vous étiez injoignable.`;
              }
              if (paymentType === PAYMENT_TYPE_STRIPE && userCancelServicePrice > 0) {
                cancelMessage += ` Vous avez été facturé d'un montant de ${userCancelServicePrice} €.`;
              }
            }
            if (cancelReason === SHOP_CANCEL_AND_REFUND) {
              cancelMessage = `Le magasin a remboursé votre commande n°${orderNumber}. Vous avez été remboursé d'un montant de ${totalPriceOrder} €.`;
            }
            if (canceledBy !== CUSTOMER_CANCEL) {
              store.dispatch({
                type: MESSAGE_MODAL,
                payload: {
                  message: cancelMessage
                }
              });
            }
            if (orderStatus === chargeId) {
              store.dispatch({ type: SHOW_ORDER_STATUS, payload: false });
            }
            if (_.isEmpty(orders)) {
              store.dispatch({ type: SHOW_ORDER_MODAL, payload: false });
            }
            removeDataFromTable(`users/${customerId}/lastOrderChargeIds/${chargeId}`);
          }

          store.dispatch({ type: UPDATE_CANCELED_ORDERS, payload: canceledOrders });
        }
      });
    listenFirebaseCart(customerId);
    return true;
  } catch (error) {
    throw error;
  }
};

export const getShopsData = async () => {
  try {
    const shops = await getDataFromTable('/shops');
    store.dispatch({ type: UPDATE_SHOPS, payload: shops });
    return shops;
  } catch (error) {
    sendCloudWatchAlert(`Could not fetch shops ${error}`);
  }
};

export const listenShopData = async (previousShop, shopId, customerAddress, getShopAndZoneData) => {
  try {
    if (previousShop) {
      firebaseModule
        .database()
        .ref(`/shops/${previousShop}`)
        .off();
    }
    firebaseModule
      .database()
      .ref(`/shops/${shopId}`)
      .on('value', snapshot => {
        let shop = snapshot.val();
        shop = getShopAndZoneData(shopId, shop, customerAddress);
        if (shop) {
          store.dispatch({ type: UPDATE_SHOP, payload: shop });
        }
      });
    listenOrders(shopId);
    return true;
  } catch (error) {
    throw error;
  }
};

export const listenDriverData = async (driverId, chargeId, driverFreelance) => {
  let firebase = firebaseModule;
  if (driverFreelance) {
    firebase = firebaseFleet;
  }
  firebase
    .database()
    .ref(`/drivers/${driverId}`)
    .off();
  firebase
    .database()
    .ref(`/drivers/${driverId}`)
    .on('value', snapshot => {
      if (snapshot.val()) {
        const { firstName, lastName, lat, long, numero } = snapshot.val();
        const driverData = {
          driverFirstName: firstName,
          driverLastName: lastName,
          driverLocation: {
            latitude: lat,
            longitude: long
          },
          driverNumero: numero
        };
        store.dispatch({ type: UPDATE_DRIVERS_DATA, payload: { driverData, chargeId } });
      }
    });
};

export const unlistenDriverData = async (driverId, chargeId, driverFreelance) => {
  let firebase = firebaseModule;
  if (driverFreelance) {
    firebase = firebaseFleet;
  }
  firebase
    .database()
    .ref(`/drivers/${driverId}`)
    .off();
  const driverData = null;
  store.dispatch({ type: UPDATE_DRIVERS_DATA, payload: { driverData, chargeId } });
};

export const updateAccountFirebase = async (firstName, lastName, numero) => {
  const { user, userConnected } = store.getState().userReducer;
  try {
    if (userConnected) {
      const { customerId } = user;
      const updates = {};
      updates[`/users/${customerId}/donneesPerso/firstName`] = firstName || '';
      updates[`/users/${customerId}/donneesPerso/lastName`] = lastName || '';
      updates[`/users/${customerId}/donneesPerso/numero`] = numero || '';
      // update user info firebase
      await updatesDataOnTable(updates);
    }
  } catch (error) {
    throw error;
  }
};
export const ckeckUserAccount = async email => {
  try {
    const data = await firebaseModule.auth().fetchSignInMethodsForEmail(email);
    return data;
  } catch (error) {
    sendCloudWatchAlert(`Error fetching user with email ${error}`);
    throw error;
  }
};