import _ from 'lodash';
import { isTerminalVertical, getOffsetTop } from '../../../utils';

export const handleScrollTerminalVertical = component => {
  if (isTerminalVertical()) {
    document.getElementById('list-products').addEventListener('scroll', () => {
      const { selectSection, navbarHeight } = component.props;
      const extraTop = 4;
      let offsetTop = 0;
      let newSectionKey = null;
      if (component.sectionsRef) {
        _.map(component.sectionsRef, (sectionRef, sectionKey) => {
          const scrollY = document.getElementById('list-products').scrollTop;
          if (
            sectionRef &&
            scrollY >= getOffsetTop(sectionRef) - parseInt(navbarHeight, 10) - extraTop &&
            getOffsetTop(sectionRef) > offsetTop
          ) {
            offsetTop = getOffsetTop(sectionRef);
            newSectionKey = sectionKey;
          }
        });

        if (newSectionKey) {
          selectSection(newSectionKey);
        }
      }
    });
  }
};
