import { sendCloudWatchAlert, formatPhone } from '../../utils';
import { deleteCustomerAccountBackend } from '../../api/backend';
import { updateCustomerHubrise } from '../../api/hubrise';
import { updateAccountFirebase } from '../../api/firebase';

export const deleteUserAccount = async component => {
  const { showUpdateInfo, showAccountModal, showMessageModal, userSignOut } = component.props;
  try {
    await deleteCustomerAccountBackend();
    userSignOut();
    showUpdateInfo(false);
    showAccountModal(false);
    showMessageModal(`Nous vous confirmons la suppression de votre compte.`);
  } catch (error) {
    showMessageModal(`Nous n'avons pas pu supprimer votre compte, veuillez essayer de nouveau.`);
    sendCloudWatchAlert(`Error deleting user account ${error}`);
  }
};

export const updateAccount = async (component, firstName, lastName, numero) => {
  const { updateProfile, showMessageModal, showUpdateInfo } = component.props;
  try {
    component.setState({ loading: true });
    numero = formatPhone(numero);
    updateProfile('fistName', firstName);
    updateProfile('lastName', lastName);
    updateProfile('numero', numero);
    await updateCustomerHubrise({ firstName, lastName, numero });
    await updateAccountFirebase(firstName, lastName, numero);
    showUpdateInfo(false);
    component.setState({ loading: false });
    showMessageModal('Votre compte a bien été mis à jour');
  } catch (error) {
    const errorMessage = error;
    sendCloudWatchAlert(`Could not update user info ${errorMessage}`);
    component.setState({ loading: false });
    showMessageModal(
      'La modification de votre compte a échoué. Veuillez vérifier les informations saisies.'
    );
  }
};
