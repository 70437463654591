import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Check from '@material-ui/icons/Check';
import ForumIcon from '@material-ui/icons/Forum';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StepConnector from '@material-ui/core/StepConnector';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import StoreIcon from '@material-ui/icons/Store';
import { UNIT } from '../../constants/configuration';

import {
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_TYPE_CLICK_AND_SEAT,
  COLOR_GREY,
  DARK_COLOR
} from '../../constants';
import { isDarkTheme } from '../../utils';

const StepperStyled = withStyles(() => ({
  root: {
    'padding-right': '0px',
    'padding-left': '0px',
    'padding-top': '10px',
    'padding-bottom': '10px',
    'background-color': isDarkTheme() && DARK_COLOR
  }
}))(Stepper);

class OrderSteps extends React.Component {
  render() {
    const { order } = this.props;
    const { orderType } = order;
    const { secondaryColor } = this.props;
    const steps = ['Réception', 'Préparation'];

    if (orderType === ORDER_TYPE_CLICK_AND_COLLECT) {
      steps.push('En attente de récupération');
    } else if (orderType === ORDER_TYPE_CLICK_AND_SEAT) {
      steps.push('Service à table');
    } else {
      steps.push('En cours de livraison');
    }
    steps.push('Terminé');

    const ConnectorStyle = withStyles({
      root: {
        '& .MuiStepper-root': {
          padding: '0px'
        }
      },
      alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
        padding: '0px'
      },
      active: {
        '& $line': {
          borderColor: COLOR_GREY
        }
      },
      completed: {
        '& $line': {
          borderColor: secondaryColor
        }
      },
      line: {
        borderColor: COLOR_GREY,
        borderTopWidth: 3,
        borderRadius: 1
      }
    })(StepConnector);

    const StepIconStyle = makeStyles({
      root: {
        color: COLOR_GREY,
        display: 'flex',
        height: 22,
        alignItems: 'center'
      },
      active: {
        color: secondaryColor
      },
      completed: {
        color: secondaryColor,
        zIndex: 1,
        fontSize: UNIT * 1.125
      }
    });

    const StepIconClasses = props => {
      const classes = StepIconStyle();
      const { active, completed } = props;

      let icons = {
        1: <ForumIcon />,
        2: <RestaurantIcon />,
        3: <DirectionsBikeIcon />,
        4: <Check />
      };

      if (orderType !== ORDER_TYPE_DELIVERY) {
        icons = {
          1: <ForumIcon />,
          2: <RestaurantIcon />,
          3: <StoreIcon />,
          4: <Check />
        };
      }

      return (
        <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed
          })}
        >
          {icons[String(props.icon)]}
        </div>
      );
    };

    StepIconClasses.propTypes = {
      active: PropTypes.bool,
      completed: PropTypes.bool
    };

    const { activeStep } = this.props;

    return (
      <div>
        <StepperStyled alternativeLabel activeStep={activeStep} connector={<ConnectorStyle />}>
          {steps.map((label, index) => (
            <Step key={label} className='px-0'>
              <StepLabel StepIconComponent={StepIconClasses}>
                <span className={`${index < activeStep ? 'label' : 'disabled-label'}`}>
                  {label}
                </span>
              </StepLabel>
            </Step>
          ))}
        </StepperStyled>
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { secondaryColor } = configurationReducer;
  return {
    secondaryColor
  };
};

export default connect(mapStateToProps)(OrderSteps);