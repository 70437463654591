import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import StoreIcon from '@material-ui/icons/Store';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCart';
import PersonOutlineOutlinedIcon from '@material-ui/icons/Person';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FlagsSelect from '../FlagsSelect';
import Button from '../Button';
import {
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_CLICK_AND_COLLECT,
  UNIT
} from '../../constants';
import {
  isDarkTheme,
  isStringNotNull,
  isTerminal,
  isQrCode,
  resetTerminalSession,
  getNbTotalCart,
  sendCloudWatchAlert,
  sendCloudWatchLogs
} from '../../utils';
import {
  showCartModal,
  showAccountModal,
  showConnexionModal,
  showAddressModal,
  showMessageModal,
  showOrderModal
} from '../../redux/actions';
import { getAddress } from './InfoBar.services';
import { PRIMARY } from '../../constants/configuration';

const INITIAL_STATE = {
  cartIcon: 'transparent',
  accountIcon: 'transparent',
  locationIcon: 'transparent',
  orderIcon: 'transparent',
  orderTypeIcon: 'transparent'
};

class InfoBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  toggleHover = iconId => {
    const { secondaryColor } = this.props;
    this.setState({
      [iconId]: secondaryColor
    });
  };

  toggleLeave = iconId => {
    this.setState({ [iconId]: 'transparent' });
  };

  render() {
    const {
      customerAddress,
      shopName,
      cart,
      orders,
      userConnected,
      showAccountModal,
      showConnexionModal,
      showAddressModal,
      isHorizontalSection,
      isUserAnonymous,
      isMobile,
      showMessageModal,
      showCartModal,
      showOrderModal,
      secondaryColor,
      localize,
      landing,
      marketplace
    } = this.props;
    const marketplaceEnabled = marketplace && marketplace.enabled;
    const disableAddress = isUserAnonymous;
    const { cartIcon, accountIcon, locationIcon, orderIcon, orderTypeIcon } = this.state;
    const classNameDisplayXLarge = 'mb-0 align-self-center d-none d-xl-block';
    const classNameDisplaySmall = 'd-block d-xl-none';
    let orderTypeLabel = '';
    if (customerAddress && !isMobile) {
      if (customerAddress.orderType === ORDER_TYPE_DELIVERY) {
        orderTypeLabel = 'Commande en Livraison';
      } else if (customerAddress.orderType === ORDER_TYPE_CLICK_AND_COLLECT) {
        orderTypeLabel = 'Commande à Emporter';
      } else if (customerAddress.orderType === ORDER_TYPE_CLICK_AND_SEAT) {
        orderTypeLabel = 'Commande sur Place';
      }
    }
    if (customerAddress && isMobile) {
      if (customerAddress.orderType === ORDER_TYPE_DELIVERY) {
        orderTypeLabel = 'En Livraison';
      } else if (customerAddress.orderType === ORDER_TYPE_CLICK_AND_COLLECT) {
        orderTypeLabel = 'A Emporter';
      } else if (customerAddress.orderType === ORDER_TYPE_CLICK_AND_SEAT) {
        orderTypeLabel = 'Sur Place';
      }
    }
    return (
      <div
        className='px-3 px-sm-5 d-block text-white'
        style={{
          backgroundColor:
            (isDarkTheme() && !isHorizontalSection) || !isDarkTheme() ? '#3f3f3f' : 'black'
        }}
      >
        <div className='row p-0 p-md-3 py-3 py-md-0 justify-content-between'>
          <div className='row align-self-center'>
            <Button
              className='row d-flex align-items-center p-0 no-outline'
              onClick={() => {
                showAddressModal(true);
              }}
              style={{
                backgroundColor: orderTypeIcon,
                pointerEvents: disableAddress || !isStringNotNull(orderTypeLabel) ? 'none' : 'auto',
                borderRadius: UNIT / 4
              }}
              onMouseEnter={() =>
                !disableAddress &&
                isStringNotNull(orderTypeLabel) &&
                this.toggleHover('orderTypeIcon')
              }
              onMouseLeave={() => !disableAddress && this.toggleLeave('orderTypeIcon')}
            >
              <div className='row col-7 text-white mx-0 mx-sm-1 align-items-center position-absolute'>
                {!isMobile ? (
                  <p
                    className='mb-0 ml-0'
                    style={{
                      visibility: `${isStringNotNull(orderTypeLabel) ? 'visible' : 'hidden'}`
                    }}
                  >
                    {isStringNotNull(orderTypeLabel) ? orderTypeLabel : 'Commande sur Place'}
                  </p>
                ) : (
                  <>
                    <span
                      className='mb-0 mr-3 no-localization addressStyle'
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      <LocationOnIcon style={{ marginTop: `${UNIT * -0.18755}` }} />
                      {getAddress()}
                    </span>
                  </>
                )}
              </div>
            </Button>
          </div>
          <div className='d-none d-lg-block'>
            <div className='row'>
              <div>
                <Button
                  className='row d-flex align-items-center p-0 text-white'
                  onClick={() => {
                    showAddressModal(true);
                  }}
                  style={{
                    backgroundColor: locationIcon,
                    pointerEvents: disableAddress ? 'none' : 'auto',
                    borderRadius: UNIT / 4
                  }}
                  onMouseEnter={() => !disableAddress && this.toggleHover('locationIcon')}
                  onMouseLeave={() => !disableAddress && this.toggleLeave('locationIcon')}
                >
                  <LocationOnIcon className='mr-2' />
                  <p className='mb-0 mr-3 no-localization'>{getAddress()}</p>
                  <p className='mb-0 mr-3'>|</p>
                  <StoreIcon className='mr-2' />
                  <p className='mb-0 pr-1'>{shopName}</p>
                </Button>
              </div>
            </div>
          </div>
          <div className='row'>
            <>
              <Button
                className='p-0 no-outline'
                disabled={_.isEmpty(cart)}
                onClick={() => {
                  if (!customerAddress) {
                    sendCloudWatchLogs('Cart without address');
                    showAddressModal(true);
                  } else {
                    showCartModal(true);
                  }
                }}
                style={{ backgroundColor: cartIcon, borderRadius: UNIT / 4 }}
                onMouseEnter={() => this.toggleHover('cartIcon')}
                onMouseLeave={() => this.toggleLeave('cartIcon')}
              >
                <div
                  className='row text-white mx-0 mx-sm-1 align-items-center'
                  style={{ position: 'relative' }}
                >
                  <ShoppingCartOutlinedIcon className='mr-0 mr-xl-1' />
                  {!_.isEmpty(cart) && (
                    <div
                      className={classNameDisplaySmall}
                      style={{
                        position: 'absolute',
                        zIndex: 1000,
                        right: -(UNIT / 2),
                        top: -10,
                        backgroundColor: secondaryColor,
                        borderRadius: 50,
                        width: 20
                      }}
                    >
                      <p className='mb-0 text-white' style={{ fontSize: UNIT * 0.875 }}>
                        {getNbTotalCart()}
                      </p>
                    </div>
                  )}
                  <p className={classNameDisplayXLarge}>{`${getNbTotalCart()} Panier`}</p>
                </div>
              </Button>
              {isMobile && !_.isEmpty(cart) && (
                <Button
                  type={PRIMARY}
                  className='fixed-cart-bottom'
                  onClick={() => {
                    if (!customerAddress) {
                      sendCloudWatchLogs('Cart without address');
                      showAddressModal(true);
                    } else {
                      showCartModal(true);
                    }
                  }}
                  style={{ borderRadius: UNIT / 4 }}
                  onMouseEnter={() => this.toggleHover('cartIcon')}
                  onMouseLeave={() => this.toggleLeave('cartIcon')}
                >
                  <ShoppingCartOutlinedIcon className='mr-xl-1 float-left leftSpace' />
                  Voir panier
                  <div
                    className={classNameDisplaySmall}
                    style={{
                      position: 'absolute',
                      zIndex: 1000,
                      right: '13%',
                      top: 8,
                      backgroundColor: 'white',
                      borderRadius: 50,
                      width: 20
                    }}
                  >
                    <p
                      className='mb-0'
                      style={{
                        fontSize: UNIT * 0.875,
                        color: secondaryColor
                      }}
                    >
                      {getNbTotalCart()}
                    </p>
                  </div>
                </Button>
              )}
            </>
            {isTerminal() && (
              <>
                <div className='mr-1' />
                <Button
                  className='p-0 no-outline'
                  onClick={() => {
                    resetTerminalSession();
                  }}
                  style={{ backgroundColor: accountIcon, borderRadius: UNIT / 4 }}
                  onMouseEnter={() => this.toggleHover('accountIcon')}
                  onMouseLeave={() => this.toggleLeave('accountIcon')}
                >
                  <div className='row text-center text-white mx-0 mx-sm-1  align-items-center'>
                    <ExitToAppIcon className='mr-0 mr-xl-1' />
                    <p className='mb-0 align-self-center'>Abandonner</p>
                  </div>
                </Button>
              </>
            )}
            {!isTerminal() && (!marketplaceEnabled || isQrCode()) && (
              <>
                <div className='mr-1' />
                <Button
                  className='p-0 no-outline'
                  disabled={_.isEmpty(orders)}
                  onClick={() => {
                    showOrderModal(true);
                  }}
                  onMouseEnter={() => this.toggleHover('orderIcon')}
                  onMouseLeave={() => this.toggleLeave('orderIcon')}
                  style={{ backgroundColor: orderIcon, borderRadius: UNIT / 4 }}
                >
                  <div
                    className='row text-white mx-0 mx-sm-1 align-items-center'
                    style={{ position: 'relative' }}
                  >
                    <LocalMallIcon className='mr-0 mr-xl-1' />
                    {!_.isEmpty(orders) && (
                      <div
                        className={classNameDisplaySmall}
                        style={{
                          position: 'absolute',
                          zIndex: 1000,
                          right: -(UNIT / 2),
                          top: -10,
                          backgroundColor: secondaryColor,
                          borderRadius: 50,
                          width: 20
                        }}
                      >
                        <p className='mb-0 text-white' style={{ fontSize: UNIT * 0.875 }}>
                          {_.size(orders)}
                        </p>
                      </div>
                    )}
                    <p className={classNameDisplayXLarge}>
                      {_.size(orders) <= 1
                        ? `${_.size(orders)} Commande`
                        : `${_.size(orders)} Commandes`}
                    </p>
                  </div>
                </Button>
              </>
            )}
            {!disableAddress && (
              <>
                <div className='mr-1' />
                <Button
                  className='p-0 no-outline'
                  onClick={() =>
                    userConnected ? showAccountModal(true) : showConnexionModal(true)
                  }
                  onMouseEnter={() => this.toggleHover('accountIcon')}
                  onMouseLeave={() => this.toggleLeave('accountIcon')}
                  style={{ backgroundColor: accountIcon, borderRadius: UNIT / 4 }}
                >
                  <div className='row text-center text-white mx-0 mx-sm-1 align-items-center'>
                    <PersonOutlineOutlinedIcon className='mr-0 mr-xl-1' />
                    <p className={classNameDisplayXLarge}>Profil</p>
                  </div>
                </Button>
                {!isMobile && (
                  <Button
                    className='p-0 no-outline ml-2 d-block d-lg-none align-self-center'
                    onClick={() => showAddressModal(true)}
                    onMouseEnter={() => this.toggleHover('locationIcon')}
                    onMouseLeave={() => this.toggleLeave('locationIcon')}
                    style={{ backgroundColor: locationIcon, borderRadius: UNIT / 4 }}
                  >
                    <div className='row text-center text-white mx-0 mx-sm-1 align-items-center'>
                      <LocationOnIcon className='mr-0 mr-xl-1' />
                      <p className={`pr-1 ${classNameDisplayXLarge}`}>Addresse</p>
                    </div>
                  </Button>
                )}
              </>
            )}
            {(!landing.enabled || isUserAnonymous) && localize.enabled && (
              <div className='ml-2 align-self-center'>
                <FlagsSelect
                  className='mx-0 mx-sm-1'
                  showSelectedLabel={false}
                  alignOptions='left'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  userReducer,
  shopReducer,
  cartReducer,
  orderReducer,
  configurationReducer,
  componentReducer
}) => {
  const { isHorizontalSection, isMobile } = componentReducer;
  const { customerAddress, userConnected, isUserAnonymous } = userReducer;
  const { secondaryColor, localize, landing, marketplace } = configurationReducer;
  const { addressShop, shopName } = shopReducer;
  const { orders } = orderReducer;
  return {
    customerAddress,
    addressShop,
    shopName,
    cart: cartReducer,
    userConnected,
    orders,
    secondaryColor,
    isHorizontalSection,
    isUserAnonymous,
    isMobile,
    localize,
    landing,
    marketplace
  };
};

export default connect(mapStateToProps, {
  showCartModal,
  showConnexionModal,
  showAccountModal,
  showAddressModal,
  showMessageModal,
  showOrderModal
})(InfoBar);
