import _ from 'lodash';
import { Cookies } from 'react-cookie';
import {
  SELECT_SECTION,
  UPDATE_CLOSEST_SHOPS,
  UPDATE_SHOP,
  UPDATE_SHOPS,
  UPDATE_SHOP_IS_LOADED,
  IS_SHOP_UNAVAILABLE
} from './types';
import { ORDER_TYPE_DELIVERY } from '../../constants';
import {
  sendCloudWatchLogs,
  getClosestShops,
  getShopAndZoneData,
  sendCloudWatchAlert,
  getFirstShop,
  isStringNotNull,
  getShopIdMarketplaceLink,
  checkIfShopAvailable,
  updateAddressAndShop,
  removeAddress
} from '../../utils';
import {
  removeDataFromTable,
  listenShopData,
  getDataFromTable,
  getDataFromTableWithLimit,
  getFirebaseCustomerId
} from '../../api/firebase';
import { updateAddress, addAddress } from './userAction';
import { showMessageModal } from './componentAction';

export const updateShops = shops => {
  return {
    type: UPDATE_SHOPS,
    payload: shops
  };
};

export const updateShop = address => async (dispatch, getState) => {
  try {
    const shopIdFromLink = getShopIdMarketplaceLink();
    const { shopId } = getState().shopReducer;
    const { addressIsLoaded } = getState().userReducer;
    let shopIdToGet = address && address.shopId;
    if (shopIdFromLink) {
      shopIdToGet = shopIdFromLink;
    }

    if (shopIdToGet !== shopId) {
      dispatch({ type: UPDATE_SHOP_IS_LOADED, payload: false });
    }
    // Getting and update shop
    let shop = null;
    sendCloudWatchLogs(`Updating shop with address ${JSON.stringify(address)}`);
    if (shopIdToGet) {
      shop = await getDataFromTable(`/shops/${shopIdToGet}`);
      shop = getShopAndZoneData(shopIdToGet, shop, address);
      if (!shop) {
        removeAddress(address);
      }
    }
    if (!shop) {
      shop = await getFirstShop();
    }
    sendCloudWatchLogs(`Updating shop to ${shop.shopId}`);
    dispatch({ type: UPDATE_SHOP, payload: shop });
    listenShopData(shopId, shop.shopId, address, getShopAndZoneData);

    if (shopIdToGet !== shopId) {
      const { sections } = shop;
      const section = _.find(sections, section => {
        if (section) {
          return true;
        }
      });
      if (section) {
        dispatch({ type: SELECT_SECTION, payload: section.key });
      }
    }

    if (addressIsLoaded) {
      dispatch({ type: UPDATE_SHOP_IS_LOADED, payload: true });
    }

    if (address && shopIdFromLink) {
      getClosestShops(address, address.orderType).then(closestShops => {
        checkIfShopAvailable(address, shopIdFromLink, closestShops);
      });
    }
    if (!shopIdFromLink && address && address.orderType === ORDER_TYPE_DELIVERY) {
      // const closestShops = await getClosestShops(address, address.orderType);
      // if (closestShops && closestShops.length > 0) {
      //   const { shopId } = closestShops[0];
      //   if (shopIdToGet !== shopId) {
      //     // check if there's a closer shop than the current one
      //     updateAddressAndShop(shopId, address, ORDER_TYPE_DELIVERY);
      //   } else {
      // check if delivery price per km changed
      const { delivery = {} } = getState().configurationReducer;
      const { userServicePrice: deliveryPricing } = delivery;
      const { configuration = {} } = shop;
      const { delivery: deliveryShop = {} } = configuration;
      const { userServicePrice: deliveryPricingShop } = deliveryShop;
      if (
        (deliveryPricingShop && !_.isEqual(deliveryPricingShop, address.pricing)) ||
        (!deliveryPricingShop && deliveryPricing && !_.isEqual(deliveryPricing, address.pricing)) ||
        (address.pricing && !deliveryPricingShop && !deliveryPricing)
      ) {
        const newAddress = _.clone(address);
        if (address.pricing && !deliveryPricingShop && !deliveryPricing) {
          delete newAddress.deliveryPrice;
          delete newAddress.pricing;
        }
        updateAddressAndShop(shopIdToGet, newAddress, ORDER_TYPE_DELIVERY);
      }
      //   }
      // } else {
      //   removeAddress(address);
      // }
    }
  } catch (error) {
    sendCloudWatchAlert(`Error updating shop ${error}`);
  }
};

export const updateShopIsLoaded = shopIsLoaded => {
  return {
    type: UPDATE_SHOP_IS_LOADED,
    payload: shopIsLoaded
  };
};

export const updateClosestShops = shops => {
  return {
    type: UPDATE_CLOSEST_SHOPS,
    payload: shops
  };
};

export const isShopUnavailable = shopUnavailable => {
  return {
    type: IS_SHOP_UNAVAILABLE,
    payload: shopUnavailable
  };
};
