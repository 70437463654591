import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import styles from './ProductModal.styles';
import {
  addToCart,
  showCartModal,
  showMessageModal
} from '../../../redux/actions';
import Category from '../../Category';
import Spinner from '../../Spinner';
import Button from '../../Button';
import { addToCartFirebase, sendGoogleAnalytics } from '../../../api';
import {
  updateSubCategories,
  getMinimumStockRemaining,
  isNextCategoryDisabled,
  handleNextOption,
  handlePreviousOption,
  getProductData
} from './ProductModal.services';
import CloseButton from '../../CloseButton';
import TextInputCounter from '../../TextInputCounter';
import ModalBorder from '../../ModalBorder';
import {
  COLOR_MID_GRAY,
  UNIT,
  COLOR_LIGHT_GRAY,
  MODAL_SCROLL_OFFSET,
  PRIMARY,
  SECONDARY,
  DARK_COLOR
} from '../../../constants/configuration';

import {
  roundNumber,
  isChatbot,
  sendCloudWatchAlert,
  getProductId,
  displayPriceFormat,
  isStringNotNull,
  isTerminal,
  getNbInCart,
  convertHoursToDays,
  getProductUnits,
  isTerminalVertical,
  getCounterOptionKey,
  getMinimumPriceFreeProduct,
  isDarkTheme
} from '../../../utils';

const { modalOptionContainer } = styles(COLOR_MID_GRAY);
class ProductModal extends React.Component {
  constructor(props) {
    super(props);
    const { product, categories, shopCategories } = props;
    const { unit, productCategories, mandatoryCategories } = getProductData(
      shopCategories,
      product,
      categories
    );
    this.INITIAL_STATE = {
      nb: unit ? 0 : 1,
      items: {},
      instruction: '',
      productCategories,
      mandatoryCategories,
      errorMessage: '',
      loading: false,
      productAdded: false,
      showOptionScrollButton: false,
      unit,
      stepOptionIndex: 0,
      itemsCounter: {},
      modalHeight: null
    };
    this.state = _.cloneDeep(this.INITIAL_STATE);
  }

  componentWillReceiveProps(nextProps) {
    const { product } = this.props;
    if (!_.isEqual(product, nextProps.product)) {
      this.resetComponent(nextProps, true);
    }
  }

  componentDidUpdate() {
    const { modalHeight } = this.state;
    if (
      !modalHeight &&
      document.getElementById('product-modal-body') &&
      document.getElementById('product-modal-border') &&
      document.getElementById('product-modal-body').offsetHeight >
        document.getElementById('product-modal-border').offsetHeight +
          MODAL_SCROLL_OFFSET
    ) {
      this.setState({
        showOptionScrollButton: true,
        modalHeight: document.getElementById('product-modal-body').offsetHeight
      });
    }
  }

  renderCategories = () => {
    const {
      productCategories,
      items,
      stepOptionIndex,
      unit,
      nb,
      loading,
      itemsCounter
    } = this.state;
    const {
      shopCategories = {},
      title,
      product,
      secondaryColor,
      disableProductInstruction
    } = this.props;
    const { stockRemaining, name: stockName } = getMinimumStockRemaining(this);
    const units = getProductUnits(product.unit);
    const categoriesLength = productCategories.length + 1;
    if (
      productCategories &&
      productCategories[stepOptionIndex] &&
      stepOptionIndex < productCategories.length
    ) {
      const categoryId = productCategories[stepOptionIndex];
      const categoryKey = categoryId.split('@')[0];
      const category = shopCategories[categoryKey];
      if (category) {
        return (
          <div
            className='row col-12 mt-4 hide-scroll'
            key={stepOptionIndex}
            style={
              isTerminalVertical()
                ? { height: '700px', overflow: 'auto', ...modalOptionContainer }
                : modalOptionContainer
            }
          >
            <Category
              productTitle={title}
              product={product}
              nbProduct={nb}
              category={category}
              key={categoryId}
              categoryId={categoryId}
              itemsCounter={itemsCounter}
              productItems={items}
              categoryKey={categoryKey}
              index={stepOptionIndex}
              addUniqueItem={(index, categoryId, categoryKey, itemKey, item) =>
                this.addUniqueItem(
                  index,
                  categoryId,
                  categoryKey,
                  itemKey,
                  item
                )
              }
              addItem={(index, categoryId, categoryKey, itemKey, item, count) =>
                this.addItem(
                  index,
                  categoryId,
                  categoryKey,
                  itemKey,
                  item,
                  count
                )
              }
              removeItem={(index, categoryId, itemKey, count) =>
                this.removeItem(index, categoryId, itemKey, count)
              }
            />
          </div>
        );
      }
    }
    if (productCategories.length === stepOptionIndex) {
      return (
        <div
          className={`row col-12 mt-4 ${categoriesLength > 1 && 'p-4'}`}
          key={stepOptionIndex}
          style={
            productCategories.length === 0
              ? {}
              : isTerminalVertical()
              ? { height: '700px', overflow: 'auto', ...modalOptionContainer }
              : isDarkTheme()
              ? {}
              : modalOptionContainer
          }
        >
          <div
            className={`row col d-flex justify-content-center ${categoriesLength >
              1 &&
              isTerminalVertical() &&
              'pt-4'}`}
            style={{ display: 'flex' }}
          >
            {productCategories.length === 0 ? (
              <div />
            ) : (
              <div
                className={`col-12 align-self-center ${!isTerminalVertical() &&
                  'header-container-option'}`}
                style={isTerminalVertical() ? { fontSize: UNIT * 2.9 } : {}}
              >
                Combien en voulez-vous ?
              </div>
            )}

            {!unit ? (
              <div
                className={`${isTerminalVertical() &&
                  'd-flex align-self-start justify-content-center'} ${categoriesLength >
                  1 && 'pt-4'}
                  }`}
                style={
                  isTerminalVertical()
                    ? {}
                    : { display: 'flex', alignItems: 'center' }
                }
              >
                <Button
                  className={`no-outline ${
                    nb > 0 ? 'label' : 'disabled-label'
                  }   ${isTerminalVertical() ? 'py-0' : 'p-0'}`}
                  onClick={this.removeOne}
                >
                  <i
                    className='fas fa-minus-circle mx-2'
                    style={{
                      fontSize: isTerminalVertical() ? 80 : 40
                    }}
                  />
                </Button>
                <p
                  className={`${
                    isTerminalVertical()
                      ? 'd-flex align-items-center justify-content-center mx-4 lable'
                      : 'mx-2 label'
                  }`}
                  style={
                    isTerminalVertical()
                      ? {
                          fontSize: UNIT * 3.75
                        }
                      : { margin: '0px', fontSize: UNIT * 1.5 }
                  }
                >
                  {nb}
                </p>
                <Button
                  className={`no-outline ${
                    isTerminalVertical() ? 'py-0' : 'p-0'
                  }`}
                  onClick={() => {
                    this.addOne();
                  }}
                  disabled={stockRemaining <= 0}
                >
                  <i
                    className='fas fa-plus-circle mx-2'
                    style={{
                      color:
                        stockRemaining <= 0 ? COLOR_LIGHT_GRAY : secondaryColor,
                      fontSize: isTerminalVertical() ? 80 : 40
                    }}
                  />
                </Button>
              </div>
            ) : (
              <form
                className={`needs-validation ${!isTerminalVertical() &&
                  'pt-4'}`}
              >
                <div
                  className='form-group'
                  style={{
                    display: 'flex',
                    width: 'fit-content',
                    margin: 'auto'
                  }}
                >
                  <div className='input-group mr-3'>
                    <input
                      type='number'
                      className='form-control'
                      style={{ width: '70px' }}
                      value={nb}
                      onChange={(e) => this.updateWeight(e)}
                      min={0}
                    />
                    <div className='input-group-append'>
                      {units && units.length > 1 && (
                        <>
                          <div
                            className='btn-group btn-group-toggle'
                            data-toggle='buttons'
                          >
                            {units.map((u, index) => {
                              return (
                                <label
                                  key={index}
                                  className={`btn btn-light border ${
                                    u.name === unit.name ? 'active' : ''
                                  }`}
                                  style={{
                                    borderRadius:
                                      index === units.length - 1
                                        ? '0px 4px 4px 0px'
                                        : '0px'
                                  }}
                                  onClick={() => this.setState({ unit: u })}
                                >
                                  <input
                                    type='radio'
                                    name='options'
                                    id={index}
                                    autoComplete='off'
                                    checked={u.name === unit.name}
                                  />
                                  {u.name}
                                </label>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {units && units.length === 1 && (
                        <span
                          className='input-group-text'
                          style={{ fontSize: UNIT, margin: 'auto' }}
                        >
                          {units[0].name}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}
            <div className='col-12'>
              {stockRemaining > 0 && stockRemaining <= 5 && !loading && (
                <p
                  className='text-warning pt-4 font-italic label'
                  style={isTerminalVertical() ? { fontSize: UNIT * 1.5 } : {}}
                >
                  - Plus que {stockRemaining}{' '}
                  <span className='no-localization'>
                    {unit ? unit.name : ''}
                  </span>{' '}
                  {stockName} en stock -
                </p>
              )}
              {stockRemaining <= 0 && !loading && (
                <p
                  className='text-danger pt-4 font-italic mb-0 label'
                  style={isTerminalVertical() ? { fontSize: UNIT * 1.5 } : {}}
                >
                  - Plus de {stockName} en stock -
                </p>
              )}
            </div>
            {categoriesLength > 1 &&
              stepOptionIndex + 1 === categoriesLength &&
              this.renderAddToCartButton()}
            {!isChatbot() && (
              <div className='row col'>
                {!isTerminal() &&
                  !disableProductInstruction &&
                  this.renderSpecialInstructions()}
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  renderOptionScrollButton = () => {
    const { secondaryColor } = this.props;
    const { showOptionScrollButton } = this.state;
    if (showOptionScrollButton) {
      return (
        <i
          className='fas fa-arrow-circle-down shadow-lg'
          onClick={() => {
            document
              .getElementById('optionContainer')
              .scrollIntoView({ top: 0, behavior: 'smooth' });
            this.setState({ showOptionScrollButton: false });
          }}
          style={{
            fontSize: '30px',
            position: 'absolute',
            zIndex: 10,
            top: '-50px',
            color: secondaryColor,
            backgroundColor: COLOR_MID_GRAY,
            borderRadius: UNIT,
            cursor: 'pointer'
          }}
        />
      );
    }
  };

  renderCategorySteps = () => {
    const { stepOptionIndex, productCategories } = this.state;
    const { secondaryColor } = this.props;
    const categoriesLength = productCategories.length + 1;
    if (categoriesLength > 1) {
      return (
        <div className='col-12 d-flex justify-content-center'>
          <Button
            className='no-outline py-0'
            disabled={stepOptionIndex === 0}
            onClick={() => {
              handlePreviousOption(this);
              this.setState({ showOptionScrollButton: false });
            }}
          >
            <a
              href='#optionContainer'
              style={{
                pointerEvents: isTerminalVertical() && 'none'
              }}
            >
              <i
                className={`fas fa-chevron-left mx-2 ${
                  isTerminalVertical() ? 'fa-3x' : 'fa-2x'
                } `}
                style={{
                  color:
                    stepOptionIndex === 0
                      ? isDarkTheme()
                        ? DARK_COLOR
                        : 'white'
                      : isDarkTheme()
                      ? 'white'
                      : 'black'
                }}
              />
            </a>
          </Button>
          <p
            className='label'
            style={{
              margin: '0px',
              fontSize: isTerminalVertical() ? UNIT * 1.875 : UNIT * 1.375
            }}
          >
            {`${stepOptionIndex + 1} / ${categoriesLength}`}
          </p>
          <Button
            className='no-outline py-0'
            disabled={isNextCategoryDisabled(this)}
            onClick={() => {
              handleNextOption(this);
              this.setState({ showOptionScrollButton: false });
            }}
          >
            <a
              href='#optionContainer'
              style={{
                pointerEvents:
                  (isNextCategoryDisabled(this) || isTerminalVertical()) &&
                  'none'
              }}
            >
              <i
                className={`fas fa-chevron-right mx-2 ${
                  isTerminalVertical() ? 'fa-3x' : 'fa-2x'
                }`}
                style={{
                  color: isNextCategoryDisabled(this)
                    ? isNextCategoryDisabled(this) === 'lastItem'
                      ? isDarkTheme()
                        ? DARK_COLOR
                        : 'white'
                      : COLOR_LIGHT_GRAY
                    : secondaryColor
                }}
              />
            </a>
          </Button>
          {!isTerminalVertical() && this.renderOptionScrollButton()}
        </div>
      );
    }
  };

  addItem(index, categoryId, categoryKey, itemKey, item, count) {
    const { items, itemsCounter } = this.state;
    const { shopCategories } = this.props;
    // Increment counter
    if (count) {
      const optionKey = getCounterOptionKey(categoryId, itemKey);
      let counter = itemsCounter[optionKey];
      counter = counter ? counter + 1 : 1;
      itemsCounter[optionKey] = counter;
      itemKey = `${itemKey}@${counter}`;
      this.setState({ itemsCounter });
    }

    // Add item to productItems
    if (!items[index]) {
      const category = shopCategories[categoryKey];
      items[index] = {
        categoryKey,
        categoryId,
        categoryName: category.name,
        categoryPosition: index,
        categoryType: category.type,
        options: {}
      };
    }
    item.label = item.name;
    item.value = item.position;

    items[index].options[itemKey] = item;

    this.setState({ items });
  }

  async addUniqueItem(index, categoryId, categoryKey, itemKey, item) {
    const { shopCategories } = this.props;
    const newProductItems = await updateSubCategories(this, categoryId, item);
    const category = shopCategories[categoryKey];
    item.label = item.name;
    item.value = item.position;
    newProductItems[index] = {
      categoryKey,
      categoryId,
      categoryName: category.name,
      categoryPosition: index,
      categoryType: category.type,
      options: { [itemKey]: item }
    };
    this.setState({ items: newProductItems });
  }

  removeItem(index, categoryId, itemKey, count) {
    let { items } = this.state;
    const { itemsCounter } = this.state;
    if (count) {
      const optionKey = getCounterOptionKey(categoryId, itemKey);
      const counter = itemsCounter[optionKey];
      itemsCounter[optionKey] = counter - 1;
      itemKey = `${itemKey}@${counter}`;
      this.setState({ itemsCounter });
    }
    // Remove item from itemsCounter
    items[index].options = _.omit(items[index].options, itemKey);

    if (_.isEmpty(items[index].options)) {
      items = _.omit(items, index);
    }
    this.setState({ items });
  }

  renderSpecialInstructions = () => {
    const { instruction, productCategories } = this.state;

    return (
      <div className='col-12 pt-4 dark-modal'>
        <div
          className={`pb-2 dark-modal ${
            productCategories.length === 0 ? 'bg-white' : 'bg-light'
          }`}
          style={{ fontSize: UNIT * 0.875 }}
        >
          Instructions spéciales :
        </div>
        <div className='form-group mb-0'>
          <TextInputCounter
            className='form-control text-center font-italic'
            placeholder='Entrez vos instructions'
            style={{
              margin: 'auto',
              width: '90%',
              resize: 'none',
              fontSize: UNIT * 0.625,
              borderRadius: UNIT / 2,
              backgroundColor: isDarkTheme() && '#202020'
            }}
            counterStyle={{
              position: 'absolute',
              right: '25px',
              bottom: '15px',
              fontSize: UNIT * 0.75
            }}
            value={instruction}
            onChange={(e) => {
              this.setState({ instruction: e.target.value });
            }}
          />
        </div>
      </div>
    );
  };

  updateWeight = (e) => {
    const nb = e.target.value;
    if (nb >= 0 || !isStringNotNull(nb)) {
      this.setState({ nb: e.target.value });
    }
  };

  addOne = () => {
    const { nb } = this.state;
    const result = getMinimumStockRemaining(this);
    if (_.isEmpty(result) || result.stockRemaining > 0) {
      const nbIncremented = nb + 1;
      this.setState({ nb: nbIncremented });
    }
  };

  removeOne = (e) => {
    const { nb } = this.state;
    const nbDecremented = nb === 0 ? 0 : nb - 1;
    this.setState({ nb: nbDecremented });
  };

  calculatePrice = () => {
    const { items } = this.state;
    const { price } = this.props;
    let itemsPrice = 0;
    _.map(items, (item) => {
      if (item) {
        const itemOptions = item.options;
        _.map(itemOptions, (option) => {
          itemsPrice += parseFloat(option.price, 10);
        });
      }
    });
    const productPrice = parseFloat(price, 10);
    return roundNumber(productPrice + itemsPrice);
  };

  renderFinalPrice = () => {
    const { nb, unit } = this.state;
    const productPrice = this.calculatePrice();
    let price = productPrice * nb;
    if (unit) {
      price /= unit.conversion;
    }
    return price;
  };

  addToCartClick = async () => {
    const { product } = this.props;
    const {
      items,
      mandatoryCategories,
      nb,
      productCategories,
      instruction,
      unit
    } = this.state;
    const { name, price, key, position } = product;
    try {
      const {
        shopCategories,
        addToCart,
        sectionName,
        userConnected,
        sectionKey,
        productKey,
        customerId,
        isUserAnonymous,
        showMessageModal,
        shopId
      } = this.props;

      this.setState({ errorMessage: '', loading: true });

      for (let i = 0; i < mandatoryCategories.length; i += 1) {
        const key = mandatoryCategories[i];
        if (!items[key]) {
          const categoryId = productCategories[key];
          const categoryKey = categoryId.split('@')[0];
          const category = shopCategories[categoryKey];
          if (category) {
            const errorMessage = `Vous devez sélectionner l'option : ${category.name}`;
            showMessageModal(errorMessage);
            this.setState({ loading: false });
          }
          return;
        }
      }
      const productId = getProductId(productKey, items);
      let cartNb = getNbInCart(productId) + Number(nb);
      let productPrice = this.calculatePrice() * cartNb;
      if (unit) {
        cartNb = Number(nb);
        productPrice = (this.calculatePrice() * cartNb) / unit.conversion;
      }
      const newProduct = { ...product };
      newProduct.items = items;
      newProduct.nb = cartNb;
      newProduct.unit = unit ? unit.name : null;
      newProduct.sectionName = sectionName;
      newProduct.sectionKey = sectionKey;
      newProduct.productKey = productKey;
      newProduct.unitPrice = parseFloat(product.price);
      newProduct.price = productPrice;
      newProduct.shopId = shopId;
      if (!newProduct.boughtPrice) {
        newProduct.boughtPrice = 0;
      }
      if (instruction) {
        newProduct.instruction = instruction;
      }
      if ((userConnected || isUserAnonymous) && !isTerminal()) {
        await addToCartFirebase(customerId, productId, newProduct);
      } else {
        addToCart(productId, newProduct);
      }
      setTimeout(() => {
        this.resetComponent(this.props);
      }, 1000);
      sendGoogleAnalytics('add_to_cart', {
        currency: 'EUR',
        items: [
          {
            id: key,
            name,
            price,
            position,
            category: sectionName,
            quantity: nb
          }
        ],
        value: productPrice
      });
    } catch (error) {
      sendCloudWatchAlert(
        `Error adding product ${key} with items ${JSON.stringify(
          items
        )} to cart ${error}`
      );
    }
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.state;
    if (errorMessage !== '') {
      return (
        <div className='alert alert-danger' role='alert'>
          {errorMessage}
        </div>
      );
    }
  };

  resetComponent(props, notHide) {
    const { onHideModal, shopCategories, product, categories } = props;
    if (!notHide) {
      onHideModal();
    }
    const { unit, productCategories, mandatoryCategories } = getProductData(
      shopCategories,
      product,
      categories
    );
    setTimeout(() => {
      this.setState({
        ...this.INITIAL_STATE,
        items: {},
        itemsCounter: {},
        productCategories,
        mandatoryCategories,
        unit,
        nb: unit ? 0 : 1
      });
    }, 200);
  }

  renderAddToCartButton = () => {
    const { secondaryColor, isMobile, closed, shopUnavailable } = this.props;
    const { loading, nb, productCategories, stepOptionIndex } = this.state;
    const categoriesLength = productCategories.length + 1;
    let disabled = false;
    const { stockRemaining } = getMinimumStockRemaining(this);
    const { addToCartVerticalTerminalStyle, addToCartCommandStyle } = styles(
      secondaryColor
    );
    const isShopClosed = closed || shopUnavailable;
    if (isShopClosed) {
      disabled = true;
    }
    if (
      nb === '' ||
      nb <= 0 ||
      stockRemaining < 0 ||
      stepOptionIndex < productCategories.length ||
      loading
    ) {
      disabled = true;
    }
    return (
      <>
        <div
          className={
            categoriesLength > 1
              ? `col-12 d-flex justify-content-center ${
                  isTerminalVertical() ? 'align-self-start' : 'pt-4'
                }`
              : 'col-12 d-flex justify-content-center'
          }
        >
          <Button
            disabled={disabled}
            style={
              isTerminalVertical()
                ? addToCartVerticalTerminalStyle
                : addToCartCommandStyle
            }
            onClick={() => {
              this.addToCartClick();
            }}
          >
            {loading && (
              <div>
                <Spinner
                  color='white'
                  size={`${!isTerminalVertical() && 'sm'}`}
                />
                <span className='sr-only'>Loading...</span>
              </div>
            )}
            {!loading && (
              <div>
                <span>
                  {isShopClosed
                    ? 'Indisponible'
                    : isMobile
                    ? 'Ajouter '
                    : 'Ajouter au panier '}
                </span>
                {!isShopClosed && (
                  <span> {displayPriceFormat(this.renderFinalPrice())} </span>
                )}
              </div>
            )}
          </Button>
        </div>
      </>
    );
  };

  renderProductPrice() {
    const { product } = this.props;
    const { unit } = product;
    const { price } = product;
    const minimumPrice = getMinimumPriceFreeProduct(product);

    if (price > 0 || !minimumPrice) {
      return (
        <h6
          className='text-product text-muted'
          style={isTerminalVertical() ? { fontSize: UNIT } : {}}
        >
          {displayPriceFormat(price)}
          {unit ? `/${unit}` : ''}
        </h6>
      );
    }
    return (
      <h6
        className='text-product text-muted font-italic'
        style={isTerminalVertical() ? { fontSize: UNIT } : {}}
      >
        À partir de {displayPriceFormat(minimumPrice)}
        {unit ? `/${unit}` : ''}
      </h6>
    );
  }

  renderModalBody = () => {
    const { image, title, description, product } = this.props;
    const { productAdded } = this.state;
    const { preparationTime } = product;

    if (productAdded) {
      return <p className='label'>{title} a bien été ajouté au panier.</p>;
    }
    return (
      <div className='text-center'>
        {isStringNotNull(image) && (
          <div
            className='mb-3 p-0'
            style={isTerminalVertical() ? { width: '80%', margin: 'auto' } : {}}
          >
            <img
              src={image}
              alt={title}
              className='img-fluid col-12 image-modal-product'
              style={
                isTerminalVertical()
                  ? { borderRadius: UNIT, width: '50%' }
                  : { borderRadius: UNIT }
              }
            />
          </div>
        )}
        {!isStringNotNull(image) && <div className='mt-4' />}
        <div className='px-sm-4 my-3'>
          <h2
            className={`text-break ${isTerminalVertical() && 'py-2'}`}
            id='product-modal-title'
          >
            {title}
          </h2>
          {isStringNotNull(description) && (
            <p className='text-secondary text-break line-break mb-1 label'>
              {description}
            </p>
          )}
          <div className='col-12'>
            {preparationTime > 0 && (
              <p className='text-warning font-weight-bold font-italic pt-3 label'>
                {`Pour votre information, ce produit nécessite au moins ${
                  convertHoursToDays(preparationTime) > 0
                    ? `${convertHoursToDays(preparationTime)} jour(s)`
                    : `${preparationTime} heure(s)`
                } de préparation`}
              </p>
            )}
          </div>
          {!isChatbot() && (
            <div>
              <div className='row' id='optionContainer'>
                {this.renderCategories()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderModalFooter = () => {
    const { showCartModal } = this.props;
    const { productAdded, productCategories } = this.state;
    const categoriesLength = productCategories.length + 1;
    if (productAdded) {
      return (
        <>
          <Button
            type={PRIMARY}
            onClick={() => this.resetComponent(this.props)}
          >
            Continuer mes achats
          </Button>
          <Button
            type={SECONDARY}
            onClick={() => {
              this.resetComponent(this.props);
              showCartModal(true);
            }}
          >
            Accéder au panier
          </Button>
        </>
      );
    }
    return (
      <>
        {categoriesLength > 1
          ? this.renderCategorySteps()
          : this.renderAddToCartButton()}
      </>
    );
  };

  render() {
    const { showModal, secondaryColor } = this.props;
    return (
      <>
        <Modal
          show={showModal}
          onHide={() => this.resetComponent(this.props)}
          aria-labelledby='contained-modal-title-vcenter'
          scrollable
          centered
          dialogClassName={`${isTerminalVertical() &&
            'modal-terminal-vertical'}`}
        >
          <ModalBorder
            className={`hide-scroll ${!isTerminalVertical() &&
              'modal-size-fixed'}`}
            style={
              isTerminalVertical()
                ? {
                    overflow: 'auto',
                    maxHeight: '110vh'
                  }
                : {}
            }
            color={`${
              isTerminalVertical() ? secondaryColor : COLOR_LIGHT_GRAY
            }`}
            id='product-modal-border'
            onScroll={() => {
              if (
                document.getElementById('product-modal-border').scrollTop <
                  document.getElementById('product-modal-title').offsetHeight &&
                document.getElementById('product-modal-body').offsetHeight >
                  document.getElementById('product-modal-border').offsetHeight +
                    MODAL_SCROLL_OFFSET
              ) {
                this.setState({ showOptionScrollButton: true });
              } else {
                this.setState({ showOptionScrollButton: false });
              }
            }}
          >
            <div>
              <CloseButton
                closeStyle={{ right: UNIT * 0.5 }}
                onClick={() => {
                  this.resetComponent(this.props);
                }}
              />
              <Modal.Body className='p-3  dark-modal' id='product-modal-body'>
                {this.renderModalBody()}
              </Modal.Body>
              {!isChatbot() && (
                <Modal.Footer
                  className={` footer-color modal-sticky-footer  ${
                    isTerminalVertical() ? 'row' : 'px-3'
                  }`}
                  style={{
                    height: isTerminalVertical() ? '100px' : '60px'
                  }}
                >
                  {this.renderModalFooter()}
                </Modal.Footer>
              )}
            </div>
          </ModalBorder>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({
  shopReducer,
  configurationReducer,
  userReducer,
  cartReducer,
  componentReducer
}) => {
  const { categories, shopId, closed, shopUnavailable } = shopReducer;
  const { isMobile } = componentReducer;
  const { secondaryColor, disableProductInstruction } = configurationReducer;
  const { customerAddress, userConnected, user, isUserAnonymous } = userReducer;
  return {
    shopCategories: categories,
    secondaryColor,
    customerAddress,
    userConnected,
    cartReducer,
    user,
    customerId: user.customerId,
    isUserAnonymous,
    isMobile,
    shopId,
    disableProductInstruction,
    closed,
    shopUnavailable
  };
};

export default connect(mapStateToProps, {
  addToCart,
  showCartModal,
  showMessageModal
})(ProductModal);
