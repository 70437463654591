import moment from 'moment';
import _ from 'lodash';
import {
  sendCloudWatchAlert,
  getWebViewCustomerId,
  getWebViewPageId,
  isSameDay,
  sortSlots,
  checkOrdersLimit
} from '../../utils';
import { ORDER_TYPE_DELIVERY, API_SEND_ORDER_MESSENGER } from '../../constants';
import { showMessageModal, showCartModal, updateOrderTime } from '../../redux/actions';
import store from '../../redux/store';
import { sendPostRequest, closeWebView, getDataFromTable } from '../../api';

export const getMaximumPreparationTime = cart => {
  let maxPreparationTime = 0;
  _.map(cart, product => {
    const { preparationTime } = product;
    if (maxPreparationTime < parseInt(preparationTime, 10)) {
      maxPreparationTime = parseInt(preparationTime, 10);
    }
  });
  return maxPreparationTime;
};

export const getFirstDay = (component, maximumPreparationTime) => {
  const { customerAddress } = store.getState().userReducer;
  const { orderType } = customerAddress;
  let now = moment();
  if (maximumPreparationTime) {
    now = moment().add(maximumPreparationTime, 'h');
  }
  let orderTimes = getOrderTimes(now, orderType, maximumPreparationTime);
  let dayToAdd = 1;
  while (orderTimes.length === 0 && dayToAdd < 7) {
    now = moment().add(dayToAdd, 'd');
    orderTimes = getOrderTimes(now, orderType, maximumPreparationTime);
    dayToAdd++;
  }
  if (orderTimes.length > 0) {
    store.dispatch(updateOrderTime(orderTimes[0]));
    component.setState({ day: now, hourSelectValue: 0 });
  }
};

export const getExcludedOrderDays = (date, orderType, maximumPreparationTime) => {
  const now = moment();
  const newDate = moment(date);

  let diffDays = now.diff(newDate, 'days');
  if (diffDays > 0) {
    return false;
  }
  if (maximumPreparationTime) {
    const nowPlusPreparationTime = moment().add(maximumPreparationTime, 'h');
    diffDays = nowPlusPreparationTime.diff(newDate, 'days');
    if (diffDays > 0) {
      return false;
    }
  }
  const orderTimes = getOrderTimes(newDate, orderType, maximumPreparationTime);
  if (orderTimes.length === 0) {
    return false;
  }
  const { openHours, specialHours } = store.getState().shopReducer;
  // Check if shop exceptionnaly close for a specific date
  const isTodayExceptionnallyClose = _.find(specialHours, hours => {
    const { day, slots } = hours;
    return isSameDay(day, date) && !slots;
  });
  if (isTodayExceptionnallyClose) {
    return false;
  }
  // Check if shop open for a specific date
  const openHour = _.find(openHours, (openHour, day) => {
    return newDate.format('dddd') === day && (openHour.slots || openHour.slotsDelivery);
  });
  if (openHour) {
    return true;
  }
  return false;
};

export const getLimit = (limits, slots, slot) => {
  if (limits) {
    const indexSlot = _.findIndex(slots, s => s === slot);
    const limit = limits[indexSlot];
    return limit;
  }
};

export const getOrderTimes = (day, orderType, maximumPreparationTime) => {
  const orderTimes = [];
  const nowMinutes = parseInt(moment().format('mm'), 10);
  const { openHours, specialHours } = store.getState().shopReducer;
  const { configHours } = store.getState().configurationReducer;
  let slots = null;
  let slotsDelivery = null;
  let limits = null;
  let limitsDelivery = null;
  const hasSpecialHours = _.find(specialHours, hours => {
    return isSameDay(hours.day, day);
  });
  if (openHours[day.format('dddd')]) {
    slots = openHours[day.format('dddd')].slots;
    limits = openHours[day.format('dddd')].limits;
    limitsDelivery = openHours[day.format('dddd')].limitsDelivery;
    slotsDelivery = openHours[day.format('dddd')].slotsDelivery;
  }
  if (hasSpecialHours) {
    slots = hasSpecialHours.slots;
    slotsDelivery = hasSpecialHours.slots;
  }
  if (slots || slotsDelivery) {
    const newSlots = orderType === ORDER_TYPE_DELIVERY && slotsDelivery ? slotsDelivery : slots;
    const newLimits = orderType === ORDER_TYPE_DELIVERY && slotsDelivery ? limitsDelivery : limits;
    const sortedSlots = sortSlots(newSlots, day);
    const slotInterval = configHours.slotInterval || 30;
    _.map(sortedSlots, slot => {
      const limit = getLimit(newLimits, newSlots, slot);
      const stringSlots = slot.split('-');
      const beginHours = moment(`${day.format('YYYY-MM-DD')}T${stringSlots[0]}:00`);
      let endHours = moment(`${day.format('YYYY-MM-DD')}T${stringSlots[1]}:00`);
      if (stringSlots[1] === '00:00') {
        const dayPlusOne = moment(day).add(1, 'd');
        endHours = moment(`${dayPlusOne.format('YYYY-MM-DD')}T${stringSlots[1]}:00`);
      }
      let now = moment();
      if (maximumPreparationTime) {
        now = moment().add(maximumPreparationTime, 'h');
      }
      let date;
      if (now > beginHours) {
        date = moment(now);
        const firstSlot = moment(beginHours);
        const nextSlot = firstSlot.add(slotInterval, 'm');
        while (nextSlot < date) {
          firstSlot.add(slotInterval, 'm');
        }
        date = firstSlot;
      } else {
        date = moment(beginHours);
      }
      // if (now > beginHours && now < endHours && isToday(day) && maximumPreparationTime <= 0) {
      //   orderTimes.push({ value: 'Maintenant', data: moment() });
      // }
      while (date < endHours) {
        const datePlus30Miunutes = date.clone().add(slotInterval, 'm');
        const hasLimit = checkOrdersLimit(limit, date, datePlus30Miunutes);
        if (!hasLimit) {
          const dateFormatted = `${date.format('HH:mm')} - ${datePlus30Miunutes.format('HH:mm')}`;
          orderTimes.push({ value: dateFormatted, data: date.clone() });
        }
        date = date.add(slotInterval, 'm');
      }
    });
  }
  return orderTimes;
};

export const sendOrderMessenger = async component => {
  try {
    component.setState({ loading: true });
    const link = await getDataFromTable('/configuration/chatbot/link');
    const customerId = getWebViewCustomerId();
    const pageId = getWebViewPageId();
    const data = {
      customerId,
      pageId
    };
    await sendPostRequest(API_SEND_ORDER_MESSENGER, data, {});
    closeWebView();
    setTimeout(() => {
      component.setState({ loading: false });
      window.location.href = link;
      store.dispatch(showCartModal(false));
    }, 5000);
  } catch (error) {
    component.setState({ loading: false });
    store.dispatch(
      showMessageModal("Nous n'avons pas pu envoyer votre commande. Veuillez réessayer.")
    );
    sendCloudWatchAlert(`Could not send order messenger ${error}`);
  }
};
