import React from 'react';
import { connect } from 'react-redux';
import ReactPhoneInput from 'react-phone-input-2';
import { formatPhoneService } from './PhoneInput.services';
import { phoneNumberMinLength } from '../../utils';

const INITIAL_STATE = {};

class PhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    const { className, isPhoneIncorrect, onChange, onBlur } = this.props;
    let { value } = this.props;
    value = formatPhoneService(this, value);
    return (
      <ReactPhoneInput
        value={value}
        countryCodeEditable={false}
        onChange={onChange}
        inputClass={`${className} dark-modal`}
        dropdownClass='dropdownClass'
        onBlur={onBlur}
        placeholder='Numéro de téléphone'
        country='fr'
        preferredCountries={['fr', 'mc']}
        regions={['europe']}
        ref={node => (this.phoneInput = node)}
        masks={{ mc: '.. .. .. ..' }}
        isValid={(value, countryCode) => {
          if (this.phoneInput) {
            const { dialCode, iso2 } = countryCode;
            const minLength = phoneNumberMinLength(iso2);
            isPhoneIncorrect(value.length - dialCode.length < minLength);
          }
          return true;
        }}
      />
    );
  }
}

export default connect(null, {})(PhoneInput);
