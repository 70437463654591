import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {
  showAddressModal,
  showChooseShop,
  updateAddressIsLoaded
} from '../../redux/actions';
import AddressInput from '../AddressInput';
import ChooseShop from '../ChooseShop';
import CloseButton from '../CloseButton';
import ModalBorder from '../ModalBorder';
import { UNIT } from '../../constants/configuration';
import { isTerminalVertical } from '../../utils';

class AddressModal extends React.Component {
  onClose = () => {
    const { showAddressModal, showChooseShop } = this.props;
    showAddressModal(false);
    showChooseShop(false);
  };

  render() {
    const { addressModal, chooseShop, isMobile } = this.props;
    return (
      <div>
        <Modal
          show={addressModal}
          onHide={() => {
            this.onClose();
          }}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          scrollable
        >
          <ModalBorder
            className='hide-scroll modal-size-fixed'
            width='90%'
            margin='0 auto'
          >
            <CloseButton
              onClick={this.onClose}
              closeStyle={{ right: UNIT * 1.8 }}
            />
            {!chooseShop && (
              <Modal.Body
                className='py-4 px-0 px-sm-2 dishop-iso dark-modal border-color'
                style={
                  isTerminalVertical()
                    ? {}
                    : { backgroundColor: 'transparent', borderRadius: UNIT * 2 }
                }
              >
                <div className='dishop-iso'>
                  <div className='container-fluid dark-modal'>
                    <div className='row'>
                      <div className='col-12'>
                        <AddressInput fromModal />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            )}
            {chooseShop && <ChooseShop />}
          </ModalBorder>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ componentReducer, configurationReducer }) => {
  const { addressModal, chooseShop, isMobile } = componentReducer;
  const { marketplace, secondaryColor } = configurationReducer;
  return {
    addressModal,
    chooseShop,
    marketplace,
    secondaryColor,
    isMobile
  };
};

export default connect(mapStateToProps, {
  showAddressModal,
  showChooseShop,
  updateAddressIsLoaded
})(AddressModal);
