import React from 'react';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import {
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_CLICK_AND_COLLECT,
  CARD_ICON_URL,
  TIME_ICON_URL,
  CLICK_AND_COLLECT_ICON_URL,
  ORDER_TYPE_CLICK_AND_SEAT
} from '../../constants';
import { getTheme } from '../../utils';

class CaracteristicsSection extends React.Component {
  render() {
    const { index, webapp, orderTypes } = this.props;
    const { imageCoverUrl2 } = webapp.images;
    const { style } = getTheme(index, imageCoverUrl2);
    let headerTwo = '';
    let descriptionTwo = '';
    if (
      orderTypes.includes(
        ORDER_TYPE_DELIVERY,
        ORDER_TYPE_CLICK_AND_COLLECT,
        ORDER_TYPE_CLICK_AND_SEAT
      )
    ) {
      headerTwo += 'LIVRAISON, SUR PLACE & CLICK AND COLLECT';
      descriptionTwo = "En livraison, à emporter ou sur place, on s'occupe de tout pour vous.";
    } else if (orderTypes.includes(ORDER_TYPE_DELIVERY, ORDER_TYPE_CLICK_AND_COLLECT)) {
      headerTwo += 'LIVRAISON & CLICK AND COLLECT';
      descriptionTwo = "En livraison ou à emporter , on s'occupe de tout pour vous.";
    } else if (orderTypes.includes(ORDER_TYPE_DELIVERY, ORDER_TYPE_CLICK_AND_SEAT)) {
      headerTwo += 'LIVRAISON & SUR PLACE';
      descriptionTwo = "En livraison ou sur place, on s'occupe de tout pour vous.";
    } else if (orderTypes.includes(ORDER_TYPE_DELIVERY)) {
      headerTwo += 'LIVRAISON À DOMICILE';
      descriptionTwo =
        "Ne bougez pas de chez vous, on vous livre à domicile et on s'occupe de tout.";
    } else if (orderTypes.includes(ORDER_TYPE_CLICK_AND_COLLECT)) {
      headerTwo += 'CLICK AND COLLECT';
      descriptionTwo = 'Récupérer votre commande quand vous le souhaitez et sans attendre.';
    }
    return (
      <section style={style}>
        <div style={{ backgroundColor: index % 2 ? 'rgba(0,0,0,.8)' : 'white' }}>
          <div className='container'>
            <div className='row py-5 text-center text-dark'>
              <div className='col-md-4 zoom p-0 p-sm-4 py-3 py-sm-0'>
                <Fade left>
                  <div className='card h-100 shadow'>
                    <div className='card-body'>
                      <img
                        alt='order_fast'
                        src={TIME_ICON_URL}
                        style={{ width: '50px', marginBottom: '15px' }}
                      />
                      <h4>RAPIDITÉ</h4>
                      <p>
                        Nous mettons tout en oeuvre pour préparer votre commande le plus rapidement
                        possible
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className='col-md-4 zoom p-0 p-sm-4 py-3 py-sm-0'>
                <Fade>
                  <div className='card h-100 shadow'>
                    <div className='card-body'>
                      <img
                        alt='click_and_collect'
                        src={CLICK_AND_COLLECT_ICON_URL}
                        style={{ width: '50px', marginBottom: '15px' }}
                      />
                      <h4>{headerTwo}</h4>
                      <p>{descriptionTwo}</p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className='col-md-4 zoom p-0 p-sm-4 py-3 py-sm-0'>
                <Fade right>
                  <div className='card h-100 shadow'>
                    <div className='card-body'>
                      <img
                        alt='online_payment'
                        src={CARD_ICON_URL}
                        style={{ width: '50px', marginBottom: '15px' }}
                      />
                      <h4>PAIEMENT SÉCURISÉ</h4>
                      <p>
                        N'ayez aucune crainte pour votre argent. Payez via notre site à l'aide du
                        module sécurisé de notre partenaire de paiement{' '}
                        <a href='https://stripe.com/fr' target='_blank' rel='noreferrer'>
                          Stripe
                        </a>
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { webapp, orderTypes } = configurationReducer;
  return {
    webapp,
    orderTypes
  };
};

export default connect(mapStateToProps)(CaracteristicsSection);