import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Shop from '../Shop';
import { updateClosestShops } from '../../../redux/actions';
import { updateShops } from './ListShop.services';

class ListShop extends React.Component {
  componentDidMount() {
    const { closestShops, customerAddress } = this.props;
    if (!closestShops && customerAddress) {
      updateShops(this);
    }
  }

  render() {
    const { closestShops } = this.props;
    const shopClassname = `col-xl-4 col-lg-4 col-md-6 col-sm-10 mb-2 px-3`;
    return _.keys(closestShops)
      .sort((a, b) => {
        const shopA = closestShops[a];
        const shopB = closestShops[b];
        if (shopA && shopB) {
          const positionA = shopA.closed ? 70 : 2;
          const positionB = shopB.closed ? 70 : 1;
          return positionA - positionB;
        }
      })
      .map(key => {
        const shop = closestShops[key];
        const { name, address, distance, sliding, shopId, description, closed } = shop;
        return (
          <>
            <div className={shopClassname} key={key}>
              <Shop
                shopIndex={shopId}
                name={name}
                address={address}
                distance={distance}
                sliding={sliding}
                description={description}
                closed={closed}
              />
            </div>
          </>
        );
      });
  }
}

const mapStateToProps = ({ shopReducer, userReducer }) => {
  const { closestShops } = shopReducer;
  const { customerAddress } = userReducer;
  return {
    closestShops,
    customerAddress
  };
};

export default connect(mapStateToProps, { updateClosestShops })(ListShop);
