import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { ScrollView, ScrollElement } from '../../ScrollVertical';
import '../../../css/react-perfect-scrollbar.css';
import Section from '../Section';
import { showProducts } from '../../../redux/actions';
import { setSectionListKey } from './ListSections.services';
import { isTerminalVertical, filterSections, getTerminalVerticalHeight } from '../../../utils';

const Sections = sections => {
  return _.map(sections, (section, key) => {
    return (
      <div key={key}>
        <Section
          key={key}
          sectionIndex={key}
          title={section.name}
          image={section.iconUrl}
          sectionKey={section.key}
        />
      </div>
    );
  });
};

const ArrowLeft = <i className='fa fa-arrow-left text-color pl-2' />;
const ArrowRight = <i className='fa fa-arrow-right text-color pr-2' />;

const INITIAL_STATE = {
  selected: '0',
  scrollIndex: 0
};

class ListSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.anchor = window.location.hash.substr(1);
  }

  componentWillReceiveProps(nextProps) {
    const { selectedSection } = this.props;
    if (nextProps.selectedSection !== selectedSection) {
      // scroll horizontal
      const anchor = window.location.hash.substr(1);
      if (this.anchor === anchor) {
        this.setState({ scrollIndex: nextProps.selectedSection });
      } else {
        this.anchor = anchor;
      }
      // scroll vertical
      setSectionListKey(this, nextProps.selectedSection);
    }
  }

  render() {
    const { navbar, sections, isMobile, navbarHeight, isHorizontalSection } = this.props;
    const { selected, scrollIndex } = this.state;
    const newSections = filterSections(sections);
    const menu = Sections(newSections);
    const containerClassname = navbar ? 'px-0 col-12' : '';
    return (
      <>
        {isHorizontalSection && (
          <>
            <div
              className={`${containerClassname} section-bar`}
              style={{
                paddingLeft: isMobile ? 10 : 0
              }}
            >
              <ScrollMenu
                data={menu}
                selected={String(selected)}
                arrowLeft={!isMobile ? ArrowLeft : null}
                arrowRight={!isMobile ? ArrowRight : null}
                scrollToSelected
                alignCenter={false}
                dragging={isMobile}
                wheel={false}
                transition={1}
                hideSingleArrow
                hideArrows
              />
            </div>
          </>
        )}
        {!isHorizontalSection && (
          <div
            className='sticky-top hide-scroll overflow-auto'
            style={{
              top: navbarHeight,
              height: isTerminalVertical()
                ? getTerminalVerticalHeight()
                : window.innerHeight * 0.875
            }}
          >
            <ScrollView scrollToIndex={scrollIndex} className='hide-scroll'>
              {registration =>
                _.map(newSections, (section, key) => {
                  if (section && !section.hidden) {
                    return (
                      <ScrollElement
                        sectionKey={section.key}
                        key={section.key}
                        registration={registration}
                      >
                        <Section
                          key={key}
                          sectionIndex={key}
                          title={section.name}
                          image={section.iconUrl}
                          sectionKey={section.key}
                        />
                      </ScrollElement>
                    );
                  }
                })
              }
            </ScrollView>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  shopReducer,
  configurationReducer,
  sectionReducer,
  componentReducer
}) => {
  const { sections } = shopReducer;
  const { isMobile, navbarHeight, isHorizontalSection } = componentReducer;
  const { sectionOrientation } = configurationReducer;
  const { selectedSection } = sectionReducer;
  return {
    sections,
    sectionOrientation,
    selectedSection,
    isMobile,
    navbarHeight,
    isHorizontalSection
  };
};

export default connect(mapStateToProps, {
  showProducts
})(ListSection);
