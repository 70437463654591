import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Button from '../Button';
import Horaires from '../Cover/Horaires';
import { showHoursModal } from '../../redux/actions';
import ModalBorder from '../ModalBorder';
import { SECONDARY } from '../../constants/configuration';
import CloseButton from '../CloseButton';
import { UNIT } from '../../constants/configuration';
const hoursModalData = {
  show: false
};

class HorairesModal extends React.Component {
  render() {
    const { showHoursModal, hoursModal } = this.props;
    const { show, title, hours } = hoursModal;
    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            showHoursModal(hoursModalData);
          }}
          scrollable
          centered
        >
          <ModalBorder className='hide-scroll modal-size-fixed'>
            <Modal.Header className='modal-sticky-header dark-modal'>
              <h5 className='text-secondary label'>{`${title ||
                "Horaires d'ouvertures"}`}</h5>
              <div style={{ position: 'inherit' }}>
                <CloseButton
                  closeStyle={{ right: UNIT * 0.5 }}
                  onClick={() => {
                    showHoursModal(hoursModalData);
                  }}
                />
              </div>
            </Modal.Header>

            <Modal.Body className='dark-modal'>
              <Horaires hours={hours} />
            </Modal.Body>
            <Modal.Footer
              className={` modal-sticky-footer justify-content-center 
                dark-modal`}
            >
              <Button
                type={SECONDARY}
                onClick={() => {
                  showHoursModal(hoursModalData);
                }}
              >
                Fermer
              </Button>
            </Modal.Footer>
          </ModalBorder>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ shopReducer, componentReducer, userReducer }) => {
  const { hoursModal } = componentReducer;
  const { openHours } = shopReducer;
  const { customerAddress } = userReducer;
  return { openHours, hoursModal, customerAddress };
};

export default connect(mapStateToProps, { showHoursModal })(HorairesModal);
