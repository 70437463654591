import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Fade from 'react-reveal/Fade';
import Button from '../Button';
import {
  userSignOut,
  showUpdateInfo,
  showPaymentModal,
  showAccountModal
} from '../../redux/actions';
import { getCreditCard, isObjectNotNull } from '../../utils';
import CreditCard from '../CreditCard';
import PastOrders from '../PastOrders';
import AccountForm from '../AccountForm';
import ModalBorder from '../ModalBorder';
import { UNIT, PRIMARY, SECONDARY } from '../../constants/configuration';

const INITIAL_STATE = { pastOrder: false };

class AccountModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderForm = () => {
    return <AccountForm />;
  };

  renderBody = () => {
    const { userConnected, user, showUpdateInfo, showPaymentModal } = this.props;
    const { pastOrder } = this.state;
    const { firstName, lastName, numero, email, cards } = user;
    const card = getCreditCard(cards);
    const iconPadding = 'pr-2';
    if (userConnected) {
      return (
        <Modal.Body className='dark-modal'>
          {pastOrder && (
            <Fade>
              <PastOrders />
            </Fade>
          )}
          {!pastOrder && (
            <div className='p-0 p-sm-2'>
              <h4 className='text-muted text-center mb-0 label'>Profil</h4>
              <div className='row'>
                <div className='col-12 col-sm-8 py-2 no-localization'>
                  <i className={`fas fa-user ${iconPadding}`} style={{ fontSize: UNIT }} />
                  {firstName || '--'} {lastName}
                  <br />
                  <i className={`fas fa-envelope ${iconPadding}`} />
                  {email || '--'}
                  <br />
                  <i className={`fas fa-phone-alt ${iconPadding}`} />
                  {numero || '--'}
                </div>
                <div className='col-12 col-sm-4 pt-2 pt-sm-0 d-flex align-items-center'>
                  <Button
                    type={PRIMARY}
                    className='btn-block label'
                    onClick={() => {
                      showUpdateInfo(true);
                    }}
                  >
                    <i className='fas fa-user-cog pr-2' />
                    Modifier
                  </Button>
                </div>
              </div>
              <h4 className='text-muted text-center mt-3 label'>Cartes bancaires</h4>
              {card ? (
                <CreditCard buttonName='Modifier' />
              ) : (
                <div className='row align-items-center justify-content-center'>
                  <Button
                    type={PRIMARY}
                    className='btn-block label'
                    onClick={() => {
                      showPaymentModal(true);
                    }}
                  >
                    <i className='fas fa-credit-card pr-2' />
                    {isObjectNotNull(cards)
                      ? 'Gestion de vos cartes bancaires'
                      : 'Ajouter une carte bancaire'}
                  </Button>
                </div>
              )}
              <h4 className='text-muted text-center mt-3 label'>Mes commandes</h4>
              <Button
                type={PRIMARY}
                className='btn-block'
                onClick={() => {
                  this.setState({ pastOrder: true });
                }}
              >
                <i className='fas fa-cart-arrow-down pr-2' />
                Voir mes commandes
              </Button>
            </div>
          )}
        </Modal.Body>
      );
    }

    return <div>Se connecter</div>;
  };

  renderFooter = () => {
    const { showAccountModal, userSignOut } = this.props;
    const { pastOrder } = this.state;
    return (
      <Modal.Footer className='modal-sticky-footer justify-content-center dark-modal'>
        {pastOrder && (
          <>
            <Button
              type={SECONDARY}
              onClick={() => {
                this.setState({ pastOrder: false });
              }}
            >
              Retour
            </Button>
          </>
        )}
        {!pastOrder && (
          <>
            <Button
              type={SECONDARY}
              onClick={() => {
                showAccountModal(false);
              }}
            >
              Fermer
            </Button>
            <Button
              type={PRIMARY}
              onClick={() => {
                showAccountModal(false);
                userSignOut();
              }}
            >
              <i className='fas fa-sign-out-alt pr-2' />
              Se déconnecter
            </Button>
          </>
        )}
      </Modal.Footer>
    );
  };

  render() {
    const { accountModal, showAccountModal, updateInfo, showUpdateInfo } = this.props;
    return (
      <Modal
        onHide={() => {
          showAccountModal(false);
          showUpdateInfo(false);
        }}
        scrollable
        show={accountModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <ModalBorder className={`hide-scroll ${updateInfo ? '' : 'modal-size-fixed'} dark-modal `}>
          {!updateInfo && (
            <div>
              {this.renderBody()}
              {this.renderFooter()}
            </div>
          )}
          {updateInfo && this.renderForm()}
        </ModalBorder>
      </Modal>
    );
  }
}

const mapStateToProps = ({ componentReducer, userReducer, configurationReducer }) => {
  const { accountModal, updateInfo } = componentReducer;
  const { user, userConnected, updateInfoError } = userReducer;
  const { secondaryColor } = configurationReducer;
  return {
    accountModal,
    user,
    userConnected,
    secondaryColor,
    updateInfoError,
    updateInfo
  };
};

export default connect(mapStateToProps, {
  showAccountModal,
  userSignOut,
  showUpdateInfo,
  showPaymentModal
})(AccountModal);