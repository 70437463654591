import _ from 'lodash';
import moment from 'moment';
import { getPromotion } from './customer';
import store from '../redux/store';
import { sendCloudWatchAlert } from './logs';
import {
  ORDER_TYPE_DELIVERY,
  PAYMENT_TYPE_TICKET_RESTO,
  PAYMENT_TYPE_CB,
  PAYMENT_TYPE_STRIPE,
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_COUNTER,
  PROMOTION_DELIVERY_FREE,
  PROMOTION_PERCENTAGE,
  API_SEND_ORDER,
  API_PAYMENT_TRMINAL,
  ORDER_TYPE_CLICK_AND_SEAT,
  END_CONTENT,
  ERROR_MESSAGE_CONTENT,
  STRIPE_MINIMUM_AMOUNT,
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_PREPARING,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_TAKEAWAY,
  ORDER_PHASE_WAITER,
  ORDER_PHASE_CONTACTING_TIME,
  ORDER_PHASE_DELIVERING_TIME,
  ORDER_PHASE_PREPARING_TIME,
  ORDER_TYPE_DELIVERY_LABEL,
  ORDER_TYPE_CLICK_AND_COLLECT_LABEL,
  ORDER_TYPE_CLICK_AND_SEAT_LABEL
} from '../constants';
import {
  resetTerminalSession,
  sendCloudWatchLogs,
  validatePhone,
  getTimezone,
  renderErrorMessage,
  getCurrentShopSlot,
  isTerminal,
  isQrCode,
  getCustomerTableNb,
  isStringNotNull,
  formatPhone
} from '.';
import { getTerminalId } from './config.js';
import {
  showMessageModal,
  showHoursModal,
  showCartModal,
  showOverlayCover,
  showOrderModal,
  showOrderStatus
} from '../redux/actions';
import {
  createPayment,
  retrieveCard,
  createCustomer,
  addCardToExistingCustomer,
  updateDataOnTable,
  updatesDataOnTable,
  sendPostRequest,
  validateDeliveryStuart,
  getIdToken,
  deleteCustomerPaymentCardBackend,
  sendGoogleAnalytics,
  updateCustomerHubrise,
  confirmPaymentIntent
} from '../api';
import { displayPriceFormat } from './product';
import { getShopFromOrder, getClosedMessage } from './shop';
import { getDateToday } from './date';
import { getAddressFormatted } from './map';

export const roundNumber = number => {
  return Math.round(number * 100) / 100;
};

export const getNonStripeChargeId = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const calculateProductPrice = () => {
  const cart = store.getState().cartReducer;
  let productPrice = 0;

  if (cart && !_.isEmpty(cart)) {
    const prices = _.keys(cart).map(key => {
      const cartItem = cart[key];
      return cartItem.price;
    });

    for (let i = 0; i < prices.length; i += 1) {
      productPrice += prices[i];
    }
  }

  return roundNumber(productPrice);
};

export const calculateFullPrice = () => {
  const { servicePrice, managementFee } = getUserServicePrice();
  let productPrice = calculateProductPrice();
  let totalPriceOrder = servicePrice + managementFee + productPrice;
  const promotion = getPromotion();
  if (promotion) {
    if (promotion.type === PROMOTION_DELIVERY_FREE) {
      totalPriceOrder -= servicePrice;
    } else if (
      typeof promotion.type === 'string' &&
      promotion.type.includes(PROMOTION_PERCENTAGE)
    ) {
      const percentageReduction = promotion.type.match(/\d+/)[0];
      totalPriceOrder -= Math.round(productPrice * percentageReduction) / 100;
    } else if (typeof promotion.type === 'number') {
      const percentageReduction = promotion.type;
      productPrice =
        productPrice - percentageReduction > 0 ? productPrice - percentageReduction : 0;
      totalPriceOrder = servicePrice + managementFee + productPrice;
    }
  }
  return roundNumber(totalPriceOrder);
};

export const getUserServicePrice = () => {
  try {
    const { customerAddress } = store.getState().userReducer;
    let orderType = '';
    if (customerAddress) {
      orderType = customerAddress.orderType;
    }
    const { userServicePriceInfo = {} } = store.getState().shopReducer;
    const { userServicePrice, userCancelServicePrice } = userServicePriceInfo;
    let managementFee = 0;
    if (userServicePriceInfo.managementFee > 0) {
      managementFee = userServicePriceInfo.managementFee;
    }
    let servicePrice = 0;
    let nextServicePrice = 0;
    let nextProductPrice = 0;
    if (orderType === ORDER_TYPE_DELIVERY) {
      if (customerAddress.deliveryPrice) {
        servicePrice = customerAddress.deliveryPrice;
      } else if (userServicePrice) {
        const totalProductPriceOrder = calculateProductPrice();
        _.map(userServicePrice, val => {
          if (val) {
            if (
              totalProductPriceOrder >= val.priceOrder &&
              (!val.nextPriceOrder || totalProductPriceOrder < val.nextPriceOrder)
            ) {
              servicePrice = val.fees;
              if (val.nextPriceOrder) {
                nextProductPrice = val.nextPriceOrder;
                nextServicePrice = val.nextFees;
              }
            }
          }
        });
      }
    }
    return {
      servicePrice,
      nextProductPrice,
      nextServicePrice,
      managementFee,
      userCancelServicePrice
    };
  } catch (error) {
    sendCloudWatchAlert(`Could not get user service price ${error}`);
  }
};

export const getUserCancelServicePrice = (
  userCancelServicePrice,
  totalProductPriceOrder,
  totalPriceOrder
) => {
  let cancelServicePrice = 0;
  if (userCancelServicePrice.type === '%') {
    cancelServicePrice = (totalProductPriceOrder * userCancelServicePrice.value) / 100;
  } else {
    cancelServicePrice = userCancelServicePrice.value;
  }
  if (cancelServicePrice > totalPriceOrder) {
    cancelServicePrice = totalPriceOrder;
  }
  return cancelServicePrice;
};

export const getPaymentTypeLabel = paymentType => {
  let paymentTypeLabel = '';
  switch (paymentType) {
    case PAYMENT_TYPE_STRIPE:
      paymentTypeLabel = 'Payer en ligne';
      break;
    case PAYMENT_TYPE_CASH:
      if (isTerminal()) {
        paymentTypeLabel = 'Espèce';
      } else {
        paymentTypeLabel = 'Payer en espèce';
      }
      break;
    case PAYMENT_TYPE_CB:
      if (isTerminal()) {
        paymentTypeLabel = 'CB';
      } else {
        paymentTypeLabel = 'Payer en CB';
      }
      break;
    case PAYMENT_TYPE_TICKET_RESTO:
      if (isTerminal()) {
        paymentTypeLabel = 'Ticket restaurant';
      } else {
        paymentTypeLabel = 'Payer en ticket restaurant';
      }
      break;
    case PAYMENT_TYPE_COUNTER:
      if (isTerminal()) {
        paymentTypeLabel = 'Comptoir';
      } else {
        paymentTypeLabel = 'Payer sur place';
      }
      break;
    default:
      break;
  }
  return paymentTypeLabel;
};

export const checkClosedShop = component => {
  const { closed } = store.getState().shopReducer;
  if (isStringNotNull(closed) && !isTerminal()) {
    component.setState({ loading: false });
    store.dispatch(showMessageModal(getClosedMessage(closed)));
    return true;
  }
  return false;
};

export const checkOrder = (component, paymentType, card) => {
  const closedShop = checkClosedShop(component);
  const totalPriceOrder = calculateFullPrice();
  if (!closedShop) {
    component.setState({ loading: true, errorMessage: '' });
    const { customerAddress, user } = store.getState().userReducer;
    const { numero } = user;
    const { minimum, openHours, minimumClickAndCollect, shopName } = store.getState().shopReducer;
    const { orderTime } = store.getState().orderReducer;
    const { orderType } = customerAddress;
    const totalProductPriceOrder = calculateProductPrice();
    if ((component.isPhoneIncorrect || !isStringNotNull(numero)) && !isQrCode() && !isTerminal()) {
      component.setState({
        loading: false
      });
    } else if (orderType === ORDER_TYPE_DELIVERY && totalProductPriceOrder < minimum) {
      component.setState({
        loading: false
      });
      store.dispatch(
        showMessageModal(
          `Votre panier doit être supérieur à ${displayPriceFormat(
            minimum,
            true
          )} pour pouvoir passer commander.`
        )
      );
    } else if (
      orderType === ORDER_TYPE_CLICK_AND_COLLECT &&
      totalProductPriceOrder < minimumClickAndCollect &&
      !isTerminal()
    ) {
      component.setState({
        loading: false
      });
      store.dispatch(
        showMessageModal(
          `Votre panier doit être supérieur à ${displayPriceFormat(
            minimumClickAndCollect,
            true
          )} pour pouvoir passer commander.`
        )
      );
    } else if (
      paymentType === PAYMENT_TYPE_STRIPE &&
      totalPriceOrder < STRIPE_MINIMUM_AMOUNT &&
      totalPriceOrder > 0
    ) {
      component.setState({
        loading: false
      });
      store.dispatch(
        showMessageModal(
          `Votre panier doit être supérieur à ${displayPriceFormat(
            STRIPE_MINIMUM_AMOUNT,
            true
          )} pour pouvoir utiliser le paiement en ligne.`
        )
      );
    } else if (openHours) {
      const { slot, isSpecialHours } = getCurrentShopSlot(orderTime.data, orderType);
      if (!slot) {
        if (!isSpecialHours) {
          sendCloudWatchLogs('Le service est fermé');
          component.setState({ loading: false });
          const hoursModalData = {
            show: true,
            hours: openHours
          };
          store.dispatch(showHoursModal(hoursModalData));
        } else {
          component.setState({ loading: false });
          store.dispatch(
            showMessageModal(
              getClosedMessage(`${shopName} est exceptionnellement fermé sur ce créneau horaire.`)
            )
          );
          sendCloudWatchLogs('Shop exceptionnaly closed');
        }
      } else {
        sendOrderClick(component, paymentType, card);
      }
    } else {
      sendOrderClick(component, paymentType, card);
      component.setState({ choosePayment: true });
    }
  }
};

export const sendOrderClick = async (component, paymentType, card) => {
  try {
    sendCloudWatchLogs('Sending new order');
    const { servicePrice } = store.getState().shopReducer;
    const { orderTime, easelNumber } = store.getState().orderReducer;
    const { customerAddress, user, userConnected } = store.getState().userReducer;
    const { stuart, configHours } = store.getState().configurationReducer;
    const { customAccountId } = servicePrice;
    const { shopId, orderType } = customerAddress;
    const { email, stripeCustomerId, customerId, firstName, lastName } = user;
    let { numero } = user;
    const finalOrder = {};
    const orderNumber = 0;
    numero = formatPhone(numero);

    const userServicePriceInfo = getUserServicePrice();
    const totalProductPriceOrder = calculateProductPrice();
    const totalPriceOrder = calculateFullPrice();
    const userCancelServicePrice = getUserCancelServicePrice(
      userServicePriceInfo.userCancelServicePrice,
      totalProductPriceOrder,
      totalPriceOrder
    );

    const promotion = getPromotion();
    finalOrder.chargeId = `cash_${getNonStripeChargeId()}`;
    finalOrder.address = customerAddress;
    finalOrder.date =
      isQrCode() || isTerminal() || configHours.hideScheduledTime
        ? moment().format()
        : orderTime.data.format();
    finalOrder.distance = '';
    finalOrder.firstName = firstName || '';
    finalOrder.email = email;
    finalOrder.lastName = lastName || '';
    finalOrder.id = customerId;
    finalOrder.idDep = '';
    finalOrder.managementFee = userServicePriceInfo.managementFee;
    finalOrder.numero = numero;
    finalOrder.numeroDep = '';
    finalOrder.orderNumber = orderNumber;
    finalOrder.orderType = orderType;
    finalOrder.paymentType = paymentType;
    finalOrder.shopId = shopId;
    finalOrder.status = 'contacting';
    finalOrder.timezone = getTimezone();
    finalOrder.totalPriceOrder = totalPriceOrder;
    finalOrder.totalProductPriceOrder = totalProductPriceOrder;
    finalOrder.userCancelServicePrice = userCancelServicePrice;
    finalOrder.userNameDep = '';
    finalOrder.userServicePrice = userServicePriceInfo.servicePrice;
    if (isQrCode()) {
      finalOrder.canal = 'QRCODE';
    } else if (isTerminal()) {
      finalOrder.canal = 'TERMINAL';
    } else {
      finalOrder.canal = 'WEBAPP';
    }
    if (isTerminal() && easelNumber) {
      finalOrder.tableNb = easelNumber;
    }
    if (orderType === ORDER_TYPE_CLICK_AND_SEAT && isQrCode()) {
      finalOrder.tableNb = getCustomerTableNb();
    }
    if (promotion) {
      finalOrder.promotion = promotion;
    }
    if (userConnected) {
      await updateCustomerHubrise({ address: customerAddress, numero });
      await updateDataOnTable(`/users/${customerId}/donneesPerso`, { numero: numero || '' });
      if (orderType === ORDER_TYPE_DELIVERY) {
        const { street2, instruction } = customerAddress;
        const addressId = customerAddress.id;
        const updates = {};
        updates[`/users/${customerId}/donneesPerso/addresses/${addressId}/street2`] = street2 || '';
        updates[`/users/${customerId}/donneesPerso/addresses/${addressId}/instruction`] =
          instruction || '';
        await updatesDataOnTable(updates);
      }
    }
    if (stuart && orderType === ORDER_TYPE_DELIVERY) {
      sendCloudWatchLogs('Validating delivery stuart');
      finalOrder.stuart = {
        enabled: true
      };
      await validateDeliveryStuart(finalOrder);
    }
    if (paymentType === PAYMENT_TYPE_STRIPE && totalPriceOrder) {
      try {
        sendCloudWatchLogs('Creating payment');
        const amount = parseInt(totalPriceOrder.toFixed(2) * 100, 10);
        const transferGroup = moment.utc(finalOrder.date).format('X');
        const intentData = {
          amount,
          description: `${shopId} - ${email}`,
          currency: 'eur',
          customer: stripeCustomerId,
          capture_method: 'manual',
          confirmation_method: 'manual',
          payment_method: card.id,
          confirm: true,
          setup_future_usage: 'on_session',
          on_behalf_of: customAccountId,
          transfer_data: {
            destination: customAccountId
          },
          transfer_group: transferGroup
        };
        const data = {
          intentData,
          shopId,
          customerId
        };
        const charge = await createPayment(data);
        const { id, status, client_secret } = charge;
        finalOrder.chargeId = id;
        if (userConnected) {
          attachPaymentMethodToCustomer(card);
        }
        if (status === 'requires_source_action') {
          const { stripe } = component.props;
          const { error, paymentIntent } = await stripe.handleCardAction(client_secret);
          if (paymentIntent) {
            sendCloudWatchLogs(`Requiring confirmation for payment ${paymentIntent.status}`);
          }
          if (error) {
            throw error;
          } else if (paymentIntent.status === 'requires_confirmation') {
            sendCloudWatchLogs(`Asking for 3D SECURE ${id}`);
            await confirmPaymentIntent(paymentIntent.id);
            sendCloudWatchLogs(`Successfully verifying with 3D SECURE ${id} `);
          }
        }
      } catch (error) {
        const errorMessage = String(error);
        if (userConnected) {
          attachPaymentMethodToCustomer(card);
        }
        if (
          !errorMessage.includes('insufficient') &&
          !errorMessage.includes('declined') &&
          !errorMessage.includes('support') &&
          !errorMessage.includes('incorrect') &&
          !errorMessage.includes('A network')
        ) {
          sendCloudWatchAlert(`Custom authorization failed ${error}`);
        }
        store.dispatch(
          showMessageModal(
            'Le paiement en ligne a échoué. Veuillez réessayer ou changer de carte bancaire.'
          )
        );
        component.setState({
          loading: false
        });
        return;
      }
    }
    sendCloudWatchLogs(`Sending order to firebase ${JSON.stringify(finalOrder)}`);
    await sendOrder(component, finalOrder);
  } catch (error) {
    let errorMessage = String(error);
    if (!errorMessage.includes('ERROR_ORDERS_LIMIT')) {
      sendCloudWatchAlert(`Could not send order ${errorMessage}`);
    }
    switch (errorMessage) {
      case 'PHONE_INVALID':
        errorMessage = renderErrorMessage('stuart/phone-invalid');
        break;
      case 'RECORD_INVALID':
        errorMessage = renderErrorMessage('stuart/unavailable');
        break;
      case 'CANT_GEOCODE_ADDRESS':
        errorMessage = renderErrorMessage('stuart/address-not-served');
        break;
      case 'ERROR_ORDERS_LIMIT':
        errorMessage = renderErrorMessage('orders/limit');
        break;
      default:
        errorMessage = "Votre commande n'a pas pu être envoyée. Veuillez réessayer.";
        break;
    }
    component.setState({
      loading: false,
      errorMessage
    });
    if (isTerminal()) {
      store.dispatch(showOverlayCover(ERROR_MESSAGE_CONTENT));
    } else {
      store.dispatch(showMessageModal(errorMessage));
    }
  }
};

export const sendOrder = async (component, order) => {
  const cart = store.getState().cartReducer;
  const { userConnected } = store.getState().userReducer;
  order.commande = cart;
  const { date, promotion, userServicePrice, totalPriceOrder, managementFee, paymentType } = order;
  const firstScheduleDate = moment().add(25, 'm');
  const isSchedule = moment(date).isAfter(firstScheduleDate);
  order.isSchedule = isSchedule;
  let headers = {};
  if (userConnected) {
    const idToken = await getIdToken();
    headers = { Authorization: `Bearer ${idToken}` };
  }
  if (isTerminal()) {
    order.terminalId = getTerminalId();
  }
  const data = {
    order
  };
  if (isTerminal() && paymentType === PAYMENT_TYPE_CB) {
    sendCloudWatchLogs('Sending payment terminal');
    await sendPostRequest(API_PAYMENT_TRMINAL, data, headers);
  }
  await sendPostRequest(API_SEND_ORDER, data, headers);
  sendCloudWatchLogs(`DISHOP WEBAPP - A new order has beent sent.\n ${JSON.stringify(order)}`);
  if (isTerminal()) {
    store.dispatch(showCartModal(false));
    store.dispatch(showOverlayCover(END_CONTENT));
    setTimeout(() => {
      resetTerminalSession();
    }, 5000);
  } else {
    store.dispatch(showCartModal(false));
    store.dispatch(showOrderModal(true));
    store.dispatch(showOrderStatus(order.chargeId));
  }
  component.setState({ loading: false, stripeSCA: {}, choosePayment: false });
  const items = [];
  _.map(cart, product => {
    const { key, name, sectionName, nb, price, position } = product;
    items.push({
      id: key,
      name,
      category: sectionName,
      position,
      quantity: nb,
      price
    });
  });
  sendGoogleAnalytics('purchase', {
    coupon: promotion ? promotion.promoCode : '',
    currency: 'EUR',
    shipping: userServicePrice,
    tax: managementFee,
    value: totalPriceOrder,
    items
  });
};

export const attachPaymentMethodToCustomer = async card => {
  const { user, userConnected } = store.getState().userReducer;
  const { id } = card;

  try {
    const { stripeCustomerId, email } = user;
    const data = {
      cardId: id
    };
    const cardOnStripe = await retrieveCard(data);
    if (!cardOnStripe || !cardOnStripe.customer) {
      const data = { card };
      sendCloudWatchLogs('Ading card to customer');
      if (stripeCustomerId) {
        data.stripeCustomerId = stripeCustomerId;
        await addCardToExistingCustomer(data);
      } else {
        data.email = email;
        await createCustomer(data);
      }
    }
    sendCloudWatchLogs('Successfully creating payment intent');
    return true;
  } catch (error) {
    const errorMessage = String(error);
    if (!errorMessage.includes('not be used again')) {
      sendCloudWatchAlert(`Could not attach payment method to customer ${error}`);
    } else if (userConnected) {
      deleteCustomerPaymentCardBackend(id);
    }
    throw error;
  }
};

export const getStatus = order => {
  if (!_.isEmpty(order)) {
    const shop = getShopFromOrder(order);
    switch (order.status) {
      case ORDER_PHASE_CONTACTING:
        return `Prise de contact avec ${shop.name}...`;
      case ORDER_PHASE_PREPARING:
        return 'Commande en préparation';
      case ORDER_PHASE_DELIVERING:
        return `Livraison prévue pour ${getEstimationTime(order)}`;
      case ORDER_PHASE_TAKEAWAY:
        return `Votre commande est prête à être récupérée au : ${getAddressFormatted(
          shop.address
        )}`;
      case ORDER_PHASE_WAITER:
        return `Votre serveur arrive avec votre commande ${
          order.tableNb ? `à la table ${order.tableNb} ` : ''
        }`;
      default:
        return '';
    }
  }
};

export const getEstimationTime = order => {
  const { orderType, status, isSchedule } = order;
  const {
    date: dateOrder,
    dateSent = moment(),
    datePreparationStarted = moment(),
    datePreparationEnded = moment()
  } = order;
  const estimationTimeFormat = 'HH:mm';
  let date = moment(dateOrder);
  switch (status) {
    case ORDER_PHASE_CONTACTING: {
      if (!isSchedule) {
        let defaultMinutesTimeToAdd = ORDER_PHASE_CONTACTING_TIME + ORDER_PHASE_PREPARING_TIME;
        if (orderType === ORDER_TYPE_DELIVERY) {
          defaultMinutesTimeToAdd += ORDER_PHASE_DELIVERING_TIME;
        }
        date = moment(dateSent).add(defaultMinutesTimeToAdd, 'm');
      }
      const estimationTime = date;
      return estimationTime.format(estimationTimeFormat).replace(':', 'h');
    }
    case ORDER_PHASE_PREPARING: {
      const { preparationTime, isSchedule } = order;
      let defaultMinutesTimeToAdd = preparationTime;
      if (isSchedule) {
        date = date.add(defaultMinutesTimeToAdd, 'm');
      }
      if (orderType === ORDER_TYPE_DELIVERY) {
        defaultMinutesTimeToAdd += ORDER_PHASE_DELIVERING_TIME;
      }

      let estimationTime = moment(datePreparationStarted).add(defaultMinutesTimeToAdd, 'm');
      if (estimationTime.isBefore(date)) {
        estimationTime = date;
      }
      return estimationTime.format(estimationTimeFormat).replace(':', 'h');
    }
    case ORDER_PHASE_DELIVERING: {
      const defaultMinutesTimeToAdd = ORDER_PHASE_DELIVERING_TIME;
      let estimationTime = moment(datePreparationEnded).add(defaultMinutesTimeToAdd, 'm');
      if (estimationTime.isBefore(date)) {
        estimationTime = date;
      }
      return estimationTime.format(estimationTimeFormat).replace(':', 'h');
    }
    case ORDER_PHASE_TAKEAWAY: {
      const estimationTime = moment(datePreparationEnded)
        .format(estimationTimeFormat)
        .replace(':', 'h');
      return estimationTime;
    }
    case ORDER_PHASE_WAITER: {
      const estimationTime = moment(datePreparationEnded)
        .format(estimationTimeFormat)
        .replace(':', 'h');
      return estimationTime;
    }
    default:
      return null;
  }
};

export const checkOrdersLimit = (limit, beginHours, endHours) => {
  const { shopOrders } = store.getState().shopReducer;
  let nbOrdersInterval = 0;
  if (limit >= 0) {
    _.map(shopOrders, order => {
      let { date } = order;
      date = moment(date);
      if (date.isBefore(endHours, 'minutes') && date.isSameOrAfter(beginHours, 'minutes')) {
        nbOrdersInterval++;
      }
    });
    return nbOrdersInterval >= limit;
  }
  return false;
};
export const getOrderTypeLabel = orderType => {
  return orderType === ORDER_TYPE_DELIVERY
    ? ORDER_TYPE_DELIVERY_LABEL
    : orderType === ORDER_TYPE_CLICK_AND_COLLECT
    ? ORDER_TYPE_CLICK_AND_COLLECT_LABEL
    : ORDER_TYPE_CLICK_AND_SEAT_LABEL;
};
