import { addPromotionBackend } from '../../api';
import {
  sendCloudWatchLogs,
  isStringNotNull,
  renderErrorMessage,
  isObjectNotNull
} from '../../utils';
import store from '../../redux/store';
import { showMessageModal } from '../../redux/actions';
import { LOYALTY } from '../../constants';
import _ from 'lodash';

export const addLoyalty = async (component, loyalty) => {
  try {
    const { user } = store.getState().userReducer;
    const { customerId } = user;
    component.setState({ loadingPromotion: true });
    if (isStringNotNull(loyalty)) {
      sendCloudWatchLogs(`Adding loyalty ${loyalty}`);
      const deviceId = customerId;
      await addPromotionBackend(customerId, loyalty, deviceId);
      if (loyalty === LOYALTY)
        store.dispatch(showMessageModal(`Vos points de fidélité ont été consommés`));
      component.setState({ loadingPromotion: false });
    }
  } catch (error) {
    store.dispatch(showMessageModal(renderErrorMessage(error)));
    component.setState({ loadingPromotion: false });
    sendCloudWatchLogs(`Error adding loyalty ${error}`);
  }
};

export const getLoyalty = (loyaltyShop, loyaltyConfig) => {
  let newLoyalty = false;
  let isShopLoyalty = false;
  if (isObjectNotNull(loyaltyShop) && loyaltyShop.enabled) {
    if (loyaltyShop.limit && loyaltyShop.type && loyaltyShop.point) {
      newLoyalty = loyaltyShop;
      isShopLoyalty = true;
    } else if (isObjectNotNull(loyaltyConfig)) {
      newLoyalty = loyaltyConfig;
    }
  }
  return { newLoyalty, isShopLoyalty };
};

export const getCustomerLoyaltyPoints = (shopIds, shopId, userPoints) => {
  let newPoints = 0;
  if (shopId) {
    const shop = _.find(shopIds, shop => shop.shopId === shopId);
    if (shop && shop.points > 0) {
      newPoints = shop.points;
    }
  } else if (userPoints > 0) {
    newPoints = userPoints;
  }
  return newPoints;
};

export const getLoyaltyPercentage = (points, loyalty) => {
  let percentage = Math.round((points / loyalty.limit) * 100);
  if (percentage > 100) {
    percentage = 100;
  }
  return percentage;
};
