import React from 'react';
import { sendCloudWatchAlert } from '../../utils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    sendCloudWatchAlert(`Error Web App ${error}`);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <div />;
    }
    return children;
  }
}

export default ErrorBoundary;
