import axios from 'axios';
import store from '../redux/store';
import {
  API_ADD_PROMOTION,
  API_CREATE_CUSTOMER,
  API_UPDATE_PAYMENT_CARD_CUSTOMER,
  API_ADD_PAYMENT_CARD_CUSTOMER,
  API_DELETE_CARD_CUSTOMER,
  API_DELETE_CUSTOMER_ACCOUNT,
  API_GET_CUSTOMER,
  WEBAPP
} from '../constants';
import { getIdToken, getFirebaseCustomerId } from './firebase';
import { sendCloudWatchAlert } from '../utils';

export const initializeBackend = domain => {
  //  domain = 'http://localhost:3000';
  axios.defaults.baseURL = String(domain);
  return true;
};

export const sendPostRequest = async (url, data, headers) => {
  try {
    const { companyId } = store.getState().configurationReducer;
    const newData = { ...data, companyId };
    const response = await axios({
      method: 'post',
      url,
      data: newData,
      headers
    });
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.errorMessage : error;
    throw errorMessage;
  }
};

export const sendGetRequest = async (url, data, headers) => {
  try {
    const { companyId } = store.getState().configurationReducer;
    const newData = { ...data, companyId };
    const response = await axios({
      method: 'get',
      url,
      params: newData,
      headers
    });
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.errorMessage : error;
    throw errorMessage;
  }
};

export const addPromotionBackend = async (customerId, promoCode, deviceId) => {
  // const idToken = await getIdToken();
  // const headers = { Authorization: `Bearer ${idToken}` };
  const { shopId } = store.getState().userReducer.customerAddress;
  const data = {
    customerId,
    promoCode,
    deviceId,
    shopId,
    canal: WEBAPP
  };
  const response = await sendPostRequest(API_ADD_PROMOTION, data, {});
  return response;
};

export const createCustomerBackend = async customerProfile => {
  const { shopId } = store.getState().shopReducer;
  const data = {
    customerProfile,
    shopId
  };
  const customerId = getFirebaseCustomerId();
  let headers = {};
  if (customerId) {
    const idToken = await getIdToken();
    headers = { Authorization: `Bearer ${idToken}` };
  }
  const response = await sendPostRequest(API_CREATE_CUSTOMER, data, headers);
  return response;
};

export const getCustomerBackend = async () => {
  try {
    const customerId = getFirebaseCustomerId();
    if (customerId) {
      const data = {
        customerId
      };
      const idToken = await getIdToken();
      const headers = { Authorization: `Bearer ${idToken}` };
      const response = await sendGetRequest(API_GET_CUSTOMER, data, headers);
      return response.data;
    }
  } catch (error) {
    sendCloudWatchAlert(`Could not get customer backend ${error}`);
  }
};

export const updateCustomerPaymentCardBackend = async newCardId => {
  const { user } = store.getState().userReducer;
  const data = {
    newCardId,
    customerId: user.customerId
  };
  const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  await sendPostRequest(API_UPDATE_PAYMENT_CARD_CUSTOMER, data, headers);
};

export const addCustomerPaymentCardBackend = async newCard => {
  const { user } = store.getState().userReducer;
  const data = {
    newCard,
    customerId: user.customerId
  };
  const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  await sendPostRequest(API_ADD_PAYMENT_CARD_CUSTOMER, data, headers);
};

export const deleteCustomerPaymentCardBackend = async cardId => {
  const { user } = store.getState().userReducer;
  const data = {
    cardId,
    customerId: user.customerId
  };
  const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  await sendPostRequest(API_DELETE_CARD_CUSTOMER, data, headers);
};

export const deleteCustomerAccountBackend = async () => {
  const customerId = getFirebaseCustomerId();
  if (customerId) {
    sendCloudWatchAlert(`Delete user ${customerId}`);
    const { shopId } = store.getState().shopReducer;
    const data = {
      customerId,
      shopId
    };
    const idToken = await getIdToken();
    const headers = { Authorization: `Bearer ${idToken}` };
    await sendPostRequest(API_DELETE_CUSTOMER_ACCOUNT, data, headers);
  }
};
