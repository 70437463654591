import {
  isTerminalVertical,
  sendCloudWatchLogs,
  updateAddressAndShop,
  getClosestShops,
  checkIfShopAvailable
} from '../../utils';
import { removeDataFromTable } from '../../api';
import store from '../../redux/store';
import { resetCart } from '../../redux/actions';

export const getClasses = () => {
  const { isHorizontalSection } = store.getState().componentReducer;
  let listSectionClass = 'col-lg-2 col-4 col-md-3 mb-3 pr-4';
  let listProductClass = 'col-lg-10 col-8 col-md-9';
  if (isHorizontalSection) {
    listProductClass = 'col-12 p-0';
  }
  if (isTerminalVertical()) {
    listSectionClass = 'col-3 mb-3 pr-4';
    listProductClass = 'col-9';
  }
  return { listSectionClass, listProductClass };
};

export const displayOrNotHeader = displayNavBar => {
  const header = document.getElementsByTagName('header')[0];
  if (header) {
    if (displayNavBar) {
      header.style.visibility = 'hidden';
    } else {
      header.style.visibility = 'visible';
    }
  }
};

export const resetCartIfNeeded = () => {
  const { userConnected, isUserAnonymous, user } = store.getState().userReducer;
  sendCloudWatchLogs('Resetting cart due to changing shop');
  if (userConnected || isUserAnonymous) {
    const { customerId } = user;
    removeDataFromTable(`/users/${customerId}/pendingOrder/commande`);
  } else {
    store.dispatch(resetCart());
  }
};

export const updateShopFromLink = async (component, customerAddress) => {
  const { marketplace, updateShopIsLoaded, match, shopId } = component.props;
  const marketplaceEnabled = marketplace && marketplace.enabled;
  if (marketplaceEnabled) {
    const shopIdFromLink = match.params.shopId;
    if (customerAddress && shopIdFromLink && customerAddress.shopId !== shopIdFromLink) {
      if (shopId !== shopIdFromLink) {
        updateShopIsLoaded(false);
      }
      await updateAddressAndShop(shopIdFromLink, customerAddress, customerAddress.orderType);
    }
  }
};
