import React from 'react';
import _ from 'lodash';

class DayHoraire extends React.Component {
  render() {
    const { name, slots, displayTop } = this.props;
    return (
      <tr>
        <th
          className={`text-left align-middle ${!displayTop && 'border-top-0'}`}
        >
          {name}
        </th>
        <td
          className={`text-right align-middle ${!displayTop && 'border-top-0'}`}
        >
          {!_.isEmpty(slots)
            ? slots.map((slot, i) => (
                <p key={i} className='my-auto no-localization'>
                  {slot}
                </p>
              ))
            : 'fermé'}
        </td>
      </tr>
    );
  }
}

export default DayHoraire;
