import React from 'react';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import styles from './MobileAppSection.styles';
import { getTheme } from '../../utils';
import { PLAYSTORE_IMAGE_URL, APPLESTORE_IMAGE_URL, MOBILEAPP_IMAGE_URL } from '../../constants';

class PhoneSection extends React.Component {
  render() {
    const { webapp, appleStoreId, playStoreId, index } = this.props;
    const { images } = webapp;
    const { imageCoverUrl3 } = images;
    const { style, textColor } = getTheme(index, imageCoverUrl3);
    return (
      <section className={`${textColor} text-center`} style={style}>
        <div style={{ backgroundColor: index % 2 ? 'rgba(0,0,0,.8)' : 'white' }}>
          <div className='container'>
            <div className='row py-5 justify-content-center align-items-center'>
              <div className='col-sm-6 px-0 px-sm-4'>
                <img
                  className='img-fluid'
                  src={MOBILEAPP_IMAGE_URL}
                  alt='mobile app'
                  style={{ maxHeight: '400px' }}
                />
              </div>
              <div className='col-sm-6 p-0 p-sm-4'>
                <h1 className='mb-4 text-title'>Commandez sur notre application mobile</h1>
                <p className='mb-3'>
                  Retrouvez l'ensemble de notre menu et commandez sur notre application mobile
                  disponible sur Iphone et Android! Ne bougez pas de votre canapé, dégainez votre
                  téléphone et faites vous livrer directement chez vous
                </p>
                <div className='row'>
                  <div className='col-6 pr-3'>
                    <a
                      href={`https://apps.apple.com/fr/app/dishop/id${appleStoreId}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={APPLESTORE_IMAGE_URL} alt='appstore' className='img-fluid' />
                    </a>
                  </div>
                  <div className='col-6'>
                    <a
                      href={`https://play.google.com/store/apps/details?id=${playStoreId}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={PLAYSTORE_IMAGE_URL} alt='google-play' className='img-fluid' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ configurationReducer }) => {
  const { webapp, appleStoreId, playStoreId, name, chatbot } = configurationReducer;
  return {
    webapp,
    appleStoreId,
    playStoreId,
    name,
    chatbot
  };
};

export default connect(mapStateToProps)(PhoneSection);
