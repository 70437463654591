import { UNIT } from '../../constants/configuration';

const styles = {
  btnStyle: {
    borderRadius: UNIT * 2,
    fontSize: UNIT * 0.75,
    fontFamily: 'sans-serif',
    height: UNIT * 2.1875,
    fontWeight: 600
  },
  inputStyle: {
    backgroundColor: '#FBFBFB',
    border: '1px solid #F3F2F2',
    fontSize: UNIT * 0.875,
    color: '#868686'
  },
  broderStyle: {
    border: '1px solid #6A6A6A',
    margin: `${UNIT * 0.875}px 0px`
  },
  forgetPasswordStyle: {
    fontSize: UNIT * 0.75,
    color: 'black',
    textDecoration: 'underline',
    fontWeight: '500'
  },
  closeButton: {
    opacity: 1,
    fontWeight: 300,
    fontSize: 'xx-large'
  }
};

export default styles;