import {
  sendCloudWatchAlert,
  createCookieCustomer,
  resetTerminalTimeout,
  isTerminalVertical
} from '../../utils';
import store from '../../redux/store';
import { showOverlayCover, terminalUserConnectionCheck } from '../../redux/actions';

export const updateOrderType = async (component, orderType) => {
  try {
    component.setState({ loadingOrderType: true });
    const customerId = await createCookieCustomer(orderType);
    //  const customerId = 'cff62000-1da9-4400-b4c6-1a6c676ccadc';
    store.dispatch(terminalUserConnectionCheck(customerId));

    setTimeout(() => {
      store.dispatch(showOverlayCover(false));
      resetTerminalTimeout();
      component.setState({ loadingOrderType: false });
    }, 1000);
  } catch (error) {
    component.setState({ loadingOrderType: false });
    sendCloudWatchAlert(`Could not update order type from terminal ${error}`);
  }
};

export const getTerminalClasses = paymentTypes => {
  let containerPaymentTypeClass = '';
  let paymentTypeClass = '';
  if (paymentTypes.filter(Boolean).length % 2 === 0 && isTerminalVertical()) {
    containerPaymentTypeClass = 'col-10 m-auto';
    paymentTypeClass = 'col-5';
  } else {
    containerPaymentTypeClass = 'col-12';
    paymentTypeClass = 'col-4';
  }
  return { paymentTypeClass, containerPaymentTypeClass };
};

export const getTerminalStyles = styles => {
  const {
    textTerminalHorizontalStyle,
    textTermialVerticalStyle,
    iconTerminalVerticalStyle,
    iconTerminalHorizontalStyle,
    titleTerminalVerticalStyle,
    titleTerminalHorizontalStyle,
    cardTerminalHorizontalStyle,
    cardTerminalVerticalStyle,
    containerTitleTerminalVerticalStyle,
    containerTitleTerminalHorizontalStyle,
    backTextTerminalVerticalStyle,
    backTextTerminalHorizontalStyle
  } = styles;

  const titleTermialStyle = isTerminalVertical()
    ? titleTerminalVerticalStyle
    : titleTerminalHorizontalStyle;

  const textTermialStyle = isTerminalVertical()
    ? textTermialVerticalStyle
    : textTerminalHorizontalStyle;

  const iconTerminalStyle = isTerminalVertical()
    ? iconTerminalVerticalStyle
    : iconTerminalHorizontalStyle;

  const cardTerminalStyle = isTerminalVertical()
    ? cardTerminalVerticalStyle
    : cardTerminalHorizontalStyle;

  const containerTitleTerminalStyle = isTerminalVertical()
    ? containerTitleTerminalVerticalStyle
    : containerTitleTerminalHorizontalStyle;

  const backTextTerminalStyle = isTerminalVertical()
    ? backTextTerminalVerticalStyle
    : backTextTerminalHorizontalStyle;

  return {
    cardTerminalStyle,
    textTermialStyle,
    iconTerminalStyle,
    titleTermialStyle,
    containerTitleTerminalStyle,
    backTextTerminalStyle
  };
};
