import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Keyboard from 'react-virtual-keyboard';
import LocalDining from '@material-ui/icons/LocalDining';
import LocalMall from '@material-ui/icons/LocalMall';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Carousel from '../Carousel/Carousel.view';
import styles from './CoverOverlay.styles';
import { UNIT, KIOSK } from '../../constants/configuration';
import { updateOrderType, getTerminalStyles, getTerminalClasses } from './CoverOverlay.services';
import { showOverlayCover, showCartModal, updateEaselNumber } from '../../redux/actions';
import Spinner from '../Spinner';
import {
  getCreditCard,
  getPaymentTypeLabel,
  getPaymentTypes,
  checkOrder,
  resetTerminalTimeout,
  isTerminalVertical,
  isStringNotNull,
  isObjectNotNull
} from '../../utils';
import {
  START_CONTENT,
  CHOOSE_ORDER_TYPE_CONTENT,
  TERMINAL_ORDER_TYPES,
  END_CONTENT,
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_CLICK_AND_COLLECT,
  BLOCK_CONTENT,
  PAYMENT_CONTENT,
  CHOOSE_PAYMENT_TYPE_CONTENT,
  INPUT_EASEL_NUMBER_CONTENT,
  ERROR_MESSAGE_CONTENT,
  PAYMENT_TYPE_CASH,
  PAYMENT_TYPE_CB,
  PAYMENT_TYPE_TICKET_RESTO,
  PAYMENT_TYPE_COUNTER
} from '../../constants';
import Button from '../Button';

const INITIAL_STATE = {
  loading: false,
  loadingOrderType: false,
  dots: '',
  errorMessage: '',
  paymentType: null,
  choosePayment: false, // Ne sert à rien, c'est juste pour utiliser checkOrder
  easelNumber: null,
  isCarousel: false
};

const {
  textTermialStyle,
  iconTerminalStyle,
  cardTerminalStyle,
  containerTitleTerminalStyle,
  titleTermialStyle,
  backTextTerminalStyle
} = getTerminalStyles(styles);

const Icon = paymentType => {
  if (paymentType === PAYMENT_TYPE_CB) {
    return <CreditCardIcon className='text-secondary' style={iconTerminalStyle} />;
  }
  if (paymentType === PAYMENT_TYPE_COUNTER) {
    return <AccountBalanceIcon className='text-secondary' style={iconTerminalStyle} />;
  }
  if (paymentType === PAYMENT_TYPE_TICKET_RESTO) {
    return <ConfirmationNumberIcon className='text-secondary' style={iconTerminalStyle} />;
  }
  if (paymentType === PAYMENT_TYPE_CASH) {
    return <MonetizationOnIcon className='text-secondary' style={iconTerminalStyle} />;
  }
};

const BackButton = onClick => {
  return (
    <div className='row justify-content-center'>
      <Button
        type={KIOSK}
        style={
          isTerminalVertical()
            ? {
                position: 'absolute',
                bottom: '5vh',
                width: '250px',
                borderRadius: UNIT * 2
              }
            : {
                position: 'absolute',
                left: '3vh',
                top: '3vh'
              }
        }
      >
        <h2 className='text-secondary mb-1' style={backTextTerminalStyle}>
          Retour
        </h2>
      </Button>
    </div>
  );
};

class CoverOverlay extends React.Component {
  onInputChanged = data => {
    this.setState({ easelNumber: data });
  };

  onInputSubmitted = () => {
    const { showOverlayCover, updateEaselNumber } = this.props;
    const { easelNumber } = this.state;
    if (isStringNotNull(easelNumber)) {
      updateEaselNumber(easelNumber);
      showOverlayCover(CHOOSE_PAYMENT_TYPE_CONTENT);
    }
  };

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentWillMount() {
    if (isTerminalVertical()) {
      document.documentElement.style.setProperty('--keyboard-width', ''.concat(400, 'px'));
      document.documentElement.style.setProperty('--keyboard-key-fontsize', ''.concat(80, 'px'));
      document.documentElement.style.setProperty('--keyboard-input-fontsize', ''.concat(40, 'px'));
    }
  }

  componentDidMount() {
    setInterval(() => {
      let { dots } = this.state;
      if (dots.length === 3) {
        dots = '';
      } else {
        dots += '.';
      }
      this.setState({ dots });
    }, 500);
  }

  renderCaroussel = () => {
    const { sliding } = this.props;
    return <Carousel slides={sliding} />;
  };

  renderWelcomePage = () => {
    const { overlayCover, name } = this.props;
    return (
      <div className='text-white text-center text-center px-5 pt-5' style={styles.overlayCover}>
        <div
          className='row align-items-center justify-content-center'
          style={containerTitleTerminalStyle}
        >
          <h1 className='p-5 text-title' style={titleTermialStyle}>
            {overlayCover === CHOOSE_ORDER_TYPE_CONTENT || overlayCover === START_CONTENT
              ? 'Bienvenue chez '
              : ''}
            <span className='no-localization'>{name}</span>
          </h1>
        </div>
        <div
          className={`row align-items-top justify-content-center ${!isTerminalVertical() &&
            'px-5'}`}
        >
          {this.renderContent()}
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { overlayCover, showOverlayCover, showCartModal, orderTypes } = this.props;
    const { loadingOrderType, dots, paymentType } = this.state;
    if (loadingOrderType) {
      return (
        <div className='text-white'>
          <Spinner color='white' />
        </div>
      );
    }
    if (overlayCover === START_CONTENT) {
      return (
        <div>
          <h2 style={textTermialStyle}>Cliquez pour commencer</h2>
        </div>
      );
    }
    if (overlayCover === ERROR_MESSAGE_CONTENT) {
      const { errorMessage } = this.state;
      return (
        <div>
          <div>
            <h2
              style={textTermialStyle}
              className='px-5 text-break'
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          </div>
          <div
            onClick={() => {
              showOverlayCover(CHOOSE_PAYMENT_TYPE_CONTENT);
            }}
          >
            <BackButton />
          </div>
        </div>
      );
    }
    if (overlayCover === END_CONTENT) {
      const { orders } = this.props;
      let orderNumber = 0;
      _.map(orders, order => {
        if (orderNumber < order.orderNumber) {
          orderNumber = order.orderNumber;
        }
      });
      return (
        <div className='px-5'>
          {orderNumber > 0 && (
            <h1 className='pt-4' style={textTermialStyle}>
              {`Votre commande #${orderNumber} a bien été envoyée !`}
            </h1>
          )}
          <h1 style={textTermialStyle}>
            Merci pour votre commande et à très bientôt !{' '}
            <span role='img' aria-label='emoji'>
              😋 🍽
            </span>
          </h1>
          <Spinner color='white' />
        </div>
      );
    }
    if (overlayCover === BLOCK_CONTENT) {
      return (
        <div>
          <h2 className='p-4' style={textTermialStyle}>
            Vous n'avez pas accès à ce format.
          </h2>
          <h3 className='p-4' style={textTermialStyle}>
            Contactez-nous sur contact@dishop.co pour plus d'informations
          </h3>
        </div>
      );
    }
    if (overlayCover === PAYMENT_CONTENT) {
      return (
        <div>
          {paymentType === PAYMENT_TYPE_COUNTER && (
            <h2 className='px-4' style={textTermialStyle}>
              Envoi de votre commande en cours{dots}
            </h2>
          )}
          {paymentType !== PAYMENT_TYPE_COUNTER && (
            <h2 className='px-4' style={textTermialStyle}>
              En attente de confirmation de votre paiement{dots}
            </h2>
          )}

          <div className='pt-4'>
            <Spinner style={textTermialStyle} color='white' />
          </div>
        </div>
      );
    }

    if (overlayCover === CHOOSE_PAYMENT_TYPE_CONTENT) {
      const { user, terminal, customerAddress } = this.props;
      const { cards } = user;
      const { orderType } = customerAddress;
      const paymentTypes = getPaymentTypes();
      const { paymentTypeClass, containerPaymentTypeClass } = getTerminalClasses(paymentTypes);
      return (
        <div>
          <div
            className={`${isTerminalVertical() ? '' : 'row col-12 d-flex justify-content-center'}`}
          >
            <div className={containerPaymentTypeClass}>
              <h2 style={textTermialStyle} className='px-5'>
                Choisissez votre moyen de paiement:
              </h2>
              <div className='row justify-content-center'>
                {paymentTypes.map(paymentType => {
                  return (
                    <div className={paymentTypeClass}>
                      <Button
                        type={KIOSK}
                        className='button-terminal-vertical-size mt-5'
                        style={cardTerminalStyle}
                        onClick={async () => {
                          this.setState({ paymentType });
                          showOverlayCover(PAYMENT_CONTENT);
                          const card = getCreditCard(cards);
                          checkOrder(this, paymentType, card);
                        }}
                      >
                        <h2 className='text-secondary pb-2' style={textTermialStyle}>
                          {getPaymentTypeLabel(paymentType)}
                        </h2>
                        <div>{Icon(paymentType)}</div>
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              if (
                (terminal.easel && orderType === ORDER_TYPE_CLICK_AND_SEAT) ||
                (terminal.easelCC && orderType === ORDER_TYPE_CLICK_AND_COLLECT)
              ) {
                showOverlayCover(INPUT_EASEL_NUMBER_CONTENT);
              } else {
                showCartModal(true);
                showOverlayCover(false);
              }
            }}
          >
            <BackButton />
          </div>
        </div>
      );
    }

    if (overlayCover === INPUT_EASEL_NUMBER_CONTENT) {
      const { easelNumber } = this.state;
      return (
        <div>
          <div className='col align-items-center'>
            <h2 className='mb-5 label' style={textTermialStyle}>
              Entrez votre numéro de beeper :
            </h2>
            <Keyboard
              value={easelNumber}
              name='keyboardv'
              options={{
                type: 'input',
                layout: 'custom',
                customLayout: {
                  normal: ['7 8 9', '4 5 6', '1 2 3', '{b} 0 {a}']
                },
                alwaysOpen: true,
                usePreview: false,
                useWheel: false,
                appendLocally: true,
                updateOnChange: true,
                initialFocus: true,
                maxLength: 3,
                maxInsert: false,
                css: {
                  // input & preview
                  input: 'form-control input-sm text-center',
                  // keyboard container
                  container: 'dropdown-menu text-center',
                  // default state
                  buttonDefault: 'btn btn-default',
                  // hovered button
                  buttonHover: 'btn-secondary'
                }
              }}
              onChange={this.onInputChanged}
              onAccepted={this.onInputSubmitted}
            />
          </div>
          <div
            onClick={() => {
              showCartModal(true);
              showOverlayCover(false);
            }}
          >
            <BackButton />
          </div>
        </div>
      );
    }
    return (
      <div className='text-white'>
        <div
          onClick={() => {
            showOverlayCover(START_CONTENT);
          }}
        >
          <BackButton />
        </div>
        <h2 className='mb-5 px-5' style={textTermialStyle}>
          Où souhaitez vous manger ?
        </h2>
        <div className='row justify-content-around'>
          {orderTypes.includes(ORDER_TYPE_CLICK_AND_SEAT) && (
            <Button
              type={KIOSK}
              className='mr-5 button-terminal-vertical-size'
              style={cardTerminalStyle}
              onClick={() => {
                updateOrderType(this, ORDER_TYPE_CLICK_AND_SEAT);
              }}
            >
              <h2 className='pb-2 text-secondary' style={textTermialStyle}>
                Sur Place
              </h2>
              <div>
                <LocalDining className='text-secondary' style={iconTerminalStyle} />
              </div>
            </Button>
          )}
          {orderTypes.includes(ORDER_TYPE_CLICK_AND_COLLECT) && (
            <Button
              type={KIOSK}
              className='ml-5 button-terminal-vertical-size'
              style={cardTerminalStyle}
              onClick={() => {
                updateOrderType(this, ORDER_TYPE_CLICK_AND_COLLECT);
              }}
            >
              <h2 className='pb-2 text-secondary' style={textTermialStyle}>
                À Emporter
              </h2>
              <div>
                <LocalMall className='text-secondary' style={iconTerminalStyle} />
              </div>
            </Button>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      webapp,
      overlayCover,
      secondaryColor,
      showOverlayCover,
      sliding,
      orderTypes
    } = this.props;
    const { loadingOrderType } = this.state;
    const { images } = webapp;
    let image = images.imageCoverOrderUrl;
    if (isObjectNotNull(sliding)) {
      image = sliding[0];
    }

    const hideCaroussel =
      (overlayCover === START_CONTENT && !isObjectNotNull(sliding)) ||
      overlayCover !== START_CONTENT ||
      loadingOrderType;
    return (
      <div>
        <div
          className='overlay-cover'
          style={{
            backgroundColor: hideCaroussel ? secondaryColor : null,
            backgroundImage: hideCaroussel ? `url(${image})` : null,
            backgroundSize: '100% 100%',
            cursor: 'pointer'
          }}
          onClick={() => {
            if (overlayCover === START_CONTENT) {
              const hasMultipleOrderTypes = _.compact(orderTypes).length > 1;
              if (hasMultipleOrderTypes) {
                showOverlayCover(CHOOSE_ORDER_TYPE_CONTENT);
              } else {
                const orderType = _.compact(orderTypes)[0];
                updateOrderType(this, orderType);
              }
            } else {
              resetTerminalTimeout();
            }
          }}
        >
          {!hideCaroussel &&
            overlayCover === START_CONTENT &&
            isObjectNotNull(sliding) &&
            this.renderCaroussel()}
          {hideCaroussel && this.renderWelcomePage()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  componentReducer,
  configurationReducer,
  orderReducer,
  userReducer,
  shopReducer
}) => {
  const { overlayCover } = componentReducer;
  const { name, webapp, secondaryColor, terminal } = configurationReducer;
  const { shopConfiguration = {} } = shopReducer;
  const { terminal: shopTerminal = {} } = shopConfiguration;
  const { orders } = orderReducer;
  const { user, customerAddress } = userReducer;
  return {
    overlayCover,
    name,
    webapp,
    sliding: shopTerminal.sliding,
    orderTypes: shopTerminal.orderTypes || TERMINAL_ORDER_TYPES,
    secondaryColor,
    orders,
    user,
    terminal,
    customerAddress
  };
};

export default connect(mapStateToProps, { showOverlayCover, showCartModal, updateEaselNumber })(
  CoverOverlay
);
