const styles = {
  overlayCover: {
    backgroundColor: `rgba(0,0,0,.4)`,
    minWidth: '100%',
    height: '100%'
  },
  container: {
    backgroundColor: 'white',
    textAlign: 'left'
  }
};

export default styles;
