import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import DayHoraire from '../DayHoraire';
import { ORDER_TYPE_DELIVERY } from '../../../constants';
import { sortOpenHours } from './Horaires.services';
import { sortSlots } from '../../../utils';

class Horaires extends React.Component {
  renderHours = () => {
    const { hours, customerAddress } = this.props;
    if (hours) {
      const sortedOpenHours = sortOpenHours(hours);
      return (
        <tbody>
          {_.map(sortedOpenHours, (openHour, key) => {
            const { name, slots, slotsDelivery } = openHour;
            const selectedSlots =
              customerAddress && customerAddress.orderType === ORDER_TYPE_DELIVERY && slotsDelivery
                ? slotsDelivery
                : slots;
            const sortedSlots = sortSlots(selectedSlots, moment(key));
            return (
              <DayHoraire
                name={name}
                slots={sortedSlots}
                key={name}
                displayTop={sortedOpenHours[0] !== openHour}
              />
            );
          })}
        </tbody>
      );
    }
  };

  render() {
    return (
      <div>
        <table className='table dark-modal' style={{ margin: 'auto', width: '80%' }}>
          {this.renderHours()}
        </table>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer }) => {
  const { customerAddress } = userReducer;
  return { customerAddress };
};

export default connect(mapStateToProps, {})(Horaires);
