import _ from 'lodash';
import {
  sendCloudWatchLogs,
  getNbInCartOfProduct,
  getOptionAssociatedProduct,
  getStockRemaining,
  getCurrentNbOption,
  getProductUnits
} from '../../../utils';

export const getStockProductName = component => {
  const { product } = component.props;
  let { name } = product;
  const { items } = component.state;
  _.map(items, item => {
    if (item) {
      _.map(item.options, option => {
        name += ` ${option.name}`;
      });
    }
  });
  return name;
};

export const getMinimumStockRemaining = component => {
  let result = {};
  const { items, nb, unit = false } = component.state;
  const { product } = component.props;
  let productStock = product.stock;
  let minimumStock = false;
  _.map(items, category => {
    if (category) {
      _.map(category.options, option => {
        const product = getOptionAssociatedProduct(option);
        if (product) {
          const optionStock = product.stock;
          const nbOption = getCurrentNbOption(items, option.key);
          const nbInCart = getNbInCartOfProduct(option.key, option.productKey);
          let stockRemaining = getStockRemaining(optionStock, nbInCart, nb * nbOption);
          if (
            stockRemaining > 0 &&
            getStockRemaining(optionStock, nbInCart, (nb + 1) * nbOption) < 0
          ) {
            stockRemaining = 0;
          }
          if (
            option.key &&
            parseInt(stockRemaining, 10) >= 0 &&
            (minimumStock === false || stockRemaining < minimumStock)
          ) {
            minimumStock = stockRemaining;
            result = {
              stock: optionStock,
              name: option.name,
              key: option.key,
              stockRemaining
            };
          }
        }
      });
    }
  });
  let nbInCart = getNbInCartOfProduct(product.key);
  if (unit) {
    productStock *= unit.conversion;
    nbInCart = 0;
  }
  const stockRemaining = getStockRemaining(productStock, nbInCart, nb);
  if (
    product.key &&
    Number.isInteger(stockRemaining) &&
    (minimumStock === false || stockRemaining < minimumStock)
  ) {
    result = { stock: productStock, name: product.name, key: product.key, stockRemaining };
  }
  return result;
};

export const addCategories = (component, categoriesToAdd, categoryId) => {
  // add subcategories from checked item
  sendCloudWatchLogs(`Categories to add: ${categoriesToAdd}`);
  const { shopCategories } = component.props;
  const newCategories = [...component.state.productCategories];
  let categoryToAddPosition = 0;
  _.find(newCategories, (category, index) => {
    if (category === categoryId) {
      categoryToAddPosition = index + 1;
      return true;
    }
  });

  _.map(categoriesToAdd, categoryToAdd => {
    newCategories.splice(categoryToAddPosition, 0, categoryToAdd);
    categoryToAddPosition++;
  });
  component.setState({
    productCategories: getProductCategories(shopCategories, newCategories),
    mandatoryCategories: getProductMandatoryCategories(shopCategories, newCategories)
  });
};

export const removeCategories = async (component, categoriesToRemove) => {
  sendCloudWatchLogs(`Categories to remove: ${categoriesToRemove}`);
  const { shopCategories } = component.props;
  let newCategories = [...component.state.productCategories];
  _.map(categoriesToRemove, categoryToRemove => {
    newCategories = newCategories.filter(value => {
      return value !== categoryToRemove;
    });
  });
  await component.setState({
    productCategories: getProductCategories(shopCategories, newCategories),
    mandatoryCategories: getProductMandatoryCategories(shopCategories, newCategories)
  });
};

export const updateSubCategories = async (component, categoryId, newItem) => {
  const { items } = component.state;
  let categoriesToRemove = [];
  const categoriesToAdd = newItem.subcategories ? newItem.subcategories.split(',') : [];
  if (items) {
    // Get categories to remove
    const productItemsWithSubCategoriesToRemove = [];
    let productItemsWithSubCategoryToRemove = _.find(items, productItem => {
      return productItem && productItem.categoryId === categoryId;
    });
    if (productItemsWithSubCategoryToRemove) {
      productItemsWithSubCategoriesToRemove.push(productItemsWithSubCategoryToRemove);
      for (let i = 0; i < productItemsWithSubCategoriesToRemove.length; i++) {
        const productItem = productItemsWithSubCategoriesToRemove[i];
        if (productItem) {
          _.map(productItem.options, option => {
            if (option.subcategories) {
              const subCategories = option.subcategories.split(',');
              categoriesToRemove = _.concat(categoriesToRemove, subCategories);
              _.map(subCategories, subCategory => {
                productItemsWithSubCategoryToRemove = _.find(items, productItem => {
                  return productItem && productItem.categoryId === subCategory;
                });
                if (productItemsWithSubCategoryToRemove) {
                  productItemsWithSubCategoriesToRemove.push(productItemsWithSubCategoryToRemove);
                }
              });
            }
          });
        }
      }
    }
  }
  if (categoriesToRemove.length > 0) {
    await removeCategories(component, categoriesToRemove);
  }
  if (categoriesToAdd) {
    await addCategories(component, categoriesToAdd, categoryId);
  }

  const { productCategories } = component.state;
  // Rearrange product items after updating categories
  // !categoriesToRemove.includes(productItem.categoryId) =>
  // check if selected choice is not within the categories that has been removed & readded
  // otherwise it will be selected without its subcategories
  const newProductItems = [];
  _.map(productCategories, (productCategoryId, categoryPosition) => {
    const productItemFound = _.find(items, productItem => {
      return (
        productItem &&
        productItem.categoryId === productCategoryId &&
        productItem.categoryId !== categoryId &&
        !categoriesToRemove.includes(productItem.categoryId)
      );
    });
    if (productItemFound) {
      newProductItems[categoryPosition] = productItemFound;
    }
  });
  return newProductItems;
};

export const isNextCategoryDisabled = component => {
  const { items, mandatoryCategories, stepOptionIndex, productCategories } = component.state;
  const { shopCategories } = component.props;
  const categoriesLength = productCategories.length + 1;
  const mandatoryCategory = _.find(mandatoryCategories, mCategory => {
    return mCategory === stepOptionIndex;
  });
  if (mandatoryCategory >= 0 && !items[mandatoryCategory]) {
    return true;
  }
  const categoryId = productCategories[mandatoryCategory];
  if (categoryId) {
    const categoryKey = categoryId.split('@')[0];
    const category = shopCategories[categoryKey];
    if (
      category &&
      category.max > 1 &&
      category.multiple &&
      items[mandatoryCategory] &&
      _.size(items[mandatoryCategory].options) < category.max
    ) {
      return true;
    }
  }
  if (stepOptionIndex + 1 === categoriesLength) {
    return 'lastItem';
  }
  return false;
};

export const handlePreviousOption = component => {
  const { stepOptionIndex } = component.state;
  component.setState({
    stepOptionIndex: stepOptionIndex - 1
  });
};

export const handleNextOption = component => {
  const { stepOptionIndex } = component.state;
  component.setState({
    stepOptionIndex: stepOptionIndex + 1
  });
};

export const getProductCategories = (shopCategories = {}, categories) => {
  const newCategories = [];
  _.map(categories, category => {
    const categoryKey = category.split('@')[0];
    const categorySettings = shopCategories[categoryKey];
    if (categorySettings) {
      newCategories.push(category);
    }
  });
  return newCategories;
};

export const getProductMandatoryCategories = (shopCategories, categories) => {
  const newCategories = getProductCategories(shopCategories, categories);
  const mandatoryCategories = [];
  _.map(newCategories, (categoryId, categoryIndex) => {
    const categoryKey = categoryId.split('@')[0];
    const categorySettings = shopCategories[categoryKey];
    if (
      categorySettings &&
      categorySettings.type === 'radiobutton' &&
      !mandatoryCategories.includes(categoryIndex)
    ) {
      mandatoryCategories.push(categoryIndex);
    }
  });
  return mandatoryCategories;
};

export const getProductData = (shopCategories, product, categories) => {
  let { unit = null } = product;
  const units = getProductUnits(unit);
  if (units) {
    unit = units[0];
  }
  const productCategories = getProductCategories(shopCategories, categories);
  const mandatoryCategories = getProductMandatoryCategories(shopCategories, categories);
  return { productCategories, mandatoryCategories, unit, nb: unit ? 0 : 1 };
};
