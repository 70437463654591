import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import sectionReducer from './sectionReducer';
import productsReducer from './productsReducer';
import shopReducer from './shopReducer';
import userReducer from './userReducer';
import cartReducer from './cartReducer';
import configurationReducer from './configurationReducer';
import orderReducer from './orderReducer';
import componentReducer from './componentReducer';
import driverReducer from './driverReducer';

export default combineReducers({
  sectionReducer,
  productsReducer,
  shopReducer,
  form: formReducer,
  userReducer,
  cartReducer,
  configurationReducer,
  componentReducer,
  orderReducer,
  driverReducer
});
