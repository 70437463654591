import { updateAddressAndShop } from '../../../utils';

export const updateSelectedShop = async (component, customerAddress) => {
  // const { updateShop, updateShopIsLoaded, shopIndex } = component.props;
  // if (customerAddress.shopId !== shopIndex) {
  //   updateShopIsLoaded(false);
  //   await updateAddressAndShop(shopIndex, customerAddress, customerAddress.orderType);
  //   await updateShop(customerAddress);
  // }
};
