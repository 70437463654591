import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { showSignUp, showForgetPassword ,resetEmail} from '../../redux/actions';
import { renderErrorMessage } from '../../utils';
import Spinner from '../Spinner';
import { COLOR_FACEBOOK } from '../../constants';
import Button from '../Button';
import { UNIT, PRIMARY, LINK, TEXT_COLOR, SECONDARY } from '../../constants/configuration';
import { isUserHasAccount } from './ConnexionForm.services';
import styles from './ConnexionForm.styles';

const INITIAL_STATE = {};

class ConnexionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderInput = ({ input, type, placeholder, meta }) => {
    const { email } = this.props;
    const className = `form-control dark-modal ${meta.error && meta.touched ? 'is-invalid' : ''}`;
    return (
      <div className='form-group mb-0'>
        <input
          {...input}
          type={type}
          placeholder={placeholder}
          className={className}
          readOnly={email && type === 'email'}
          value={email && type === 'email' ? email : input.value}
          autoFocus={(type === 'password' && input.value !== '') || (type === 'email' && email)}
          style={{
            ...styles.inputStyle,
            marginTop: !email && UNIT * 0.5,
            marginBottom: !email && UNIT * 1.4375
          }}
        />
        <div className='invalid-feedback'>{meta.error}</div>
      </div>
    );
  };

  onSubmit = formValues => {
    const { onSubmit } = this.props;
    onSubmit(formValues.email, formValues.password);
  };

  onSubmitEmail = ({ email }) => {
    isUserHasAccount(email);
  };

  facebookConnect = () => {
    const { facebookSignIn } = this.props;
    facebookSignIn();
  };

  googleConnect = () => {
    const { googleSignIn } = this.props;
    googleSignIn();
  };
  renderBackButton = () => {
    const {email} = this.props;
    if (email)
    return (
       <Button
            type={SECONDARY}
            style={{ borderRadius: UNIT * 2, fontSize: UNIT * 0.875 }}
            className='btn-block mt-2'
            onClick={() => {
              showSignUp(false);
              resetEmail();
            }}
          >
            Retour
          </Button>
    );
  };  
  render() {
    const {
      handleSubmit,
      errorMessage,
      loadingConnexion,
      showForgetPassword,
      facebook,
      google,
      email
    } = this.props;
    const { auth } = google;
    return (
      <>
        <form onSubmit={handleSubmit(!email ? this.onSubmitEmail : this.onSubmit)}>
          {errorMessage !== '' && <p className='text-danger'>{renderErrorMessage(errorMessage)}</p>}
          <Field
            name='email'
            type='email'
            placeholder='Adresse E-mail'
            component={this.renderInput}
          />

          <div className='mb-3' />
          {email && (
            <Field
              name='password'
              type='password'
              placeholder='Mot de passe'
              component={this.renderInput}
            />
          )}
          {loadingConnexion && (
            <div className='mt-4'>
              <Spinner />
            </div>
          )}
          {!loadingConnexion && email && (
            <div>
              <Button
                type={LINK}
                className='p-0 label'
                style={{
                  ...styles.forgetPasswordStyle
                }}
                onClick={() => {
                  showForgetPassword(true);
                }}
              >
                Mot de passe oublié ?
              </Button>
            </div>
          )}
          {!loadingConnexion && (
            <>
              <Button
                type={PRIMARY}
                isSubmit
                className='btn-block mt-3'
                style={{
                  ...styles.btnStyle,
                  margin: `${UNIT * 0.5}px 0px`
                }}
              >
                Continuer
              </Button>
              {this.renderBackButton()}
              {(auth || facebook.auth) && (
              <div className='d-flex p-2 align-items-center' style={{ marginBottom: UNIT * 0.5 }}>
                <div className='hrStyle' style={{ marginLeft: -(UNIT * 0.375) }} />
                <span className='label labelStyle'>ou</span>
                <div className='hrStyle' style={{ marginRight: -(UNIT * 0.375) }} />
              </div>
                )}
            </>
          )}
        </form>
        {!loadingConnexion && facebook.auth && (
          <Button
            className='text-white btn-block mt-2 dark-modal border-color'
            style={{
              ...styles.btnStyle,
              ...styles.broderStyle
            }}
            onClick={this.facebookConnect}
          >
            <div>
              <i
                className='fab fa-facebook-f'
                style={{
                  fontSize: UNIT * 0.875,
                  color: COLOR_FACEBOOK
                }}
              />

              <span
                className='text-center label'
                style={{ marginLeft: UNIT * 0.4375, color: TEXT_COLOR }}
              >
                {' '}
                Connexion avec Facebook
              </span>
            </div>
          </Button>
        )}
        {!loadingConnexion && auth && (
          <Button
            className='text-white btn-block mt-2 dark-modal border-color'
            onClick={this.googleConnect}
            style={{
              ...styles.btnStyle,
              ...styles.broderStyle
            }}
          >
            <div>
              <i className='fab fa-google' style={{ fontSize: UNIT * 0.875 }} />

              <span
                className='text-center label'
                style={{ marginLeft: UNIT * 1.3125, color: TEXT_COLOR }}
              >
                Connexion avec Google
              </span>
            </div>
          </Button>
        )}
      </>
    );
  }
}

const validate = formValues => {
  const errors = {};
  if (!formValues.email) {
    errors.email = 'Merci de fournir un mail';
  }

  if (!formValues.password) {
    errors.password = 'Merci de fournir un mot de passe';
  }

  return errors;
};

const mapStateToProps = ({ configurationReducer, userReducer }) => {
  const { loadingConnexion, email } = userReducer;
  const { secondaryColor, facebook, google } = configurationReducer;
  return { secondaryColor, loadingConnexion, facebook, google, email };
};

ConnexionForm = connect(mapStateToProps, { showSignUp, showForgetPassword, resetEmail })(ConnexionForm);

export default reduxForm({
  form: 'connexionForm',
  validate
})(ConnexionForm);