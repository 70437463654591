import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { showForgetPassword } from '../../redux/actions';
import { renderErrorMessage } from '../../utils';
import Spinner from '../Spinner';
import Button from '../Button';
import { PRIMARY, SECONDARY, UNIT } from '../../constants/configuration';
import styles from './ForgetPassword.style';

class ForgetPassword extends React.Component {
  renderInput = ({ input, type, placeholder, meta, description }) => {
    const className = `form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`;
    return (
      <div className='form-group'>
        <input
          type={type}
          className={`${className} dark-modal`}
          placeholder={placeholder}
          {...input}
          style={{ ...styles.inputStyle }}
        />
        <small className='form-check-label'>{description}</small>
        <div className='invalid-feedback'>{meta.error}</div>
      </div>
    );
  };

  onSubmit = ({ email }) => {
    const { onSubmit } = this.props;
    onSubmit(email);
  };

  render() {
    const { showForgetPassword, errorMessage, handleSubmit, loadingResetPassword } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {errorMessage !== '' && <p className='text-danger'>{renderErrorMessage(errorMessage)}</p>}
        <Field
          name='email'
          type='email'
          placeholder='email@gmail.com'
          component={this.renderInput}
        />
        {loadingResetPassword && (
          <div className='mt-4'>
            <Spinner />
          </div>
        )}
        {!loadingResetPassword && (
          <Button
            type={PRIMARY}
            isSubmit
            className='btn-block mt-3'
            style={{ borderRadius: UNIT * 2, fontSize: UNIT * 0.875 }}
          >
            Réinitialiser
          </Button>
        )}
        {!loadingResetPassword && (
          <Button
            type={SECONDARY}
            className='btn-block mt-2'
            onClick={() => {
              showForgetPassword(false);
            }}
            style={{ borderRadius: UNIT * 2, fontSize: UNIT * 0.875 }}
          >
            Retour
          </Button>
        )}
      </form>
    );
  }
}

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validate = formValues => {
  const errors = {};

  if (!formValues.email) {
    errors.email = 'Merci de fournir un mail';
  } else if (!validateEmail(formValues.email)) {
    errors.email = 'Veuillez fournir un mail valide';
  }

  return errors;
};

const mapStateToProps = ({ configurationReducer, userReducer }) => {
  const { secondaryColor, name } = configurationReducer;
  const { loadingResetPassword } = userReducer;
  return { secondaryColor, name, loadingResetPassword };
};

ForgetPassword = connect(mapStateToProps, { showForgetPassword })(ForgetPassword);

export default reduxForm({
  form: 'signupForm',
  validate
})(ForgetPassword);