import React from 'react';
import { connect } from 'react-redux';
import { getTheme } from '../../utils';

class DescriptionSection extends React.Component {
  renderDesign0 = textColor => {
    const { webapp, name } = this.props;
    const { imageDescriptionLeft, imageDescriptionRight } = webapp.images;
    return (
      <div className='container py-5'>
        <div className='row'>
          <div className='col-lg-4 d-none d-lg-block text-center' style={{ alignSelf: 'center' }}>
            <img
              className='img-fluid'
              src={imageDescriptionLeft}
              style={{ maxHeight: '300px' }}
              alt={name}
            />
          </div>
          <div className='col-lg-4 text-center'>
            <div className='mb-4'>
              <h1 className='text-title'>{name}</h1>
            </div>
            <div className='mb-5'>
              <h5 className={`m-3 ${textColor === 'text-white' ? textColor : 'text-muted'}`}>
                {webapp.descriptionTitle}
              </h5>
            </div>
            <p>{webapp.description}</p>
          </div>
          <div className='col-lg-4 d-none d-lg-block text-center' style={{ alignSelf: 'center' }}>
            <img
              className='img-fluid'
              src={imageDescriptionRight}
              style={{ maxHeight: '300px' }}
              alt={name}
            />
          </div>
        </div>
        <div className='row d-lg-none'>
          <div className='col-6'>
            <img className='img-fluid' src={imageDescriptionLeft} />
          </div>
          <div className='col-6'>
            <img className='img-fluid' src={imageDescriptionRight} />
          </div>
        </div>
      </div>
    );
  };

  renderDesign1 = textColor => {
    const { webapp, name } = this.props;
    const { imageDescriptionRight } = webapp.images;
    return (
      <div className='container'>
        <div className='row py-5 justify-content-center align-items-center'>
          <div className='col-lg-6 px-0 px-sm-4'>
            <div className='mb-2'>
              <h1 className='text-title'>{name}</h1>
            </div>
            <div>
              <h5 className={`m-3 ${textColor === 'text-white' ? textColor : 'text-muted'}`}>
                {webapp.descriptionTitle}
              </h5>
            </div>
            <p>{webapp.description}</p>
          </div>
          <div className='col-lg-6 px-0 px-sm-4'>
            <img
              className='img-fluid'
              src={imageDescriptionRight}
              style={{ maxHeight: '400px', alignSelf: 'center' }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderDesign = textColor => {
    const { webapp } = this.props;
    const { landingDesign } = webapp;
    switch (landingDesign) {
      case 0:
        return this.renderDesign0(textColor);
      case 1:
        return this.renderDesign1(textColor);
      default:
        return this.renderDesign0(textColor);
    }
  };

  render() {
    const { index } = this.props;
    const { webapp } = this.props;
    const { imageCoverUrl3 } = webapp.images;
    const { style, textColor } = getTheme(index, imageCoverUrl3);
    return (
      <section className={`${textColor} text-center`} style={style}>
        <div style={{ backgroundColor: index % 2 ? 'rgba(0,0,0,.8)' : 'white' }}>
          {this.renderDesign(textColor)}
        </div>
      </section>
    );
  }
}
const mapStateToProps = ({ configurationReducer }) => {
  const { webapp, name } = configurationReducer;
  return {
    webapp,
    name
  };
};

export default connect(mapStateToProps, {})(DescriptionSection);
