import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { connect } from 'react-redux';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import RestaurantOutlinedIcon from '@material-ui/icons/RestaurantOutlined';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import StoreIcon from '@material-ui/icons/Store';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import _ from 'lodash';
import NearMeIcon from '@material-ui/icons/NearMe';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import Spinner from '../Spinner';
import styles from './AddressInput.styles';
import {
  WRONG_ADDRESS,
  UNAVAILABLE_ADDRESS,
  PLACEHOLDER_TEXT,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_CLICK_AND_COLLECT,
  DEFAULT_TEXT,
  ORDER_TYPE_DELIVERY_LABEL,
  ORDER_TYPE_CLICK_AND_COLLECT_LABEL,
  ORDER_TYPE_CLICK_AND_SEAT_LABEL
} from '../../constants';
import {
  changeSwitchValue,
  onChooseShop,
  handleClick,
  handleChange,
  handleError,
  handleSelect,
  handleOpen,
  updateAddressChoice,
  getShopsFromAdress,
  animatePlaceHolderText,
  renderAddress,
  getShopsIfInvalidAddress
} from './AddressInput.services';

import { UNIT, PRIMARY } from '../../constants/configuration';
import store from '../../redux/store';
import {
  showMessageModal,
  showChooseShop,
  updateClosestShops,
  updateAddressTemp,
  showAddressModal,
  showConnexionModal
} from '../../redux/actions';
import Button from '../Button';
import { getOrderTypeLabel } from '../../utils/order';

const INITIAL_STATE = {
  loading: false,
  address: '',
  errorMessage: '',
  placeId: null,
  selected: 0,
  selectedShop: '',
  selectedAddress: '1',
  openMenu: false,
  shopName: DEFAULT_TEXT,
  showShops: false,
  validAddress: false,
  loadClosestShops: false,
  animatePlaceholder: PLACEHOLDER_TEXT,
  isCustomerAddress: false,
  deliveryZone: [],
  animation: false
};

const searchOptions = {
  componentRestrictions: { country: ['fr', 'mc', 'gf'] }
};

class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount = async () => {
    const { customerAddress, orderTypes = [], shopName, marketplace } = this.props;
    const { animatePlaceholder, selected } = this.state;
    const { delivery = {} } = store.getState().configurationReducer;
    const { chooseShop } = delivery;
    const marketplaceEnabled = marketplace && marketplace.enabled;
    const { orderType } = customerAddress || {};
    this.setState({
      animatePlaceholder:
        orderTypes[selected] === ORDER_TYPE_DELIVERY
          ? PLACEHOLDER_TEXT
          : `${PLACEHOLDER_TEXT}(facultatif)`
    });
    if (this.inputElement) {
      animatePlaceHolderText(this.inputElement, animatePlaceholder, 200);
    }
    if (customerAddress && orderTypes.indexOf(orderType) > -1) {
      this.setState({
        selected: orderTypes.indexOf(orderType) || 0,
        validAddress: true,
        showShops:
          !marketplaceEnabled &&
          ((customerAddress.orderType === ORDER_TYPE_DELIVERY && chooseShop) ||
            customerAddress.orderType !== ORDER_TYPE_DELIVERY),
        selectedAddress: '0',
        shopName,
        address: await renderAddress(this)
      });
      await getShopsFromAdress(this, orderType);
    } else getShopsIfInvalidAddress(this, orderTypes[0], marketplaceEnabled);
  };

  toggleHover = id => {
    const { secondaryColor } = this.props;
    document.getElementById(id).style.backgroundColor = secondaryColor;
    document.getElementById(id).style.borderRadius = '0px';
  };

  toggleLeave = id => {
    document.getElementById(id).style.backgroundColor = 'transparent';
  };

  renderShops = wrongAddress => {
    const { closestShops } = this.props;
    const { openMenu = false, shopName, message, animation } = this.state;
    return (
      <div>
        <Button
          type={PRIMARY}
          className={`btn dark-modal  ${
            !openMenu && !wrongAddress && message ? 'border-red' : 'border-color'
          } ${openMenu ? 'borderMenu menuStyle' : 'borderStyle'}`}
          style={{
            border: !openMenu && '1px solid black',
            borderRadius: openMenu && '0px',
            ...styles.menuStyle,
            ...styles.row
          }}
          onClick={() => handleOpen(this)}
        >
          <div style={styles.row}>
            <StoreIcon className=' label' style={styles.startIcon} />
            <p className='label' style={styles.inputText}>
              {shopName}
            </p>
          </div>
          <div>
            {!openMenu ? (
              <ExpandMoreIcon className='label' />
            ) : (
              <ExpandLessIcon className='label' />
            )}
          </div>
        </Button>
        {animation && (
          <ul
            className={`menu ${openMenu ? 'animationOpen' : 'animationClose'}`}
            style={{
              marginBottom: 0,
              overflowY: closestShops.length <= 2 && 'hidden'
            }}
          >
            {_.keys(closestShops).map((key, i) => {
              const shop = closestShops[key];
              const { name, distance } = shop;
              return (
                <li
                  key={i}
                  className='menu-item'
                  onMouseEnter={() => this.toggleHover(name)}
                  onMouseLeave={() => this.toggleLeave(name)}
                >
                  <Button
                    id={name}
                    type={PRIMARY}
                    style={{
                      ...styles.row,
                      justifyContent: 'space-between',
                      color: 'black!important',
                      backgroundColor: 'transparent'
                    }}
                    onClick={() => handleClick(shop, name, this)}
                  >
                    <div style={{ ...styles.row }}>
                      <StoreIcon className='label' style={styles.startIcon} />
                      <p
                        className='label'
                        style={{
                          ...styles.truncText,
                          ...styles.inputText
                        }}
                      >
                        {name}
                      </p>
                    </div>
                    {distance >= 0 && (
                      <div className=' lightcolor' style={styles.row}>
                        <NearMeIcon
                          style={{
                            fontSize: 'medium'
                          }}
                          className='label'
                        />
                        <span style={{ fontSize: UNIT * 0.875 }} className='label'>
                          {distance.toFixed(2)} km
                        </span>
                      </div>
                    )}
                  </Button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };

  renderSwitch = () => {
    const { secondaryColor, isMobile, orderTypes = {} } = this.props;
    const { selected } = this.state;
    const listOrderTypes = [];
    _.map(orderTypes, c => {
      if (c === ORDER_TYPE_DELIVERY) {
        listOrderTypes.push({
          label: ORDER_TYPE_DELIVERY_LABEL,
          icon: (
            <>
              <DirectionsBikeIcon
                style={{ ...styles.space }}
                className={`${_.size(orderTypes) === 1 && 'float-left'}`}
              />
              {_.size(orderTypes) === 1 && (
                <CheckIcon style={{ ...styles.space }} className='text-end' />
              )}{' '}
            </>
          )
        });
      } else if (c === ORDER_TYPE_CLICK_AND_COLLECT) {
        listOrderTypes.push({
          label: ORDER_TYPE_CLICK_AND_COLLECT_LABEL,
          icon: (
            <>
              <LocalMallOutlinedIcon
                style={{ ...styles.space }}
                className={`${_.size(orderTypes) === 1 && 'float-left'}`}
              />{' '}
              {_.size(orderTypes) === 1 && (
                <CheckIcon style={{ ...styles.space }} className='text-end' />
              )}
            </>
          )
        });
      } else {
        listOrderTypes.push({
          label: ORDER_TYPE_CLICK_AND_SEAT_LABEL,
          icon: (
            <>
              <RestaurantOutlinedIcon
                style={{ ...styles.space }}
                className={`${_.size(orderTypes) === 1 && 'float-left'}`}
              />{' '}
              {_.size(orderTypes) === 1 && (
                <CheckIcon style={{ ...styles.space }} className='text-end' />
              )}
            </>
          )
        });
      }
    });
    return (
      <BottomNavigation
        showLabels
        value={selected}
        onChange={(event, newValue) => changeSwitchValue(event, newValue, this)}
        className='bottom-navigation-style dark-modal dark-color'
        style={{ marginBottom: UNIT, height: 40 }}
      >
        {listOrderTypes &&
          _.map(listOrderTypes, (c, index) => {
            return (
              <BottomNavigationAction
                key={index}
                label={(!isMobile || (isMobile && _.size(listOrderTypes)) < 3) && c.label}
                icon={c.icon}
                value={index}
                className='label'
                style={{
                  flexDirection: 'row',
                  maxWidth: '100%',
                  borderRadius: UNIT * 2,
                  backgroundColor:
                    selected === index && _.size(orderTypes) > 1 ? secondaryColor : 'transparent',
                  color: selected === index && _.size(orderTypes) > 1 ? 'white' : '#000'
                }}
              />
            );
          })}
      </BottomNavigation>
    );
  };

  renderAutocompleteInput = wrongAddress => {
    const { closestShops } = this.props;
    const { address, showShops, validAddress, animatePlaceholder } = this.state;
    return (
      <PlacesAutocomplete
        value={address}
        onChange={address => handleChange(address, this)}
        onSelect={(address, placeId) => handleSelect(address, placeId, this)}
        onError={(status, clearSuggestions) => handleError(status, clearSuggestions, this)}
        shouldFetchSuggestions={address.length > 6}
        highlightFirstSuggestion
        searchOptions={searchOptions}
        debounce={400}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ ...styles.margin }}>
            <div
              className={`inputStyle ${wrongAddress && 'border-red'}`}
              style={{
                marginBottom:
                  showShops && closestShops && _.size(closestShops) !== 0 ? UNIT * 0.875 : 0
              }}
            >
              <LocationOnOutlinedIcon className='fa-map-marker' style={{ ...styles.space }} />
              <input
                {...getInputProps({
                  className: `form-control no-localization dark-modal`,
                  ref: el => {
                    this.inputElement = el;
                  },
                  placeholder: animatePlaceholder,
                  style: styles.addressInput,
                  'aria-describedby': 'button-addon3',
                  type: 'text'
                })}
              />
              {validAddress && <CheckIcon style={styles.space} className='fa-check' />}
            </div>
            <div
              className='autocomplete-dropdown-container'
              style={{ position: 'absolute', zIndex: 5000 }}
            >
              {loading && <Spinner />}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active p-2'
                  : 'suggestion-item p-2';
                const style = suggestion.active
                  ? {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer',
                      color: 'black'
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                      color: 'black'
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <strong className='no-localization'>
                      {suggestion.formattedSuggestion.mainText}
                    </strong>{' '}
                    <small className='no-localization'>
                      {suggestion.formattedSuggestion.secondaryText}
                    </small>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  };

  renderRadioButtons = () => {
    const { selectedAddress = {} } = this.state;
    const { secondaryColor } = this.props;
    return (
      <RadioGroup
        aria-label='selectedAddress'
        name='selectedAddress'
        id='selectedAddress'
        value={selectedAddress}
        onChange={e => updateAddressChoice(e, this)}
      >
        <div className='dishop-iso dark-modal'>
          <div className=' align-items-center'>
            <FormControlLabel
              style={{
                ...styles.formControlLabel
              }}
              control={
                <div>
                  <Radio value='0' style={{ color: secondaryColor }} />
                  Adresse enregistrée
                </div>
              }
              label={<p style={styles.labelStyle}>{renderAddress(this)}</p>}
            />
          </div>
          <hr className='hr-gray mb-0' style={{ marginTop: UNIT * 0.75 }} />

          <div className='align-items-center'>
            <FormControlLabel
              style={{
                ...styles.formControlLabel
              }}
              control={
                <div>
                  <Radio value='1' style={{ color: secondaryColor }} />
                  Ajouter une nouvelle adresse
                </div>
              }
              label={<p style={styles.labelStyle}>Saisir une nouvelle adresse de livraison</p>}
            />
          </div>
        </div>
      </RadioGroup>
    );
  };

  render() {
    const {
      googleMapsEnabled,
      fromModal,
      isMobile,
      orderTypes = {},
      closestShops,
      addressModal,
      customerAddress,
      marketplace
    } = this.props;
    const {
      selectedAddress,
      selected,
      loading,
      selectedShop,
      showShops,
      message,
      openMenu
    } = this.state;
    const wrongAddress = message === UNAVAILABLE_ADDRESS || message === WRONG_ADDRESS;
    const orderType = orderTypes[selected];
    const marketplaceEnabled = marketplace && marketplace.enabled;
    return (
      <div className={`${!addressModal ? 'px-0 dishop-iso dark-modal' : 'px-0'}`}>
        <div className={`dishop-iso dark-modal ${!fromModal && 'p-3'}`}>
          {!fromModal ? (
            <p className='text-secondary mb-2 label'>
              Entrez votre adresse pour trouver les commerces à proximité
            </p>
          ) : (
            <p style={{ marginTop: 0 }} className='text-center  title2 label'>
              Passer une commande
            </p>
          )}
          {!fromModal && (
            <div className='mt-3'>
              {this.renderSwitch()}
              {customerAddress &&
                closestShops &&
                (orderType === ORDER_TYPE_DELIVERY ||
                (orderType !== ORDER_TYPE_DELIVERY && marketplaceEnabled)
                  ? _.size(closestShops) > 0
                  : _.size(closestShops) > 1) &&
                this.renderRadioButtons()}
              <div className='mt-3'>
                {(orderType === ORDER_TYPE_DELIVERY ||
                  (orderType !== ORDER_TYPE_DELIVERY && marketplaceEnabled) ||
                  (closestShops && (_.size(closestShops) > 1 || _.size(closestShops) === 0))) &&
                  googleMapsEnabled &&
                  selectedAddress !== '0' &&
                  this.renderAutocompleteInput(wrongAddress)}
              </div>
            </div>
          )}

          {fromModal && (
            <div className='mt-3'>
              {this.renderSwitch()}

              {customerAddress &&
                closestShops &&
                (orderType === ORDER_TYPE_DELIVERY ||
                (orderType !== ORDER_TYPE_DELIVERY && marketplaceEnabled)
                  ? _.size(closestShops) > 0
                  : _.size(closestShops) > 1) &&
                this.renderRadioButtons()}
              <div className='mt-3'>
                {(orderType === ORDER_TYPE_DELIVERY ||
                  (orderType !== ORDER_TYPE_DELIVERY && marketplaceEnabled) ||
                  (closestShops && (_.size(closestShops) > 1 || _.size(closestShops) === 0))) &&
                  googleMapsEnabled &&
                  selectedAddress !== '0' &&
                  this.renderAutocompleteInput(wrongAddress)}

                <div>{!_.isEmpty(closestShops) && showShops && this.renderShops(wrongAddress)}</div>
              </div>
              {(loading || (!closestShops && customerAddress)) && (
                <div className='row justify-content-center'>
                  <Spinner />
                </div>
              )}
              {message && !openMenu && <p style={styles.errorText}>{message}</p>}
              {!loading && (
                <Button
                  type={PRIMARY}
                  className='btn'
                  style={styles.btn}
                  onClick={() => onChooseShop(this, selectedShop, orderType)}
                >
                  Valider
                  {isMobile && ` ${getOrderTypeLabel(orderType).toLowerCase()}`}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, configurationReducer, shopReducer, componentReducer }) => {
  const { customerAddress, userConnected } = userReducer;
  const { secondaryColor, orderTypes, name, marketplace, delivery } = configurationReducer;
  const { addressShop, closestShops, shopId, shopName } = shopReducer;
  const { isMobile, googleMapsEnabled, addressModal } = componentReducer;
  return {
    customerAddress,
    secondaryColor,
    orderTypes,
    addressShop,
    isMobile,
    googleMapsEnabled,
    userConnected,
    name,
    delivery,
    shopId,
    marketplace,
    addressModal,
    closestShops,
    shopName
  };
};
export default connect(mapStateToProps, {
  showChooseShop,
  updateClosestShops,
  updateAddressTemp,
  showAddressModal,
  showConnexionModal,
  showMessageModal
})(AddressInput);
