import _ from 'lodash';
import { isEqualDeep } from '../../utils';
import { DRIVER, SHOP, DRIVER_ICON_URL, SHOP_ICON_URL, CUSTOMER_ICON_URL } from '../../constants';

const createMarker = (position, icon, map) => {
  const { google } = window;
  return new google.maps.Marker({
    position,
    map,
    icon
  });
};

export const initializeMap = component => {
  const { google } = window;
  const myOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true
  };
  component.map = new google.maps.Map(component.refs.map_canvas, myOptions);
  component.directionsService = new google.maps.DirectionsService();
};

export const updateMap = (orderLocation, customerLocation, mode, component) => {
  component.setState({ orderLocation });
  const { google } = window;
  const driverIcon = {
    url: DRIVER_ICON_URL,
    scaledSize: new google.maps.Size(70, 70)
  };
  const customerIcon = {
    url: CUSTOMER_ICON_URL,
    scaledSize: new google.maps.Size(40, 40)
  };
  const shopIcon = {
    url: SHOP_ICON_URL,
    scaledSize: new google.maps.Size(40, 40)
  };
  const { directionsService, map } = component;
  const rendererOptions = {
    map,
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: '#6E747C',
      strokeWidth: 3
    }
  };
  const start = new google.maps.LatLng(orderLocation.latitude, orderLocation.longitude);
  const end = new google.maps.LatLng(customerLocation.latitude, customerLocation.longitude);
  const request = {
    origin: start,
    destination: end,
    travelMode: google.maps.DirectionsTravelMode.DRIVING
  };

  directionsService.route(request, (response, status) => {
    if (status === google.maps.DirectionsStatus.OK) {
      const { orderLocation: orderLocationState } = component.state;

      if (isEqualDeep(orderLocationState, orderLocation)) {
        const route = response.routes[0].legs[0];

        // Reinitialize markeers
        if (component.orderMarker) {
          component.orderMarker.setMap(null);
        }
        if (component.customerMarker) {
          component.customerMarker.setMap(null);
        }

        if (mode === DRIVER) {
          component.orderMarker = createMarker(route.start_location, driverIcon, map);
        } else {
          component.orderMarker = createMarker(route.start_location, shopIcon, map);
        }
        if (!_.isEqual(orderLocation, customerLocation) || mode === DRIVER) {
          component.customerMarker = createMarker(route.end_location, customerIcon, map);
        }
        // Reinitialize directions
        if (component.directionsDisplay) {
          component.directionsDisplay.setMap(null);
        }
        component.directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
        component.directionsDisplay.setMap(map);
        component.directionsDisplay.setDirections(response);
      }
    }
  });
};
