import moment from 'moment';
import {
  API_VALIDATE_ADDRESS_DELIVERY,
  API_VALIDATE_DELIVERY,
  API_REQUEST_DELIVERY
} from '../constants';
import { sendPostRequest } from './backend';
import { getFirebaseCustomerId, getIdToken } from './firebase';
import store from '../redux/store';

export const validateAddressStuart = async (customerAddress, shopId) => {
  if (customerAddress) {
    const { city } = customerAddress;
    const { name } = store.getState().configurationReducer;
    const { numero } = store.getState().userReducer;
    try {
      const data = {
        customerAddress,
        customerPhone: numero,
        shopId
      };
      const headers = {};
      const response = await sendPostRequest(API_VALIDATE_ADDRESS_DELIVERY, data, headers);
      return response.data.success;
    } catch (error) {
      let errorMessage = error;
      if (errorMessage === 'OUT_OF_RANGE' && city) {
        errorMessage = `${name} n'a pas encore été déployé sur ${city}.`;
      }
      throw errorMessage;
    }
  } else {
    throw 'address/wrong-address';
  }
};

export const validateDeliveryStuart = async order => {
  try {
    const idToken = await getIdToken(true);
    const { date } = order;
    const pickupAt = moment(date);
    const data = {
      order,
      pickupAt
    };
    const headers = { Authorization: `Bearer ${idToken}` };
    const response = await sendPostRequest(API_VALIDATE_DELIVERY, data, headers);
    const { valid } = response.data;
    return valid;
  } catch (error) {
    const errorMessage = error.response ? `${error.response.data.errorMessage}` : error;
    throw errorMessage;
  }
};
