import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateMap, initializeMap } from './MapClient.services';
import { DRIVER, SHOP } from '../../constants';

const INITIAL_STATE = { orderLocation: null };

class MapClient extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const { chargeId, orders, drivers, shops } = this.props;
    const { shopId, address } = orders[chargeId];
    const { location } = address;
    const { address: addressShop } = shops[shopId];
    const driverLocation = (drivers[chargeId] && drivers[chargeId].driverLocation) || null;
    initializeMap(this);
    if (!driverLocation) {
      updateMap(addressShop.location, location, SHOP, this);
    } else {
      updateMap(driverLocation, location, DRIVER, this);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { chargeId, orders, drivers, shops } = nextProps;
    const { shopId, address } = orders[chargeId];
    const { location } = address;
    const { address: addressShop } = shops[shopId];
    const driverLocation = (drivers[chargeId] && drivers[chargeId].driverLocation) || null;
    if (!driverLocation) {
      updateMap(addressShop.location, location, SHOP, this);
    } else {
      updateMap(driverLocation, location, DRIVER, this);
    }
  }

  render() {
    return <div ref='map_canvas' style={{ height: '35vh' }} />;
  }
}
const mapStateToProps = ({ userReducer, driverReducer, shopReducer, orderReducer }) => {
  const { customerAddress } = userReducer;
  const { drivers } = driverReducer;
  const { shops } = shopReducer;
  const { orders } = orderReducer;
  return {
    customerAddress,
    drivers,
    shops,
    orders
  };
};

export default connect(mapStateToProps)(MapClient);
