import _ from 'lodash';
import store from '../redux/store';

export const getNbTotalCart = () => {
  const cart = store.getState().cartReducer;
  let nbTotalCart = 0;
  _.map(cart, product => {
    const { nb, unit } = product;
    nbTotalCart += unit ? 1 : nb;
  });
  return nbTotalCart;
};
