import { UNIT } from '../../constants/configuration';

const styles = secondaryColor => ({
  orderButtonTerminalVertical: {
    color: 'white',
    backgroundColor: secondaryColor,
    borderRadius: UNIT * 2,
    height: '55px',
    width: '200px',
    fontSize: UNIT * 1.25
  }
});

export default styles;
