import React from 'react';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonOutlineOutlinedIcon from '@material-ui/icons/Person';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import _ from 'lodash';
import Button from '../Button';
import styles from './Navbar.styles';
import FlagsSelect from '../FlagsSelect';
import { isStringNotNull } from '../../utils';
import {
  showAccountModal,
  showConnexionModal,
  showAddressModal,
  showOrderModal
} from '../../redux/actions';
import { UNIT } from '../../constants/configuration';

const INITIAL_STATE = {
  styleId: 'navBar',
  orderIcon: 'transparent',
  accountIcon: 'transparent',
  locationIcon: 'transparent'
};

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    const lastScrollY = window.scrollY;
    if (lastScrollY >= 100) {
      this.setState({ styleId: 'navBar2' });
    } else {
      this.setState({ styleId: 'navBar' });
    }
  };

  toggleHover = iconId => {
    const { secondaryColor } = this.props;
    this.setState({
      [iconId]: secondaryColor
    });
  };

  toggleLeave = iconId => {
    this.setState({ [iconId]: 'transparent' });
  };

  render() {
    const { styleId } = this.state;
    const {
      logoUrl,
      name,
      webapp,
      landing,
      localize,
      marketplace,
      showAccountModal,
      showConnexionModal,
      userConnected,
      isUserAnonymous,
      customerAddress,
      showAddressModal,
      showOrderModal,
      orders,
      secondaryColor
    } = this.props;
    const { navbar } = webapp;
    const marketplaceEnabled = marketplace && marketplace.enabled;
    const { orderIcon, accountIcon, locationIcon } = this.state;
    const landingEnabled = landing && landing.enabled;
    const navbarEnabled = webapp && webapp.navbar && webapp.navbar.enabled;
    const localizeEnabled = localize && localize.enabled;
    return (
      <nav
        className={`navbar navbar-dark navbar-expand-sm px-2 px-sm-5 ${
          marketplaceEnabled
            ? !customerAddress
              ? 'justify-content-center'
              : 'justify-content-between'
            : ''
        }`}
        style={styles('#3f3f3f')[marketplaceEnabled ? 'navBarMarketplace' : styleId]}
        id='top-bar'
      >
        {marketplaceEnabled && !customerAddress && (
          <div data-toggle='collapse' data-target='.navbar-collapse.show'>
            <Link className='navbar-brand p-1' to='/'>
              <div className='row align-items-center'>
                {isStringNotNull(logoUrl) && (
                  <img src={logoUrl} width='40' height='40' className='d-inline-block' alt='' />
                )}
                <h2 className='mx-2 text-title d-inline-block mb-0'>{name}</h2>
              </div>
            </Link>
          </div>
        )}
        {marketplaceEnabled && customerAddress && (
          <>
            <div data-toggle='collapse' data-target='.navbar-collapse.show'>
              <Link className='navbar-brand' to='/'>
                <div className='row align-items-center'>
                  {isStringNotNull(logoUrl) && (
                    <img src={logoUrl} width='40' height='40' className='d-inline-block' alt='' />
                  )}
                  <h2 className='m-2 d-none d-md-inline-block text-title'>{name}</h2>
                </div>
              </Link>
            </div>
            <div className='d-flex'>
              {!isUserAnonymous && (
                <Button
                  className='p-0 no-outline mr-0 mr-md-1'
                  style={{ backgroundColor: accountIcon, borderRadius: UNIT / 4 }}
                  onMouseEnter={() => this.toggleHover('accountIcon')}
                  onMouseLeave={() => this.toggleLeave('accountIcon')}
                  onClick={() =>
                    userConnected ? showAccountModal(true) : showConnexionModal(true)
                  }
                >
                  <div className='row text-center text-white mx-0 mx-sm-1 align-items-center'>
                    <PersonOutlineOutlinedIcon style={{ width: 35, height: 35 }} />
                    <span
                      className='m-2 d-none d-md-inline-block'
                      style={{ fontSize: UNIT * 1.25 }}
                    >
                      Profil
                    </span>
                  </div>
                </Button>
              )}
              <Button
                className='p-0 no-outline'
                disabled={_.isEmpty(orders)}
                onClick={() => {
                  showOrderModal(true);
                }}
                onMouseEnter={() => this.toggleHover('orderIcon')}
                onMouseLeave={() => this.toggleLeave('orderIcon')}
                style={{ backgroundColor: orderIcon, borderRadius: UNIT / 4 }}
              >
                <div
                  className='row text-center text-white mx-0 mx-sm-1 align-items-center'
                  style={{ position: 'relative' }}
                >
                  <LocalMallIcon style={{ width: 30, height: 30 }} />
                  {!_.isEmpty(orders) && (
                    <div
                      className='d-block d-md-none'
                      style={{
                        position: 'absolute',
                        zIndex: 1000,
                        right: -6,
                        top: -10,
                        backgroundColor: secondaryColor,
                        borderRadius: 50,
                        width: 20
                      }}
                    >
                      <p className='mb-0 text-white' style={{ fontSize: UNIT * 0.875 }}>
                        {_.size(orders)}
                      </p>
                    </div>
                  )}
                  <p className='m-2 d-none d-md-inline-block' style={{ fontSize: UNIT * 1.25 }}>
                    {_.size(orders) <= 1
                      ? `${_.size(orders)} Commande`
                      : `${_.size(orders)} Commandes`}
                  </p>
                </div>
              </Button>
              <Button
                className='p-0 no-outline d-block d-md-none'
                disabled={_.isEmpty(orders)}
                onMouseEnter={() => this.toggleHover('locationIcon')}
                onMouseLeave={() => this.toggleLeave('locationIcon')}
                style={{ backgroundColor: locationIcon, borderRadius: UNIT / 2 }}
                onClick={() => showAddressModal(true)}
              >
                <div className='row text-center text-white mx-0 mx-sm-1 align-items-center'>
                  <LocationOnIcon style={{ width: 35, height: 35 }} />
                </div>
              </Button>
            </div>
          </>
        )}
        {!marketplaceEnabled && landingEnabled && (
          <>
            <div data-toggle='collapse' data-target='.navbar-collapse.show'>
              <Link className='navbar-brand' to='/'>
                {isStringNotNull(logoUrl) && (
                  <img
                    src={logoUrl}
                    width='30'
                    height='30'
                    className='d-inline-block align-top'
                    alt=''
                  />
                )}
                <span className='m-2'>{name}</span>
              </Link>
            </div>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarNav'
              aria-controls='navbarNav'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon' />
            </button>
            <div className='collapse navbar-collapse' id='navbarNav'>
              <ul className='navbar-nav'>
                <li className='nav-item' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  <Link className='nav-link' to='/' style={{ color: 'white' }}>
                    Accueil
                  </Link>
                </li>
                <li className='nav-item' data-toggle='collapse' data-target='.navbar-collapse.show'>
                  <Link className='nav-link' to='/commander' style={{ color: 'white' }}>
                    Commander
                  </Link>
                </li>
              </ul>
              {landingEnabled && localizeEnabled && (
                <>
                  <div className='ml-auto d-none d-sm-block nav-link'>
                    <FlagsSelect
                      className='mx-0 mx-sm-1'
                      showSelectedLabel={false}
                      alignOptions='left'
                    />
                  </div>
                  <div className='d-block d-sm-none nav-link px-0'>
                    <FlagsSelect className='mx-0 mx-sm-1' showSelectedLabel alignOptions='right' />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {!marketplaceEnabled && !landingEnabled && navbarEnabled && (
          <a className='navbar-brand' href={navbar.homeUrl}>
            {isStringNotNull(logoUrl) && (
              <img
                src={logoUrl}
                width='30'
                height='30'
                className='d-inline-block align-top'
                alt=''
              />
            )}
            <span className='m-2'>{name}</span>
          </a>
        )}
      </nav>
    );
  }
}

const mapStateToProps = ({ configurationReducer, shopReducer, userReducer, orderReducer }) => {
  const {
    secondaryColor,
    webapp,
    logoUrl,
    name,
    landing,
    localize,
    marketplace
  } = configurationReducer;
  const { support } = shopReducer;
  const { userConnected, isUserAnonymous, customerAddress } = userReducer;
  const { orders } = orderReducer;
  return {
    support,
    secondaryColor,
    webapp,
    logoUrl,
    name,
    landing,
    localize,
    marketplace,
    userConnected,
    isUserAnonymous,
    customerAddress,
    orders
  };
};

export default connect(mapStateToProps, {
  showAccountModal,
  showConnexionModal,
  showAddressModal,
  showOrderModal
})(Navbar);
