import { FETCH_CONFIGURATION } from './types';
import {
  getDataFromTable,
  initializeBackend,
  getShopsData,
  initializeGoogle,
  initializeLocalize,
  initializeFacebook
} from '../../api';
import { GOOGLE_API_KEY } from '../../constants';

export const fetchConfiguration = () => async dispatch => {
  const configVal = await getDataFromTable('configuration');
  const { domain, google, localize, facebook } = configVal;
  initializeFacebook(facebook || {});
  initializeGoogle(google || {});
  initializeBackend(domain);
  initializeLocalize(localize || {});
  dispatch({ type: FETCH_CONFIGURATION, payload: configVal });
};
