import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './Shop.styles';
import { IMAGE_UNAVAILABLE_IMAGE_URL } from '../../../constants';
import { isStringNotNull } from '../../../utils';
import { updateShop, updateShopIsLoaded } from '../../../redux/actions';
import { updateSelectedShop } from './Shop.services';

const INITIAL_STATE = {};

class Shop extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderShopCard() {
    const { distance, sliding, secondaryColor, closed } = this.props;
    let { name, description } = this.props;
    const maxTitle = 24;
    const maxDescription = 75;
    const image = sliding && sliding[0];
    name = `${(name[0].toUpperCase() + name.slice(1).toLowerCase()).substring(0, maxTitle)}${
      name.length > maxTitle ? '...' : ''
    }`;
    description = `${
      description
        ? `${description.substring(0, maxDescription)}${
            description.length > maxDescription ? '...' : ''
          }`
        : ''
    }`;
    return (
      <div className='shop-list' style={{ overflow: 'hidden' }}>
        <img
          src={image && isStringNotNull(image) ? image : IMAGE_UNAVAILABLE_IMAGE_URL}
          alt={name}
          className='img-fluid col-12 image-shop-list'
          style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }}
        />
        <div className='py-3 px-2 px-sm-3 text-left'>
          <div className='justify-content-between d-flex align-items-start mb-1'>
            <h6 className='mb-0 text-shop'>{name}</h6>
            {distance >= 0 && (
              <div className='text-white badge ml-2' style={{ backgroundColor: secondaryColor }}>
                {distance.toFixed(1)} km
              </div>
            )}
          </div>
          <p className='text-muted text-shop-description mb-1'>{description}</p>
        </div>
        {closed && (
          <div className='overlay' style={{ borderRadius: 15 }}>
            <div className='text-overlay'>
              <h6 className='text-white disabledTitle'>Magasin Indisponible</h6>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { customerAddress, closed, shopIndex } = this.props;
    const shopCardStyle = _.clone(styles.shopCard);
    const shopCardDisabledStyle = _.clone(styles.shopCardDisabled);
    const linkStyle = _.clone(styles.link);
    const cardClass = 'card text-center shadow productCard mb-2 mb-sm-4';
    if (closed) {
      return (
        <div className={cardClass} style={shopCardDisabledStyle}>
          {this.renderShopCard()}
        </div>
      );
    }
    return (
      <Link
        to={{
          pathname: `${shopIndex}`
        }}
        style={linkStyle}
      >
        <div
          className={cardClass}
          style={shopCardStyle}
          onClick={() => {
            updateSelectedShop(this, customerAddress);
          }}
        >
          {this.renderShopCard()}
        </div>
      </Link>
    );
  }
}

const mapStateToProps = ({ userReducer, configurationReducer, shopReducer }) => {
  const { customerAddress } = userReducer;
  const { shopId } = shopReducer;
  const { secondaryColor } = configurationReducer;
  return {
    customerAddress,
    secondaryColor,
    shopId
  };
};

export default connect(mapStateToProps, { updateShop, updateShopIsLoaded })(Shop);
