import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import SignInSignUpContainer from '../SignInSignUpContainer';
import {
  showConnexionModal,
  showSignUp,
  showForgetPassword,
  resetEmail
} from '../../redux/actions';
import { isTerminalVertical } from '../../utils';
import { UNIT } from '../../constants/configuration';
import ModalBorder from '../ModalBorder';

class ConnexionModal extends React.Component {
  onClose = () => {
    const { showConnexionModal, showSignUp, showForgetPassword } = this.props;
    showConnexionModal(false);
    showSignUp(false);
    showForgetPassword(false);
    resetEmail();
  };

  render() {
    const {
      connexionModal,
      showConnexionModal,
      userConnected,
      showSignUp,
      showForgetPassword,
      isMobile
    } = this.props;
    if (userConnected) {
      showConnexionModal(false);
      showSignUp(false);
      resetEmail();
    }
    return (
      <>
        <Modal
          show={connexionModal}
          onHide={() => {
            showConnexionModal(false);
            showSignUp(false);
            showForgetPassword(false);
            resetEmail();
          }}
          scrollable
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <ModalBorder
            connexionModal
            width={!isMobile && UNIT * 26.25}
            margin='0 auto'
          >
            <div
              className='row closeStyle'
              style={{
                right: isMobile ? `${UNIT * 0.25}%` : `${UNIT * 0.6875}%`
              }}
            >
              <CloseButton
                onClick={this.onClose}
                closeStyle={{ right: UNIT * 0.5 }}
              />
            </div>
            <Modal.Body
              className='dark-modal'
              style={
                isTerminalVertical()
                  ? {}
                  : { backgroundColor: 'transparent', borderRadius: UNIT * 2 }
              }
            >
              <div
                className='dishop-iso dark-modal'
                style={isTerminalVertical() ? {} : { borderRadius: UNIT * 2 }}
              >
                <SignInSignUpContainer />
              </div>
            </Modal.Body>
          </ModalBorder>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ userReducer, componentReducer }) => {
  const { userConnected } = userReducer;
  const { connexionModal, isMobile } = componentReducer;
  return {
    userConnected,
    connexionModal,
    isMobile
  };
};

export default connect(mapStateToProps, {
  showConnexionModal,
  showSignUp,
  showForgetPassword
})(ConnexionModal);
