import React from 'react';
import { connect } from 'react-redux';
import { version } from '../../../package.json';

class Footer extends React.Component {
  renderFooter() {
    const { cgu, imagesDescription } = this.props;
    return (
      <div className='row'>
        <div className='col-sm-4'>
          <p className='no-localization'>{`Version ${version} ${imagesDescription}`}</p>
        </div>
        <div className='col-sm-4'>
          <p>
            <u>
              <a href={cgu} target='_blank'>
                conditions générales d'utilisation
              </a>
            </u>
          </p>
        </div>
        <div className='col-sm-4'>
          <p>
            <a target='_blank' href='https://dishop.co'>
              © Powered by <b>Dishop</b>
            </a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <footer id='sticky-footer'>
        <div className='container-fluid text-center border-top py-2'>
          <small>{this.renderFooter()}</small>
        </div>
      </footer>
    );
  }
}

const mapStateToProp = ({ shopReducer, configurationReducer }) => {
  const { cgu, imagesDescription } = configurationReducer;
  const { support } = shopReducer;
  return {
    support,
    imagesDescription,
    cgu
  };
};

export default connect(mapStateToProp, {})(Footer);
