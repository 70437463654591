import { ORDER_TYPE_DELIVERY } from '../../constants';
import { getAddressFormatted } from '../../utils';
import store from '../../redux/store';

export const getAddress = () => {
  const { customerAddress } = store.getState().userReducer;
  const { addressShop } = store.getState().shopReducer;
  let address =
    customerAddress && customerAddress.orderType === ORDER_TYPE_DELIVERY
      ? customerAddress
      : addressShop;
  const nbCaract = 25;
  if (address) {
    address = getAddressFormatted(address);
    address = `${address.substring(0, nbCaract)}${address.length > nbCaract ? '...' : ''}`;
  } else {
    address = 'Aucune adresse';
  }
  return address;
};
