import _ from 'lodash';
import { Cookies } from 'react-cookie';
import {
  signOut,
  signInUser,
  signUpUser,
  forgetPasswordUser,
  facebookUserSignIn,
  googleUserSignIn,
  getDataFromTable,
  setUserAddressFirebase,
  listenFirebaseUsersData,
  updateDataOnTable,
  deleteUser,
  updateAccountFirebase,
  unlistenFirebase
} from '../../api/firebase';
import { sendGoogleAnalytics } from '../../api/google';
import {
  USER_CONNECTION,
  USER_CONNECTION_ERROR,
  USER_SIGNOUT,
  USER_SIGNUP_ERROR,
  RESET_LOGIN,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_ATTRIBUTE,
  USER_START_CONNECTION,
  USER_START_RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  SHOW_SIGNUP,
  UPDATE_PROFILE,
  RESET_ORDER,
  SHOW_FORGET_PASSWORD,
  USER_START_UPDATE_INFO,
  MESSAGE_MODAL,
  UPDATE_ADDRES_IS_LOADED,
  EMAIL,
  SHOW_CONNEXION_MODAL
} from './types';
import { ORDER_TYPE_CLICK_AND_SEAT } from '../../constants';
import { showSplash, showMessageModal } from './componentAction';
import {
  isWebView,
  isQrCode,
  isTerminal,
  getCookieCustomerId,
  getShopIdFromLink,
  setUserShopIds,
  getCustomerAddress,
  generateUUID,
  setCookieCustomerId,
  createCookieCustomer,
  sendCloudWatchAlert,
  getWebViewCustomerId,
  sendCloudWatchLogs,
  formatPhone
} from '../../utils';
import {
  updateCustomerHubrise,
  signUpCustomerHubrise,
  getCustomerBackend,
  deleteCustomerAccountBackend,
  getFirebaseCustomerId
} from '../../api';
import store from '../store';

export const userConnection = (email, password) => async (dispatch, getState) => {
  try {
    sendCloudWatchLogs(`User ${email} connecting`);
    dispatch({ type: USER_START_CONNECTION });
    const customerId = await signInUser(email, password);
    userConnectionSuccess(dispatch, getState, customerId, false);
    sendGoogleAnalytics('login', { method: 'firebase' });
  } catch (error) {
    const errorMessage = String(error);
    if (
      !errorMessage.includes('auth/too-many-requests') &&
      !errorMessage.includes('auth/wrong-password') &&
      !errorMessage.includes('auth/user-not-found') &&
      !errorMessage.includes('auth/account-exists-with-different-credential')
    ) {
      sendCloudWatchAlert(`Could not login: ${errorMessage}`);
    }
    dispatch({ type: USER_CONNECTION_ERROR, payload: errorMessage });
  }
};

export const facebookSignIn = () => async (dispatch, getState) => {
  try {
    sendCloudWatchLogs(`User connecting with facebook`);
    dispatch({ type: USER_START_CONNECTION });
    const customerId = await facebookUserSignIn();
    userConnectionSuccess(dispatch, getState, customerId, false);
    sendGoogleAnalytics('login', { method: 'facebook' });
  } catch (error) {
    const errorMessage = String(error);
    if (
      !errorMessage.includes('auth/popup-closed-by-user') &&
      !errorMessage.includes('auth/cancelled-popup-request') &&
      !errorMessage.includes('auth/user-cancelled') &&
      !errorMessage.includes('auth/account-exists-with-different-credential') &&
      !errorMessage.includes('auth/network-request-failed') &&
      !errorMessage.includes('auth/popup-blocked') &&
      !errorMessage.includes('auth/invalid-credential')
    ) {
      sendCloudWatchAlert(`Could not login with facebook: ${errorMessage}`);
    }
    dispatch({
      type: USER_CONNECTION_ERROR,
      payload: "Nous n'avons pas pu vous connecter via Facebook! Veuillez réessayer"
    });
  }
};

export const googleSignIn = () => async (dispatch, getState) => {
  try {
    sendCloudWatchLogs(`User connecting with google`);
    dispatch({ type: USER_START_CONNECTION });
    const customerId = await googleUserSignIn();
    userConnectionSuccess(dispatch, getState, customerId, false);
    sendGoogleAnalytics('login', { method: 'google' });
  } catch (error) {
    const errorMessage = String(error);
    if (
      !errorMessage.includes('auth/popup-closed-by-user') &&
      !errorMessage.includes('auth/cancelled-popup-request') &&
      !errorMessage.includes('auth/user-cancelled') &&
      !errorMessage.includes('auth/account-exists-with-different-credential') &&
      !errorMessage.includes('auth/network-request-failed') &&
      !errorMessage.includes('auth/popup-blocked')
    ) {
      sendCloudWatchAlert(`Could not login with google: ${errorMessage}`);
    }
    dispatch({
      type: USER_CONNECTION_ERROR,
      payload: "Nous n'avons pas pu vous connecter via google! Veuillez réessayer"
    });
  }
};

export const userSignUp = (email, password, nom, prenom, telephone, newsletter) => async (
  dispatch,
  getState
) => {
  try {
    sendCloudWatchLogs(`User ${email} getting sign up`);
    dispatch({ type: USER_START_CONNECTION });
    const customerId = await signUpUser(email, password, nom, prenom, telephone, newsletter);
    dispatch({ type: USER_SIGNUP_ERROR, payload: '' });
    dispatch({
      type: SHOW_SIGNUP,
      payload: false
    });
    dispatch({
      type: SHOW_CONNEXION_MODAL,
      payload: false
    });
    dispatch({
      type: EMAIL,
      payload: null
    });
    userConnectionSuccess(dispatch, getState, customerId, false);
    sendGoogleAnalytics('sign_up', { method: 'firebase' });
  } catch (error) {
    deleteCustomerAccountBackend();
    userSignOutFromRedux(dispatch, getState);
    let errorMessage = String(error);
    if (!error) {
      errorMessage = 'auth/error-signup';
    }
    if (
      !errorMessage.includes('auth/email-already-in-use') &&
      !errorMessage.includes('auth/weak-password')
    ) {
      sendCloudWatchAlert(`Could not signup ${errorMessage}`);
    }
    dispatch({ type: USER_SIGNUP_ERROR, payload: errorMessage });
  }
};

export const resetEmail = () => {
  store.dispatch({ type: EMAIL, payload: null });
};

export const userForgetPassword = email => async dispatch => {
  try {
    sendCloudWatchLogs(`User ${email} resets his passwoard`);
    dispatch({ type: USER_START_RESET_PASSWORD });
    await forgetPasswordUser(email);
    dispatch({ type: RESET_PASSWORD_ERROR, payload: '' });
    dispatch({
      type: SHOW_FORGET_PASSWORD,
      payload: false
    });
  } catch (error) {
    const errorMessage = String(error);
    if (!errorMessage.includes('user-not-found')) {
      sendCloudWatchAlert(`Could not forget password ${errorMessage}`);
    }

    dispatch({ type: RESET_PASSWORD_ERROR, payload: errorMessage });
  }
};

export const terminalUserConnectionCheck = customerId => async (dispatch, getState) => {
  userConnectionSuccess(dispatch, getState, customerId, true);
};

export const userConnectionCheck = () => async (dispatch, getState) => {
  try {
    if (isWebView() || isQrCode()) {
      await userSignOutFromRedux(dispatch, getState);
      let customerId = getCookieCustomerId();
      if (customerId) {
        const address = await getCustomerAddress(customerId);
        const shopId = getShopIdFromLink();
        if ((address && address.shopId !== shopId) || !address) {
          customerId = null;
        }
      }
      if (isQrCode() && !customerId) {
        try {
          sendCloudWatchLogs('Creating customer backend');
          customerId = await createCookieCustomer(ORDER_TYPE_CLICK_AND_SEAT);
        } catch (error) {
          sendCloudWatchAlert(`Could not create customer backend ${error}`);
        }
      }
      if (isWebView()) {
        customerId = getWebViewCustomerId();
      }
      userConnectionSuccess(dispatch, getState, customerId, true);
    } else if (!isTerminal()) {
      const customerId = getFirebaseCustomerId();
      if (customerId) {
        userConnectionSuccess(dispatch, getState, customerId, false);
      } else {
        const newCustomerAddress = new Cookies().get('address');
        await updateAddress(newCustomerAddress, dispatch);
        dispatch({ type: USER_SIGNOUT });
        showSplash(dispatch, false);
      }
    } else {
      showSplash(dispatch, false);
    }
  } catch (error) {
    sendCloudWatchAlert(`Error checking user ${error}`);
  }
};

export const userSignOut = () => async (dispatch, getState) => {
  try {
    const { user } = getState().userReducer;
    if (user) {
      unlistenFirebase(user.customerId);
    }
    await signOut();
    const newCustomerAddress = new Cookies().get('address');
    await updateAddress(newCustomerAddress, dispatch);
    dispatch({ type: USER_SIGNOUT });
    dispatch({ type: RESET_ORDER });
    showSplash(dispatch, false);
  } catch (error) {
    sendCloudWatchAlert('Could not sign out');
  }
};

export const userSignOutFromRedux = async (dispatch, getState) => {
  try {
    const { user } = getState().userReducer;
    if (user) {
      unlistenFirebase(user.customerId);
    }
    await signOut();
    const newCustomerAddress = new Cookies().get('address');
    await updateAddress(newCustomerAddress, dispatch);
    dispatch({ type: USER_SIGNOUT });
    dispatch({ type: RESET_ORDER });
  } catch (error) {
    sendCloudWatchAlert('Could not sign out');
  }
};

export const userConnectionSuccess = async (dispatch, getState, customerId, isUserAnonymous) => {
  let customerProfile = await getDataFromTable(`users/${customerId}`);
  if (!customerProfile) {
    customerProfile = await getCustomerBackend();
  }
  if (customerProfile && customerProfile.donneesPerso) {
    const { donneesPerso, shopIds } = customerProfile;
    // Update cart
    const cart = getState().cartReducer;
    if (!_.isEmpty(cart)) {
      await updateDataOnTable(`/users/${customerId}/pendingOrder`, { commande: cart });
    }
    // Update address (addess from cookie that will be used as the new address)
    const { customerAddress } = getState().userReducer;
    await addAddress(dispatch, customerAddress, customerId, isUserAnonymous);
    // Listen real time firebase data (needs to be done after updating address)
    if (!isTerminal()) {
      listenFirebaseUsersData(customerId, isUserAnonymous, updateUser);
    }
    // Update user info
    updateUser(donneesPerso, shopIds, customerId, isUserAnonymous, dispatch);
    // hide splash
  } else {
    sendCloudWatchAlert(`User ${customerId} without data`);
    userSignOutFromRedux(dispatch, getState);
    dispatch({ type: USER_CONNECTION_ERROR, payload: 'auth/no-user-data' });
  }
  showSplash(dispatch, false);
};

export const updateUser = (userInfo, userShopIds, customerId, isUserAnonymous, dispatch) => {
  if (dispatch) {
    dispatch({
      type: USER_CONNECTION,
      payload: {
        user: { ...userInfo, userShopIds, customerId },
        isUserAnonymous
      }
    });
  } else {
    return {
      type: USER_CONNECTION,
      payload: {
        user: { ...userInfo, userShopIds, customerId },
        isUserAnonymous
      }
    };
  }
};

export const addAddress = async (dispatch, newCustomerAddress, customerId, isUserAnonymous) => {
  try {
    let shopId = null;
    const oldCustomerAddress = await getCustomerAddress(customerId);
    if (oldCustomerAddress && (!newCustomerAddress || isUserAnonymous)) {
      updateAddress(oldCustomerAddress, dispatch);
      shopId = oldCustomerAddress.shopId;
    } else if (newCustomerAddress) {
      await setUserAddressFirebase(customerId, newCustomerAddress);
      updateAddress(newCustomerAddress, dispatch);
      shopId = newCustomerAddress.shopId;
    } else if (!oldCustomerAddress && !newCustomerAddress) {
      updateAddress(null, dispatch);
    }
    if (!isUserAnonymous && shopId) {
      setUserShopIds(shopId, customerId);
    }
  } catch (error) {
    sendCloudWatchAlert(`Error adding address ${error}`);
  }
};

export const updateAddressIsLoaded = addressIsLoaded => {
  return { type: UPDATE_ADDRES_IS_LOADED, payload: addressIsLoaded };
};

export const updateAddress = (address, dispatch) => {
  if (dispatch) {
    dispatch({ type: UPDATE_ADDRESS, payload: { address } });
  } else {
    return { type: UPDATE_ADDRESS, payload: { address } };
  }
};

export const updateAddressAttribute = (attribute, value) => {
  return { type: UPDATE_ADDRESS_ATTRIBUTE, payload: { attribute, value } };
};

export const updateProfile = (attribute, value, dispatch) => {
  if (dispatch) {
    dispatch({ type: UPDATE_PROFILE, payload: { attribute, value } });
  } else {
    return { type: UPDATE_PROFILE, payload: { attribute, value } };
  }
};

export const resetLogin = () => {
  return {
    type: RESET_LOGIN
  };
};
