import { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import _ from 'lodash';
import store from '../../redux/store';
import {
  getClosestShops,
  isAddressValid,
  parseAddress,
  updateAddressAndShop,
  sendCloudWatchAlert,
  getAddressDetails,
  getShopIdMarketplaceLink,
  errorShopUnavailable,
  isObjectNotNull,
  getAddressFormatted,
  sendCloudWatchLogs
} from '../../utils';
import { validateAddressStuart } from '../../api';
import {
  ORDER_TYPE_DELIVERY,
  WRONG_ADDRESS,
  UNAVAILABLE_ADDRESS,
  DEFAULT_TEXT,
  PLACEHOLDER_TEXT
} from '../../constants';

export const getShopsFromAdress = async (component, orderType, isChangeSwitch) => {
  const { placeId, selectedAddress, isCustomerAddress, validAddress } = component.state;
  const address = await renderAddress(component);
  try {
    const {
      onCancel,
      updateClosestShops,
      updateAddressTemp,
      marketplace,
      showAddressModal,
      userConnected,
      fromModal
    } = component.props;
    let customerAddress = {};
    const { stuart, delivery } = store.getState().configurationReducer;
    const { shopName, shopId } = store.getState().shopReducer;
    const { chooseShop } = delivery;
    const marketplaceEnabled = marketplace && marketplace.enabled;
    try {
      let results = [];
      if (!isChangeSwitch) {
        if (placeId) {
          results = await geocodeByPlaceId(placeId);
        } else {
          results = await geocodeByAddress(address);
        }
      }
      customerAddress = !isChangeSwitch
        ? parseAddress(results[0].address_components)
        : component.props.customerAddress;

      const { lat, lng } = await getLatLng(results[0]);
      const addresses = await Geocode.fromLatLng(lat, lng);
      const { district, subLocality } = getAddressDetails(addresses.results);
      customerAddress.district = district;
      customerAddress.subLocality = subLocality;
      customerAddress.location = {
        latitude: lat,
        longitude: lng
      };
      customerAddress.orderType = orderType;
    } catch (error) {
      const errorMessage = String(error);
      if (!errorMessage.includes('INVALID_REQUEST') && !errorMessage.includes('ZERO_RESULTS') && !errorMessage.includes('geometry')) {
        sendCloudWatchAlert(`Could not get address ${error}`);
      }
    }
    const shopIdFromLink = getShopIdMarketplaceLink();
    if (isAddressValid(customerAddress, orderType)) {
      const shops = await getClosestShops(customerAddress, orderType);
      component.setState({ deliveryZone: shops });
      if (shops.length > 0) {
        const shop = shops[0];
        updateAddressTemp(customerAddress);
        if (orderType === ORDER_TYPE_DELIVERY) {
          let isAddressValidStuart = true;
          if (stuart && userConnected) {
            isAddressValidStuart = await validateAddressStuart(
              customerAddress,
              (marketplaceEnabled && shopIdFromLink) || shop.shopId
            );
          }
          if (isAddressValidStuart) {
            if (!fromModal) {
              if (marketplaceEnabled && shopIdFromLink) {
                const selectedShop = _.find(shops, shop => {
                  return shop.shopId === shopIdFromLink;
                });
                if (!selectedShop) {
                  throw errorShopUnavailable(shopName);
                }
              }
              await updateClosestShops(shops);
              if (isCustomerAddress) {
                await updateAddressAndShop(
                  shopIdFromLink || shop.shopId,
                  customerAddress,
                  ORDER_TYPE_DELIVERY,
                  isCustomerAddress
                );
                await component.setState({
                  loading: false,
                  selectedAddress: '0',
                  validAddress: false
                });
              }

              showAddressModal(false);
            } else if (chooseShop && shops.length > 1) {
              await updateClosestShops(shops);
              component.setState({
                shopName:
                  selectedAddress === '0' || (customerAddress && customerAddress.shopId === shopId)
                    ? shopName
                    : DEFAULT_TEXT,
                selectedShop:
                  selectedAddress === '0' ? _.filter(shops, shop => shop.shopId === shopId)[0] : {}
              });
              if (!marketplaceEnabled) {
                component.setState({
                  showShops:
                    (selectedAddress === '0' &&
                      customerAddress.orderType === ORDER_TYPE_DELIVERY &&
                      chooseShop) ||
                    (selectedAddress === '1' && validAddress)
                });
              }
            } else {
              await updateClosestShops(shops);
              component.setState({
                selectedShop: shop,
                shopName: shop.name,
                showShops: false
              });
              if (onCancel) {
                onCancel();
              }
            }
            return true;
          }
        } else if (orderType !== ORDER_TYPE_DELIVERY) {
          if (!fromModal) {
            if ((customerAddress || shopIdFromLink) && isCustomerAddress) {
              await updateAddressAndShop(
                shopIdFromLink || shop.shopId,
                customerAddress,
                orderType,
                isCustomerAddress
              );
              await component.setState({
                loading: false,
                selectedAddress: '0',
                validAddress: false
              });
              showAddressModal(false);
            }
          }
          if (shops.length === 1) {
            await updateClosestShops(shops);
            component.setState({
              selectedShop: shop,
              shopName: shop.name
            });
          } else {
            await updateClosestShops(shops);
            component.setState({
              shopName:
                selectedAddress === '0' || (customerAddress && customerAddress.shopId === shopId)
                  ? shopName
                  : DEFAULT_TEXT,
              selectedShop:
                selectedAddress === '0' ? _.filter(shops, shop => shop.shopId === shopId)[0] : {}
            });
            if (!marketplaceEnabled && fromModal) {
              component.setState({ showShops: true });
            }
          }

          return true;
        }
      }
      if (isCustomerAddress && orderType === ORDER_TYPE_DELIVERY) {
        throw UNAVAILABLE_ADDRESS;
      }
    } else {
      getShopsIfInvalidAddress(component, orderType, marketplaceEnabled);
    }
  } catch (error) {
    let errorMessage = String(error);
    if (!errorMessage.includes('déployé sur') && !errorMessage.includes('incorrecte')) {
      sendCloudWatchAlert(`Could not add address ${address}: ${errorMessage}`);
    }
    if (errorMessage.includes('ZERO_RESULTS')) {
      errorMessage = UNAVAILABLE_ADDRESS;
    }
    component.setState({ loading: false, message: errorMessage });
  }
};
export const getShopsIfInvalidAddress = async (component, orderType, marketplaceEnabled) => {
  if (orderType !== ORDER_TYPE_DELIVERY) {
    const { updateClosestShops, updateAddressTemp } = component.props;
    updateAddressTemp(null);
    const shops = await getClosestShops(null, orderType);
    const shop = shops[0];
    await updateClosestShops(shops);
    if (shops.length === 1) {
      component.setState({
        selectedShop: shop,
        shopName: shop.name
      });
    } else {
      component.setState({
        shopName: DEFAULT_TEXT,
        selectedShop: {}
      });
    }
    if (!marketplaceEnabled) {
      component.setState({ showShops: true });
    }
  }
};

export const changeSwitchValue = async (event, newValue, component) => {
  const { orderTypes = [], customerAddress, marketplace } = component.props;
  const { delivery } = store.getState().configurationReducer;
  const { chooseShop } = delivery;
  const { selectedAddress, openMenu } = component.state;
  const marketplaceEnabled = marketplace && marketplace.enabled;
  await getShopsFromAdress(component, orderTypes[newValue], true);
  component.setState({
    selected: newValue,
    openMenu: false,
    animation: openMenu,
    validAddress: false,
    message: null,
    address: selectedAddress === '0' && customerAddress ? renderAddress(component) : '',
    placeId: '',
    showShops: !marketplaceEnabled
      ? chooseShop && customerAddress && selectedAddress === '0'
        ? true
        : orderTypes[newValue] !== ORDER_TYPE_DELIVERY
      : false,
    animatePlaceholder:
      orderTypes[newValue] === ORDER_TYPE_DELIVERY
        ? PLACEHOLDER_TEXT
        : `${PLACEHOLDER_TEXT}(facultatif)`,
    isCustomerAddress: selectedAddress === '0'
  });

  if (!customerAddress) {
    component.setState({
      selectedAddress: '1',
      address: '',
      validAddress: false
    });
  }
};

export const onChooseShop = async (component, shop, orderType) => {
  try {
    const { showAddressModal, marketplace } = component.props;
    const { isCustomerAddress, selectedAddress, deliveryZone, openMenu } = component.state;
    const { delivery } = store.getState().configurationReducer;
    const { addressTemp } = store.getState().componentReducer;
    const { shopName } = store.getState().shopReducer;
    const { chooseShop } = delivery;
    const { address } = shop;
    const marketplaceEnabled = marketplace && marketplace.enabled;
    const shopIdFromLink = getShopIdMarketplaceLink();
    component.setState({
      loading: true,
      openMenu: false,
      animation: openMenu
    });
    let customerAddress = address;
    if (isObjectNotNull(addressTemp)) {
      customerAddress = addressTemp;
    }
    if (orderType === ORDER_TYPE_DELIVERY && selectedAddress === '1' && !isCustomerAddress) {
      throw WRONG_ADDRESS;
    } else if (
      ((_.isEmpty(shop) && orderType !== ORDER_TYPE_DELIVERY) ||
        (_.isEmpty(shop) && orderType === ORDER_TYPE_DELIVERY && chooseShop)) &&
      !marketplaceEnabled
    ) {
      throw 'Vous devez sélectionner un point de vente';
    } else if (marketplaceEnabled) {
      const shops =
        orderType === ORDER_TYPE_DELIVERY
          ? await getClosestShops(customerAddress, orderType)
          : await getClosestShops(null, orderType);
      const shop = shops[0];
      if (customerAddress || shopIdFromLink) {
        const selectedShop = _.find(shops, shop => {
          return shop.shopId === shopIdFromLink;
        });
        if (!selectedShop || deliveryZone.length === 0) {
          throw errorShopUnavailable(shopName);
        } else {
          await updateAddressAndShop(
            shopIdFromLink || shop.shopId,
            customerAddress || shop.address,
            orderType,
            isCustomerAddress
          );
          showAddressModal(false);
          component.setState({
            loading: false
          });
        }
      }
    } else if (
      deliveryZone.length > 0 ||
      orderType !== ORDER_TYPE_DELIVERY ||
      selectedAddress === '0'
    ) {
      await updateAddressAndShop(
        shop.shopId,
        customerAddress,
        chooseShop && customerAddress.orderType === ORDER_TYPE_DELIVERY
          ? ORDER_TYPE_DELIVERY
          : orderType,
        isCustomerAddress
      );
      showAddressModal(false);
      component.setState({
        loading: false
      });
    } else {
      throw UNAVAILABLE_ADDRESS;
    }
  } catch (error) {
    sendCloudWatchLogs(`Could not change shop ${error}`);
    component.setState({
      loading: false,
      message: error
    });
  }
};
export const handleOpen = component => {
  const { openMenu } = component.state;
  component.setState({ openMenu: !openMenu, animation: true });
};

export const handleClick = (shop, addressName, component) => {
  const { openMenu } = component.state;
  component.setState({
    selectedShop: shop,
    shopName: addressName,
    openMenu: false,
    animation: openMenu,
    message: null
  });
};

export const handleError = (status, clearSuggestions, component) => {
  const errorMessage = String(status);
  if (!errorMessage.includes('ZERO_RESULTS')) {
    sendCloudWatchAlert(`Error from google api ${status}`);
  }
  component.setState({ errorMessage: status }, () => {
    clearSuggestions();
  });
};

export const handleChange = (address, component) => {
  const { orderTypes } = component.props;
  const { selected, openMenu } = component.state;
  component.setState({
    address,
    validAddress: false,
    openMenu: false,
    animation: openMenu,
    message: null
  });
  if (orderTypes && orderTypes[selected] === ORDER_TYPE_DELIVERY) {
    component.setState({ showShops: false });
  }
};

export const handleSelect = async (address, placeId, component) => {
  const { selected } = component.state;
  const { orderTypes } = component.props;
  await component.setState({
    address,
    placeId,
    isCustomerAddress: true,
    validAddress: true
  });
  await getShopsFromAdress(component, orderTypes[selected]);
};
export const updateAddressChoice = async ({ target }, component) => {
  const { orderTypes, customerAddress } = component.props;
  const { selected, openMenu } = component.state;
  const { delivery = {} } = store.getState().configurationReducer;
  const { chooseShop } = delivery;
  await component.setState({
    selectedAddress: target.value,
    address: target.value === '0' ? renderAddress(component) : '',
    validAddress: false,
    openMenu: false,
    animation: openMenu,
    isCustomerAddress: target.value === '0',
    message: null
  });
  if (
    orderTypes[selected] === ORDER_TYPE_DELIVERY &&
    (target.value === '1' || customerAddress.orderType !== ORDER_TYPE_DELIVERY || !chooseShop)
  ) {
    component.setState({ showShops: false });
  }
  await getShopsFromAdress(component, orderTypes[selected]);
  if (target.value === '1') {
    component.setState({ shopName: DEFAULT_TEXT });
  }
};
export const animatePlaceHolderText = (inputElement, textString, ms) => {
  let str = '';
  let n = 0;
  let interval = null;
  const intervalFunc = () => {
    n += 1;
    if (str !== textString) {
      str += textString[n - 1];
      inputElement.placeholder = str;
    } else {
      setTimeout(() => {
        inputElement.placeholder = '';
        n = 0;
        str = '';
        interval = setInterval(intervalFunc, ms);
      }, ms * 5);
      clearInterval(interval);
    }
  };
  interval = setInterval(intervalFunc, ms);
};

export const renderAddress = component => {
  const { customerAddress } = component.props;
  return getAddressFormatted(customerAddress);
};
