import axios from 'axios';
import { API_STREAM_LOGS, FRONTEND_ALERT_ERROR } from '../constants';
import { isDev, isQrCode, isTerminal } from './config';
import store from '../redux/store';
import { version } from '../../package.json';

export const sendCloudWatchLogs = async message => {
  try {
    const domain = window.location.hostname;
    const { shopId } = store.getState().shopReducer;
    const { user } = store.getState().userReducer;
    const customMessage = `DISHOP ${
      isQrCode() ? 'QRCODE' : isTerminal() ? 'TERMINAL' : 'WEBAPP'
    } ${version} | ${
      user && user.customerId ? `CUSTOMER ID: ${user.customerId}` : 'NO ACCOUNT'
    } SHOP ID: ${shopId ? `${shopId}` : `${domain}`} - ${message}`;
    if (isDev()) {
      console.log(customMessage);
    }
    if (!isDev()) {
      await axios.post(API_STREAM_LOGS, { message: customMessage });
    }
  } catch (error) {
    console.log('Error streaming logs to cloudwatch', error);
  }
};

export const sendCloudWatchAlert = async message => {
  try {
    const { shopId } = store.getState().shopReducer;
    const domain = window.location.hostname;
    const { user } = store.getState().userReducer;
    const customMessage = `${FRONTEND_ALERT_ERROR} DISHOP ${
      isQrCode() ? 'QRCODE' : isTerminal() ? 'TERMINAL' : 'WEBAPP'
    } ${version} | ${
      user && user.customerId ? `CUSTOMER ID: ${user.customerId}` : 'NO ACCOUNT'
    } SHOP ID: ${shopId ? `${shopId}` : `${domain}`} - ${message}`;
    if (isDev()) {
      console.log(customMessage);
    }
    if (!isDev()) {
      await axios.post(API_STREAM_LOGS, { message: customMessage });
    }
  } catch (error) {
    console.log('Error streaming logs to cloudwatch', error);
  }
};
