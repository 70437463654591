import {
  UPDATE_SHOP,
  UPDATE_SHOPS,
  UPDATE_CLOSEST_SHOPS,
  UPDATE_SHOP_IS_LOADED,
  UPDATE_SHOP_ORDERS,
  IS_SHOP_UNAVAILABLE
} from '../actions/types';

const INITIAL_STATE = {
  addressShop: {},
  sections: null,
  userServicePriceInfo: null,
  servicePrice: null,
  admins: null,
  openCities: null,
  support: {},
  openHours: {},
  specialHours: {},
  promotions: null,
  shopId: null,
  slidingUrls: [],
  categories: {},
  minimum: 0,
  closed: false,
  shops: {},
  shopName: null,
  closestShops: [],
  minimumClickAndCollect: 0,
  shopIsLoaded: false,
  shopOrders: [],
  sliding: [],
  description: null,
  shopIsLoadedMarketplace: false,
  shopConfiguration: {},
  shopUnavailable: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SHOPS: {
      return {
        shops: action.payload
      };
    }
    case UPDATE_SHOP:
      return {
        ...state,
        categories: action.payload.categories || {},
        slidingUrls: action.payload.slidingUrls,
        userServicePriceInfo: action.payload.userServicePriceInfo,
        openCities: action.payload.openCities,
        support: action.payload.support,
        openHours: action.payload.hours,
        specialHours: action.payload.specialHours,
        admins: action.payload.admins,
        promotions: action.payload.promotions,
        addressShop: action.payload.address,
        minimum: action.payload.minimum,
        servicePrice: action.payload.servicePrice,
        sections: action.payload.sections,
        shopId: action.payload.shopId,
        closed: action.payload.closed,
        shopName: action.payload.name,
        minimumClickAndCollect: action.payload.configuration.minimum || 0,
        loyalty: action.payload.loyalty || {},
        sliding: action.payload.sliding,
        shopConfiguration: action.payload.configuration || {},
        description: action.payload.description,
        deliveryZones: action.payload.deliveryZones || {}
      };
    case UPDATE_CLOSEST_SHOPS:
      return { ...state, closestShops: action.payload };
    case UPDATE_SHOP_IS_LOADED:
      return { ...state, shopIsLoaded: action.payload };
    case UPDATE_SHOP_ORDERS:
      return { ...state, shopOrders: action.payload };
    case IS_SHOP_UNAVAILABLE:
      return { ...state, shopUnavailable: action.payload };
    default:
      return state;
  }
};
