import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { matchPath } from 'react-router-dom';
import {
  OPEN_TO_ALL_CITIES,
  STREET,
  STREET_NUMBER,
  DISTRICT,
  SUBLOCALITY,
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_TYPE_DELIVERY,
  TERMINAL_V,
  TERMINAL_H,
  TERMINAL,
  QRCODE,
  WEBVIEW
} from '../constants';
import { containsCircle, getDistance, containsPolygon } from './map';
import { getDataFromTable, getDataFromTableWithLimit, getShopsData } from '../api';
import store from '../redux/store';
import { updateShops, isShopUnavailable } from '../redux/actions';
import { getDateToday, getTimezone, isSameDay } from './date';
import { sendCloudWatchLogs, sendCloudWatchAlert } from './logs';
import { isTerminal, isQrCode, isWebView } from './config';
import { isStringNotNull } from './dataType';
import { priceWithPercentage, getProductFees } from './product';

const getCurrentShopDay = date => {
  const { openHours } = store.getState().shopReducer;
  let shopDay = false;
  if (openHours) {
    const timezone = getTimezone();
    const day = getDateToday(date, timezone);
    shopDay = openHours[day];
  }
  return shopDay;
};

const findSlot = (time, slots, timezone) => {
  const formatTime = 'MM-DD-YYYY HH:mm';
  const formatDayTime = 'MM-DD-YYYY';
  const day = momentTimezone(time)
    .tz(timezone)
    .format(formatDayTime);
  let shopSlot = false;
  _.find(slots, (slot, index) => {
    moment.tz.setDefault(timezone);
    const beforeTime = moment(`${day} ${slot.split('-')[0]}`, formatTime);
    const afterTime = moment(`${day} ${slot.split('-')[1]}`, formatTime);
    if (slot.split('-')[1] === '00:00') {
      afterTime.add(1, 'days');
    }
    if (time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime)) {
      shopSlot = { slot, index };
      return true;
    }
  });
  return shopSlot;
};

export const getClosedMessage = closed => {
  const { shopName } = store.getState().shopReducer;
  if (typeof closed === 'string') {
    return closed;
  }
  return `${shopName} est exceptionnellement fermé pour le moment.`;
};

export const sortSlots = (slots, day) => {
  const sortSlots = _.sortBy(slots, slot => {
    const stringSlots = slot.split('-');
    const beginHours = moment(`${day.format('YYYY-MM-DD')}T${stringSlots[0]}:00`);
    return beginHours;
  });
  return sortSlots;
};

export const getCurrentShopSlot = (date, orderType) => {
  let shopSlot = false;
  let isSpecialHours = false;
  const { openHours, specialHours } = store.getState().shopReducer;
  const timezone = getTimezone();
  const time = moment(date);
  const specialHour = _.find(specialHours, specialHour => {
    const { day } = specialHour;
    return isSameDay(day, date);
  });
  if (specialHour) {
    const { slots } = specialHour;
    shopSlot = findSlot(time, slots, timezone);
    isSpecialHours = true;
  } else if (openHours) {
    const shopDay = getCurrentShopDay(date);
    if (shopDay) {
      if (orderType === ORDER_TYPE_DELIVERY && shopDay.slotsDelivery) {
        shopSlot = findSlot(time, shopDay.slotsDelivery, timezone);
      } else {
        shopSlot = findSlot(time, shopDay.slots, timezone);
      }
    }
  }
  return { slot: shopSlot, isSpecialHours };
};

export const checkAndGetZoneData = (address, deliveryZones, openCities) => {
  const { city, district, street, subLocality, location } = address;
  let zoneData = null;
  let excludedZonesData = null;
  const sortedDeliveryZones = _.orderBy(deliveryZones, ['priority']).reverse();
  const zone = _.find(sortedDeliveryZones, deliveryZone => {
    const { shape, data } = deliveryZone;
    let containsZone = false;
    if (shape === 'circle') {
      containsZone = containsCircle(location, data, data.radius);
    } else {
      containsZone = containsPolygon(location, data);
    }
    return containsZone;
  });
  if (zone) {
    zoneData = {
      userServicePrice: zone.deliveryFees,
      minimum: zone.minimum
    };
  }
  if (openCities && !zone) {
    const openCity = _.find(openCities, cityTemp => {
      return (
        city.toLowerCase() === cityTemp.name.toLowerCase() || cityTemp.name === OPEN_TO_ALL_CITIES
      );
    });
    if (openCity) {
      const { userServicePrice, minimum, excludedZones } = openCity;
      zoneData = {
        userServicePrice,
        minimum
      };
      excludedZonesData = excludedZones;
      if (isStringNotNull(subLocality) && openCity.subLocalities) {
        const openSubLocality = _.find(openCity.subLocalities, subLocalityTemp => {
          return subLocality.toLowerCase() === subLocalityTemp.name.toLowerCase();
        });
        if (openSubLocality) {
          const { userServicePrice, minimum } = openSubLocality;
          zoneData = {
            userServicePrice,
            minimum
          };
        }
      }
      if (district && openCity.districts) {
        const openDistrict = _.find(openCity.districts, districtTemp => {
          return district.toLowerCase() === districtTemp.name.toLowerCase();
        });
        if (openDistrict) {
          const { userServicePrice, minimum } = openDistrict;
          zoneData = {
            userServicePrice,
            minimum
          };
          if (openDistrict.streets) {
            const openStreet = _.find(openDistrict.streets, streetTemp => {
              return street.toLowerCase() === streetTemp.name.toLowerCase();
            });
            if (openStreet) {
              const { userServicePrice, minimum } = openStreet;
              zoneData = {
                userServicePrice,
                minimum
              };
            }
          }
        }
      }
    }
  }
  return { excludedZonesData, zoneData };
};

export const checkIfZoneExcluded = (address, excludedZones) => {
  const excludedZone = _.find(excludedZones, excludedZoneTemp => {
    switch (excludedZoneTemp.type) {
      case STREET:
        if (address.street.toLowerCase() === excludedZoneTemp.name.toLowerCase()) return true;
        break;
      case STREET_NUMBER:
        if (
          `${address.streetNumber} ${address.street}`.toLowerCase() ===
          excludedZoneTemp.name.toLowerCase()
        )
          return true;
        break;
      case DISTRICT:
        if (
          address.district &&
          address.district.toLowerCase() === excludedZoneTemp.name.toLowerCase()
        )
          return true;
        break;
      case SUBLOCALITY:
        if (
          address.sublocality &&
          address.subLocality.toLowerCase() === excludedZoneTemp.name.toLowerCase()
        )
          return true;
        break;
      default:
        break;
    }
  });
  return excludedZone;
};

export const parseSections = sections => {
  try {
    let productFees = getProductFees();
    let newSections = [];
    _.map(sections, (value, key) => {
      const section = value;
      let minimumPrice = 100;
      if (section) {
        if (section.productFees) {
          productFees = section.productFees;
        }
        _.map(section.products, product => {
          const { categories, price } = product;
          if (productFees && parseFloat(price) > 0) {
            product.price = priceWithPercentage(price, productFees);
          }
          if (categories) {
            let i = 0;
            const categoriesArray = categories.trim().split(',');
            const newCategories = [];
            _.map(categoriesArray, category => {
              newCategories.push(`${category}@${i}`);
              i++;
            });
            product.categories = newCategories.toString();
          }

          if (minimumPrice > parseFloat(product.price)) {
            minimumPrice = parseFloat(product.price);
          }
        });
        section.key = key;
        section.minimumPrice = minimumPrice;
      }
    });
    newSections = _.sortBy(sections, section => {
      return parseInt(section.position, 10);
    });
    newSections = newSections.filter(section => {
      return section;
    });
    return newSections;
  } catch (error) {
    sendCloudWatchAlert(`Error parsing section ${error}`);
  }
};

export const parseCategories = categories => {
  let productFees = getProductFees();
  _.map(categories, category => {
    const { items } = category;
    if (category.productFees) {
      productFees = category.productFees;
    }
    _.map(items, item => {
      if (productFees && parseFloat(item.price) > 0) {
        item.price = priceWithPercentage(item.price, productFees);
      }
      if (item && item.subcategories) {
        let i = 0;
        const categoriesArray = item.subcategories.trim().split(',');
        const newCategories = [];
        _.map(categoriesArray, category => {
          newCategories.push(`${category}@${i}`);
          i++;
        });
        item.subcategories = newCategories.toString();
      }
    });
  });
  return categories;
};

export const getShopData = (shop, zoneData) => {
  const newShop = shop;
  const {
    managementFee,
    sections,
    admins,
    categories,
    cancelFee = { type: '%', value: 100 }
  } = shop;
  let minimum = 0;
  let userServicePriceInfo = {
    userServicePrice: 0,
    userCancelServicePrice: cancelFee,
    managementFee: isTerminal() || isQrCode() ? 0 : managementFee
  };
  if (zoneData) {
    minimum = zoneData.minimum;
    let { userServicePrice } = zoneData;
    userServicePrice = _.sortBy(userServicePrice, ['priceOrder']);
    _.map(userServicePrice, (value, key) => {
      if (userServicePrice[key + 1]) {
        userServicePrice[key].nextPriceOrder = userServicePrice[key + 1].priceOrder;
        userServicePrice[key].nextFees = userServicePrice[key + 1].fees;
      }
    });
    userServicePriceInfo = {
      userServicePrice,
      userCancelServicePrice: cancelFee,
      managementFee: isTerminal() || isQrCode() ? 0 : managementFee
    };
  }
  newShop.userServicePriceInfo = userServicePriceInfo;
  newShop.sections = parseSections(sections);
  newShop.categories = parseCategories(categories);
  const newAdmins = [];
  _.map(admins, admin => {
    newAdmins.push(admin);
  });
  newShop.admins = newAdmins;
  newShop.minimum = minimum;
  return newShop;
};

export const getFirstShop = async () => {
  let { shops } = store.getState().shopReducer;
  if (!shops) {
    shops = await getShopsData();
  }
  let shopId = Object.keys(shops)[0];
  _.find(shops, (shop, sId) => {
    if (!shop.closed) {
      shopId = sId;
      return true;
    }
  });
  if (isTerminal()) {
    shopId = getShopIdFromLink();
  }
  sendCloudWatchLogs(`Get first shop ${shopId}`);
  const shop = shops[shopId];
  const { sections, categories } = shop;
  shop.shopId = shopId;
  shop.sections = parseSections(sections);
  shop.categories = parseCategories(categories);
  return shop;
};

export const getClosestShops = async (customerAddress, orderType) => {
  try {
    let closestShops = [];
    const { shops } = store.getState().shopReducer;
    const { marketplace } = store.getState().configurationReducer;
    const marketplaceEnabled = marketplace && marketplace.enabled;
    if (customerAddress) {
      const { location } = customerAddress;
      _.map(shops, (shop, key) => {
        const { openCities, address, deliveryZones } = shop;
        const { latitude, longitude } = address.location;
        const distance = getDistance(location.latitude, location.longitude, latitude, longitude);
        if (isShopValid(shop, customerAddress, orderType)) {
          const { zoneData } = checkAndGetZoneData(customerAddress, deliveryZones, openCities);
          const newShop = getShopData(shop, zoneData);
          newShop.distance = distance;
          newShop.shopId = key;
          closestShops.push(newShop);
        }
        closestShops = _.sortBy(closestShops, ['distance']);
        if (!marketplaceEnabled) {
          closestShops = closestShops.slice(0, 5);
        }
      });
    } else {
      _.map(shops, (shop, key) => {
        try {
          const { configuration, hide } = shop;
          const { orderTypes } = configuration;
          shop.shopId = key;
          const { sections, categories } = shop;
          shop.sections = parseSections(sections);
          shop.categories = parseCategories(categories);
          delete shop.distance;
          if (!hide) {
            if (orderType && (!orderTypes || (orderTypes && orderTypes.includes(orderType)))) {
              closestShops.push(shop);
            } else if (!orderType) {
              closestShops.push(shop);
            }
          }
        } catch (error) {
          sendCloudWatchLogs(`Error Getting shop ${error}`);
        }
      });
    }
    return closestShops;
  } catch (error) {
    sendCloudWatchAlert(`Error getting closest shops ${error}`);
    throw error;
  }
};

export const isShopValid = (shop, customerAddress, orderType) => {
  const { openCities, configuration, hide, deliveryZones } = shop;
  const { orderTypes } = configuration;
  const { excludedZonesData, zoneData } = checkAndGetZoneData(
    customerAddress,
    deliveryZones,
    openCities
  );
  const excludedZone = checkIfZoneExcluded(customerAddress, excludedZonesData);
  if (
    (((!excludedZone && zoneData) || orderType !== ORDER_TYPE_DELIVERY) &&
      !hide &&
      (!orderTypes || (orderTypes && orderTypes.includes(orderType)))) ||
    isTerminal() ||
    isQrCode()
  ) {
    return true;
  }
  return false;
};

export const getShopAndZoneData = (shopId, shop, customerAddress) => {
  if (shop) {
    if (customerAddress) {
      const { openCities, deliveryZones } = shop;
      const { orderType } = customerAddress;
      if (isShopValid(shop, customerAddress, orderType)) {
        const { zoneData } = checkAndGetZoneData(customerAddress, deliveryZones, openCities);
        shop = getShopData(shop, zoneData);
      } else {
        shop = null;
      }
    } else {
      const { sections, categories } = shop;
      shop.sections = parseSections(sections);
      shop.categories = parseCategories(categories);
    }
    if (shop) {
      shop.shopId = shopId;
    }
    return shop;
  }
  return null;
};

export const getShopIdFromLink = () => {
  const shopId = window.location.pathname.split('/')[2];
  return shopId;
};

export const getShopIdMarketplaceLink = () => {
  const { marketplace } = store.getState().configurationReducer;
  let shopId;
  const match = matchPath(window.location.pathname, { path: '/:shopId' });
  if (
    marketplace &&
    marketplace.enabled &&
    !isTerminal() &&
    !isQrCode() &&
    !isWebView() &&
    match &&
    match.params
  ) {
    shopId = match.params.shopId;
  }

  return shopId;
};

export const getShopFromOrder = order => {
  const { shops } = store.getState().shopReducer;
  const { shopId } = order;
  let { shop } = order;
  if (!shop) {
    shop = _.find(shops, (shop, key) => {
      return shop && key === shopId;
    });
  }
  return shop || {};
};

export const checkIfShopAvailable = (customerAddress, shopId, closestShops) => {
  let shopUnavailable = false;
  if (closestShops) {
    const shopExists = _.find(closestShops, shop => {
      return shop.shopId === shopId;
    });
    if (!shopExists) {
      if (customerAddress && customerAddress.orderType === ORDER_TYPE_DELIVERY) {
        shopUnavailable = 'Ce magasin ne livre pas à votre adresse';
      } else {
        shopUnavailable = 'Service indisponible';
      }
    }
  }
  store.dispatch(isShopUnavailable(shopUnavailable));
  return shopUnavailable;
};
