import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import OrderItem from '../OrderItem';
import Button from '../Button';
import Loyalty from '../Loyalty/Loyalty.view';
import {
  displayPriceFormat,
  getStatus,
  getDateFormat,
  getUserServicePrice,
  calculateFullPrice,
  getPromotion,
  isTerminal,
  isObjectNotNull,
  getShopFromOrder,
  getAddressFormatted,
  isTerminalVertical,
  isStringNotNull,
  getEstimationTime,
  isDarkTheme
} from '../../utils';
import {
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_TAKEAWAY,
  ORDER_TYPE_DELIVERY,
  PROMOTION_DELIVERY_FREE,
  PROMOTION_PERCENTAGE,
  ORDER_TYPE_CLICK_AND_SEAT,
  LOYALTY
} from '../../constants';
import { addPromotion } from './Order.services';
import Spinner from '../Spinner';
import { UNIT } from '../../constants/configuration';

const INITIAL_STATE = {
  border: 'transparent',
  promoCode: '',
  loadingPromotion: false
};

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderLoyalty = () => {
    return (
      <div>
        <Loyalty />
      </div>
    );
  };

  renderPromo = () => {
    const { promoCode, loadingPromotion } = this.state;
    return (
      <div>
        <div className='input-group my-4'>
          <input
            type='text'
            className='form-control font-italic'
            placeholder='Ajouter un code promotionnel'
            aria-describedby='button-addon2'
            onChange={e => {
              this.setState({ promoCode: e.target.value });
            }}
            style={{ fontSize: UNIT * 0.75 }}
          />
          <div className='input-group-append'>
            <Button
              className='btn-outline-secondary label'
              id='button-addon2'
              style={{ borderRadius: `0px ${UNIT / 4}px ${UNIT / 4}px 0px` }}
              onClick={() => {
                addPromotion(this, promoCode);
              }}
            >
              {loadingPromotion && (
                <div className='d-flex justify-content-center align-items-center'>
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  />
                  <span className='sr-only'>Loading...</span>
                </div>
              )}
              {!loadingPromotion && <div style={{ fontSize: UNIT * 0.75 }}>Appliquer</div>}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderItems = commande => {
    const { deleteOption } = this.props;
    return _.keys(commande).map(key => {
      const item = commande[key];
      return <OrderItem cartItem={item} key={key} cartItemKey={key} deleteOption={deleteOption} />;
    });
  };

  renderTotal = () => {
    const { customerAddress, order, secondaryColor, showFees, name } = this.props;
    const { commande, totalPriceOrder } = order;
    let { promotion, orderType, managementFee, userServicePrice } = order;
    if (!commande) {
      promotion = getPromotion();
      const servicePrice = getUserServicePrice();
      managementFee = servicePrice.managementFee;
      userServicePrice = servicePrice.servicePrice;
      orderType = customerAddress && customerAddress.orderType;
    }
    return (
      <>
        {promotion && (
          <>
            {promotion.type === PROMOTION_DELIVERY_FREE && (
              <div className='row'>
                <div className='col-7 no-localization'>Promotion : {promotion.promoCode}</div>
                <div className='col-5 text-right'>
                  <span style={{ color: secondaryColor }}>Livraison gratuite</span>
                </div>
              </div>
            )}
            {typeof promotion.type === 'string' && promotion.type.includes(PROMOTION_PERCENTAGE) && (
              <div className='row'>
                <div className='col-8 no-localization'>Promotion {promotion.promoCode}</div>
                <div className='col-4 text-right'>{`-${promotion.type}`}</div>
              </div>
            )}
            {typeof promotion.type === 'number' && (
              <div className='row'>
                {promotion.promoCode === LOYALTY && (
                  <div className='col-8 no-localization'>Fidélité {name}</div>
                )}
                {promotion.promoCode !== LOYALTY && (
                  <div className='col-8 no-localization'>Promotion {promotion.promoCode}</div>
                )}
                <div className='col-4 text-right'>-{displayPriceFormat(promotion.type)}</div>
              </div>
            )}
          </>
        )}
        {showFees && orderType === ORDER_TYPE_DELIVERY && (
          <div className='row'>
            <div className='col-8'>Livraison</div>
            <div className='col-4 text-right'>
              {promotion && promotion.type === PROMOTION_DELIVERY_FREE
                ? displayPriceFormat(0)
                : displayPriceFormat(userServicePrice)}
            </div>
          </div>
        )}
        {showFees && managementFee > 0 && (
          <div className='row'>
            <div className='col-8'>Frais et Services</div>
            <div className='col-4 text-right'>{displayPriceFormat(managementFee)}</div>
          </div>
        )}
        <div className='row'>
          <div className='col-6'>{isTerminalVertical() ? <h3>Total</h3> : <h4>Total</h4>}</div>
          <div
            className='col-6 text-right'
            style={
              isTerminalVertical()
                ? { fontSize: UNIT * 1.75 }
                : { fontSize: UNIT * 1.3, fontWeight: 'bold' }
            }
          >
            {!commande
              ? displayPriceFormat(calculateFullPrice())
              : displayPriceFormat(totalPriceOrder)}
          </div>
        </div>
      </>
    );
  };

  renderCart = () => {
    const {
      customerAddress,
      userConnected,
      showPromo,
      userServicePriceInfo,
      isUserAnonymous,
      order
    } = this.props;
    const { orderType, commande, orderNumber } = order;
    const showReduction = (userConnected || isUserAnonymous) && showPromo && !isTerminal();
    if (!userServicePriceInfo && customerAddress) {
      return (
        <div className='text-center'>
          <Spinner />
        </div>
      );
    }
    if (!isObjectNotNull(order)) {
      let emptyCartClass = '';
      if (isUserAnonymous) {
        emptyCartClass = 'm-0 py-3 px-1';
      }
      return (
        <div className='text-center'>
          <p className={`text-secondary mb-0 label ${emptyCartClass}`}>Votre panier est vide</p>
        </div>
      );
    }
    let orderTypeLabel = '';
    if (customerAddress || orderType) {
      if ((orderType || customerAddress.orderType) === ORDER_TYPE_DELIVERY) {
        orderTypeLabel = 'En livraison';
      } else if ((orderType || customerAddress.orderType) === ORDER_TYPE_CLICK_AND_SEAT) {
        orderTypeLabel = 'Sur place';
      } else {
        orderTypeLabel = `À emporter`;
      }
    }
    return (
      <div>
        <div className={`px-sm-3 mb-4 ${!isDarkTheme() && 'text-color'}`}>
          <h3
            className='text-muted mb-1 d-flex justify-content-center label'
            style={isTerminalVertical() ? { fontSize: UNIT * 2.5 } : { fontSize: UNIT * 1.5 }}
          >
            {`Votre commande ${isStringNotNull(orderNumber) ? `n°${orderNumber} ` : ''}:`}
          </h3>
          <p className='mb-4 text-muted font-italic font-weight-light d-flex justify-content-center label'>{`- ${orderTypeLabel} -`}</p>
          {this.renderItems(commande || order)}
          {showReduction && this.renderPromo()}
          {showReduction && !isUserAnonymous && this.renderLoyalty()}
          {showReduction && <hr className='my-4 hr' />}
          {this.renderTotal()}
        </div>
      </div>
    );
  };

  renderOrder = () => {
    const { order, secondaryColor } = this.props;
    const { border } = this.state;
    const { orderNumber, date, commande, status, orderType } = order;
    const shop = getShopFromOrder(order);
    const { name, address } = shop;
    const pastOrder = status === 'completed';
    let orderTypeLabel = '';
    if (orderType) {
      if (orderType === ORDER_TYPE_DELIVERY) {
        orderTypeLabel = 'En livraison';
      } else if (orderType === ORDER_TYPE_CLICK_AND_SEAT) {
        orderTypeLabel = 'Sur place';
      } else {
        orderTypeLabel = `À emporter`;
      }
    }
    return (
      <div
        className='card mb-3 shadow'
        onMouseEnter={() => this.toggleHover()}
        onMouseLeave={() => this.toggleLeave()}
        style={{
          cursor: pastOrder ? 'unset' : 'pointer',
          border: pastOrder && !isDarkTheme() ? 'none' : `2px solid ${border}`,
          borderRadius: UNIT * 2.1875
        }}
      >
        <div className='card-body dark-modal' style={{ borderRadius: UNIT * 2 }}>
          <h5 className='card-title label' style={{ color: secondaryColor }}>
            Commande n°
            <span className='no-localization label'>{orderNumber}</span>
          </h5>
          <h6 className='card-subtitle mb-2 text-muted no-localization label'>
            {getDateFormat(date)} - {orderTypeLabel}
          </h6>
          <h6 className='card-subtitle mb-2 text-muted no-localization label'>
            {orderType === ORDER_TYPE_DELIVERY
              ? `Adresse : ${getAddressFormatted(order.address)}`
              : `${name} : ${getAddressFormatted(address)}`}
          </h6>
          <hr className='my-4 hr' />
          <div className='container-block'>
            {pastOrder && this.renderItems(commande)}
            {this.renderTotal()}
            {!pastOrder && (
              <>
                <hr className='my-4 hr' />
                <b className='text-left'>{getStatus(order)}</b>
                {status !== ORDER_PHASE_TAKEAWAY && status !== ORDER_PHASE_DELIVERING && (
                  <div className='text-left'>{`Commande prévue pour le ${getEstimationTime(
                    order
                  )}`}</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  toggleHover = () => {
    const { secondaryColor } = this.props;
    this.setState({
      border: secondaryColor
    });
  };

  toggleLeave = () => {
    this.setState({ border: 'transparent' });
  };

  render() {
    const { showCart } = this.props;
    if (!showCart) {
      return this.renderOrder();
    }
    return this.renderCart();
  }
}

const mapStateToProps = ({ userReducer, configurationReducer, shopReducer }) => {
  const { user, customerAddress, userConnected, isUserAnonymous } = userReducer;
  const { secondaryColor, webapp, name } = configurationReducer;
  const { userServicePriceInfo } = shopReducer;
  const { phrases } = webapp;
  return {
    user,
    userConnected,
    customerAddress,
    secondaryColor,
    userServicePriceInfo,
    isUserAnonymous,
    name,
    phrases
  };
};

export default connect(mapStateToProps)(Order);
