import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '../Button';
import {
  resetTerminalSession,
  isDarkTheme,
  displayPriceFormat,
  calculateProductPrice,
  getNbTotalCart
} from '../../utils';
import styles from './BottomCart.styles';
import {
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_CLICK_AND_COLLECT,
  UNIT,
  PRIMARY,
  SECONDARY
} from '../../constants';

import { showCartModal, showAccountModal, showConnexionModal } from '../../redux/actions';

const { textStyle } = styles;

const INITIAL_STATE = {
  cartIcon: 'transparent',
  accountIcon: 'transparent'
};

class BottomCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  render() {
    const { customerAddress, showCartModal, cart, order } = this.props;
    let orderTypeLabel = '';
    if (customerAddress) {
      if (customerAddress.orderType === ORDER_TYPE_DELIVERY) {
        orderTypeLabel = 'Commande en Livraison';
      } else if (customerAddress.orderType === ORDER_TYPE_CLICK_AND_COLLECT) {
        orderTypeLabel = 'Commande à Emporter';
      } else if (customerAddress.orderType === ORDER_TYPE_CLICK_AND_SEAT) {
        orderTypeLabel = 'Commande sur Place';
      }
    }
    return (
      <div
        className='px-5 d-sm-block d-none section-bar fixed-bottom text-white border-secondary border-top'
        style={{
          boxShadow: `5px 5px 5px 5px ${isDarkTheme() ? '#ffffffbf' : '#0000005c'}`
        }}
        id='bottom-cart'
      >
        <div className='col p-3'>
          <div>
            <p className='mb-3 text-color-secondary text-center' style={textStyle}>
              <span>
                {orderTypeLabel} | Total: {displayPriceFormat(calculateProductPrice())}
              </span>
            </p>
          </div>
          <div className='row justify-content-center mx-5'>
            <div className='col-4 d-flex align-items-center justify-content-end mr-5'>
              <Button
                type={SECONDARY}
                className='p-0'
                style={{ opacity: 0.75 }}
                onClick={() => {
                  resetTerminalSession();
                }}
              >
                <div
                  className='row text-white py-2 px-3'
                  style={{ justifyContent: 'center', height: '100%' }}
                >
                  <ExitToAppIcon className='mr-2 align-self-center' style={textStyle} />
                  <p className='mb-0 align-self-center' style={textStyle}>
                    Abandonner
                  </p>
                </div>
              </Button>
            </div>
            <div className='col-5 d-flex justify-content-start'>
              <Button
                type={PRIMARY}
                className='p-0'
                disabled={_.isEmpty(cart) && _.isEmpty(order)}
                style={{
                  borderRadius: UNIT * 2,
                  height: '55px',
                  width: '350px'
                }}
                onClick={() => {
                  showCartModal(true);
                }}
              >
                <div
                  className='row text-white py-2 px-3'
                  style={{ justifyContent: 'center', height: '100%' }}
                >
                  <p className='mb-0 align-self-center mr-2' style={textStyle}>
                    {!_.isEmpty(cart) ? `+${getNbTotalCart()}` : ''}
                  </p>
                  <ShoppingCartOutlinedIcon className='mr-2 align-self-center' style={textStyle} />
                  <p className='mb-0 align-self-center' style={textStyle}>
                    Ma commande
                  </p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  userReducer,
  shopReducer,
  cartReducer,
  orderReducer,
  configurationReducer,
  componentReducer
}) => {
  const { isHorizontalSection } = componentReducer;
  const { customerAddress, userConnected, isUserAnonymous } = userReducer;
  const { secondaryColor } = configurationReducer;
  const { addressShop, shopName } = shopReducer;
  const { order } = orderReducer;
  return {
    customerAddress,
    addressShop,
    shopName,
    cart: cartReducer,
    userConnected,
    order,
    secondaryColor,
    isHorizontalSection,
    isUserAnonymous
  };
};

export default connect(mapStateToProps, { showCartModal, showConnexionModal, showAccountModal })(
  BottomCart
);
