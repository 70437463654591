import React from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/Footer';
import Cover from '../../components/Cover/Cover';
import MarketplaceLanding from '../../components/MarketplaceLanding';
import ListShop from '../../components/ListShop/ListShop';
import Splash from '../../components/Splash';

class Marketplace extends React.Component {
  render() {
    const { customerAddress, addressIsLoaded } = this.props;
    return (
      <div>
        {!customerAddress && !addressIsLoaded && <Splash />}
        {!customerAddress && addressIsLoaded && <MarketplaceLanding />}
        {customerAddress && (
          <div>
            <Cover marketplaceLanding />
            <div className='row container mx-auto mt-5 justify-content-center'>
              <ListShop />
            </div>
            <Footer />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer, userReducer }) => {
  const { name, landing } = configurationReducer;
  const { customerAddress, addressIsLoaded } = userReducer;
  return {
    name,
    landing,
    customerAddress,
    addressIsLoaded
  };
};

export default connect(mapStateToProps, {})(Marketplace);
